/// <reference types="./styles.d.mts" />
import * as $s from "../../sketch/sketch.mjs";
import * as $size from "../../sketch/sketch/size.mjs";
import { px, vh } from "../../sketch/sketch/size.mjs";
import * as $h from "../../sketch_magic/sketch/magic/element/html.mjs";
import * as $colors from "../design_system/colors.mjs";
import * as $display from "../design_system/typography/display.mjs";
import * as $text from "../design_system/typography/text.mjs";
import { toList } from "../gleam.mjs";

export function layout(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("grid"),
      $s.grid_template_columns("350px 1fr"),
      $s.grid_template_areas(toList(["navbar main"])),
      $s.height(vh(100)),
      $s.color($colors.light.onyx),
      $s.overflow_y("hidden"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function main(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.padding(px(40)),
      $s.gap(px(32)),
      $s.overflow_y("auto"),
      $s.height(vh(100)),
    ]),
  );
  return $h.main(_pipe, attrs, children);
}

export function page_header(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.flex_direction("column"), $s.gap(px(24))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function page_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($display.sm())]));
  return $h.div(_pipe, attrs, children);
}

export function page_catchphrase(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.flex_direction("column"), $s.gap(px(8))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function catch_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($display.md())]));
  return $h.div(_pipe, attrs, children);
}

export function catch_subtitle(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.md($text.regular)),
      $s.color($colors.light.paragraph),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
