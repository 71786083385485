/// <reference types="./msg.d.mts" />
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import { CustomType as $CustomType } from "../../../gleam.mjs";
import * as $level from "../../../grille_pain/toast/level.mjs";

export class ExternalHide extends $CustomType {
  constructor(uuid) {
    super();
    this.uuid = uuid;
  }
}

export class Hide extends $CustomType {
  constructor(id, iteration) {
    super();
    this.id = id;
    this.iteration = iteration;
  }
}

export class New extends $CustomType {
  constructor(uuid, message, level, timeout, sticky) {
    super();
    this.uuid = uuid;
    this.message = message;
    this.level = level;
    this.timeout = timeout;
    this.sticky = sticky;
  }
}

export class Remove extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class Resume extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class Show extends $CustomType {
  constructor(id, timeout, sticky) {
    super();
    this.id = id;
    this.timeout = timeout;
    this.sticky = sticky;
  }
}

export class Stop extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}
