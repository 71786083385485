/// <reference types="./update.d.mts" />
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import { toList } from "../gleam.mjs";

export function guard(new_state, guard_) {
  let model = new_state[0];
  let eff = new_state[1];
  return [model, $effect.batch(toList([eff, guard_(model)]))];
}

export function derive_data(new_state, derivation) {
  return $pair.map_first(new_state, derivation);
}
