/// <reference types="./feature_flags.d.mts" />
import { authorized as do_authorized } from "./feature-flags.ffi.mjs";
import { CustomType as $CustomType } from "./gleam.mjs";

export class Analytics extends $CustomType {}

export class QualificationMatrix extends $CustomType {}

export class Translations extends $CustomType {}

function to_string(flag) {
  if (flag instanceof Analytics) {
    return "analytics";
  } else if (flag instanceof QualificationMatrix) {
    return "qualification-matrix";
  } else {
    return "translations";
  }
}

export function authorized(flag, org_id) {
  let flag$1 = to_string(flag);
  return do_authorized(flag$1, org_id);
}

export function guard(flag, org_id, default$, next) {
  let $ = authorized(flag, org_id);
  if ($) {
    return next();
  } else {
    return default$;
  }
}
