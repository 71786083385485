/// <reference types="./translated_text.d.mts" />
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $question from "../../../common/data/question.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $model from "../../data/model.mjs";
import { Ok, toList } from "../../gleam.mjs";

export function id_builder(id, l, k) {
  let language = $translate.decode_language_iso639_1(l);
  let kind = $translate.decode_translation_target_small(k);
  let _pipe = toList([language, kind, id]);
  return $string.join(_pipe, "_");
}

export function translate_or_default(id, default$, model) {
  let $ = $model.is_translations_hidden(model);
  if ($) {
    return default$;
  } else {
    let _pipe = model.translations;
    let _pipe$1 = $dict.get(_pipe, id);
    let _pipe$2 = $result.then$(_pipe$1, (t) => { return new Ok(t.content); });
    return $result.unwrap(_pipe$2, default$);
  }
}

export function translated_question(question, model, language) {
  return $h.text(
    translate_or_default(
      id_builder(question.id, language, new $translate.Question()),
      question.content,
      model,
    ),
  );
}

export function translated_answer(question, model, language) {
  return $h.text(
    translate_or_default(
      id_builder(question.id, language, new $translate.Answer()),
      $question.get_answer_text(question.answer),
      model,
    ),
  );
}

export function translated_data_point(point, model, language) {
  return $h.text(
    translate_or_default(
      id_builder(point.id, language, new $translate.DataPoint()),
      point.text,
      model,
    ),
  );
}
