/// <reference types="./button.d.mts" />
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import * as $html from "../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $colors from "../../design_system/colors.mjs";
import * as $text from "../../design_system/typography/text.mjs";
import { toList } from "../../gleam.mjs";

export function icon(child) {
  let _pipe = $s.class$(toList([$s.width(px(20)), $s.height(px(20))]));
  return $html.div(_pipe, toList([]), toList([child]));
}

export function text(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose(button_base()),
      $s.background($colors.light.transparent),
      $s.color($colors.light.onyx),
      $s.hover(toList([$s.gap(px(18)), $s.margin_right(px(-6))])),
      $s.disabled(
        toList([
          $s.color($colors.light.grey_800),
          $s.cursor("not-allowed"),
          $s.gap(px(6)),
        ]),
      ),
    ]),
  );
  return $html.button(_pipe, attrs, children);
}

function button_base() {
  return $s.class$(
    toList([
      $s.compose($text.md($text.medium)),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.gap(px(12)),
      $s.cursor("pointer"),
      $s.transition("all .3s"),
      $s.appearance("none"),
      $s.border("none"),
      $s.outline("none"),
      $s.font_family("inherit"),
    ]),
  );
}

function button_pill() {
  return $s.class$(
    toList([
      $s.compose(button_base()),
      $s.border_radius(px(8)),
      $s.padding_("10px 18px"),
      $s.text_decoration("none"),
      $s.border("1px solid " + $colors.light.onyx),
    ]),
  );
}

export function button_primary() {
  return $s.class$(
    toList([
      $s.compose(button_pill()),
      $s.background($colors.light.onyx),
      $s.color($colors.light.white),
      $s.hover(toList([$s.background($colors.light.paragraph)])),
      $s.disabled(
        toList([
          $s.background($colors.light.grey_400),
          $s.color($colors.light.grey_800),
          $s.border("1px solid " + $colors.light.grey_400),
          $s.cursor("not-allowed"),
        ]),
      ),
    ]),
  );
}

export function button_secondary() {
  return $s.class$(
    toList([
      $s.compose(button_pill()),
      $s.background($colors.light.white),
      $s.color($colors.light.onyx),
      $s.hover(
        toList([
          $s.background($colors.light.onyx),
          $s.color($colors.light.white),
        ]),
      ),
      $s.disabled(
        toList([
          $s.background($colors.light.white),
          $s.color($colors.light.grey_600),
          $s.border("1px solid " + $colors.light.grey_400),
          $s.cursor("not-allowed"),
        ]),
      ),
    ]),
  );
}

export function primary(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose(button_primary())]));
  return $html.button(_pipe, attrs, children);
}

export function primary_link(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose(button_primary())]));
  return $html.a(_pipe, attrs, children);
}

export function secondary(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose(button_secondary())]));
  return $html.button(_pipe, attrs, children);
}

export function secondary_link(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose(button_secondary())]));
  return $html.a(_pipe, attrs, children);
}

export function link(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose(button_base()),
      $s.background($colors.light.transparent),
      $s.color($colors.light.onyx),
      $s.text_decoration("none"),
      $s.hover(toList([$s.gap(px(18)), $s.margin_right(px(-6))])),
      $s.disabled(
        toList([
          $s.color($colors.light.grey_800),
          $s.cursor("not-allowed"),
          $s.gap(px(6)),
        ]),
      ),
    ]),
  );
  return $html.a(_pipe, attrs, children);
}
