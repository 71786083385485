/// <reference types="./model.d.mts" />
import * as $birl from "../../../../birl/birl.mjs";
import * as $duration from "../../../../birl/birl/duration.mjs";
import { Duration } from "../../../../birl/birl/duration.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $shadow from "../../../../plinth/plinth/browser/shadow.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType, divideInt } from "../../../gleam.mjs";
import * as $toast from "../../../grille_pain/internals/data/toast.mjs";
import { Toast } from "../../../grille_pain/internals/data/toast.mjs";
import * as $ffi from "../../../grille_pain/internals/ffi.mjs";
import * as $level from "../../../grille_pain/toast/level.mjs";

export class Model extends $CustomType {
  constructor(toasts, id, timeout, root) {
    super();
    this.toasts = toasts;
    this.id = id;
    this.timeout = timeout;
    this.root = root;
  }
}

export function new$(root, timeout) {
  let toasts = toList([]);
  let id = 0;
  return new Model(toasts, id, timeout, root);
}

export function add(model, external_id, message, level, sticky, timeout) {
  let timeout$1 = $option.unwrap(timeout, model.timeout);
  let new_toast = $toast.new$(
    external_id,
    model.id,
    message,
    level,
    timeout$1,
    sticky,
    model.root,
  );
  let new_toasts = listPrepend(new_toast, model.toasts);
  let new_id = model.id + 1;
  return model.withFields({ toasts: new_toasts, id: new_id });
}

function update_toast(model, id, updater) {
  let toasts = $list.map(
    model.toasts,
    (toast) => {
      let $ = id === toast.id;
      if ($) {
        return updater(toast);
      } else {
        return toast;
      }
    },
  );
  return model.withFields({ toasts: toasts });
}

export function show(model, id) {
  return update_toast(
    model,
    id,
    (toast) => {
      let now = $birl.utc_now();
      return toast.withFields({
        displayed: true,
        running: true,
        last_schedule: now
      });
    },
  );
}

export function hide(model, id) {
  return update_toast(
    model,
    id,
    (toast) => { return toast.withFields({ displayed: false }); },
  );
}

export function decrease_bottom(model, id) {
  let new_toasts = $list.map(
    model.toasts,
    (toast) => {
      let $ = toast.displayed;
      let $1 = toast.id > id;
      if ($ && $1) {
        let bottom = $ffi.compute_toast_size(id, model.root);
        let bottom$1 = toast.bottom - bottom;
        return toast.withFields({ bottom: bottom$1 });
      } else {
        return toast;
      }
    },
  );
  return model.withFields({ toasts: new_toasts });
}

export function stop(model, id) {
  return update_toast(
    model,
    id,
    (toast) => {
      let now = $birl.utc_now();
      let $ = $birl.difference(now, toast.last_schedule);
      let elapsed_time = $[0];
      let remaining = toast.remaining - (divideInt(elapsed_time, 1000));
      let iteration = toast.iteration + 1;
      return toast.withFields({
        running: false,
        remaining: remaining,
        iteration: iteration
      });
    },
  );
}

export function resume(model, id) {
  return update_toast(
    model,
    id,
    (toast) => {
      let now = $birl.utc_now();
      return toast.withFields({ running: true, last_schedule: now });
    },
  );
}

export function remove(model, id) {
  let new_toasts = $list.filter(
    model.toasts,
    (toast) => { return toast.id !== id; },
  );
  return model.withFields({ toasts: new_toasts });
}
