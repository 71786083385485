/// <reference types="./progress_bar.d.mts" />
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $sketch from "../../../../sketch/sketch.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { px } from "../../../../sketch/sketch/size.mjs";
import * as $html from "../../../../sketch_lustre/sketch/lustre/element/html.mjs";
import { toList, divideInt } from "../../../gleam.mjs";
import * as $css from "../../../grille_pain/internals/css.mjs";
import * as $toast from "../../../grille_pain/internals/data/toast.mjs";
import * as $colors from "../../../grille_pain/internals/view/colors.mjs";
import * as $level from "../../../grille_pain/toast/level.mjs";

function pb_base() {
  return $sketch.class$(
    toList([$sketch.animation_fill_mode("forwards"), $sketch.height(px(5))]),
  );
}

function pb_animation(duration) {
  let duration$1 = $int.to_string(divideInt(duration, 1000));
  let animation = duration$1 + "s linear 0s progress_bar";
  return $sketch.class$(toList([$sketch.animation(animation)]));
}

function pb_background_color(level) {
  let back_color = $colors.progress_bar_from_level(level);
  let level$1 = $level.to_string(level);
  let var$ = ("grille_pain-" + level$1) + "-progress-bar";
  let background = $css.var$(var$, back_color);
  return $sketch.class$(toList([$sketch.background(background)]));
}

function pb_play_state(running) {
  let running_str = $toast.running_to_string(running);
  return $sketch.class$(
    toList([
      (() => {
        let _pipe = $sketch.animation_play_state(running_str);
        return $sketch.important(_pipe);
      })(),
    ]),
  );
}

export function view(toast) {
  let _pipe = $sketch.class$(
    toList([
      $sketch.compose(pb_base()),
      $sketch.compose(pb_background_color(toast.level)),
      $sketch.compose(pb_animation(toast.animation_duration)),
      $sketch.compose(pb_play_state(toast.running)),
    ]),
  );
  return $html.div(_pipe, toList([]), toList([]));
}
