/// <reference types="./options.d.mts" />
import { CustomType as $CustomType } from "../gleam.mjs";

export class Options extends $CustomType {
  constructor(timeout) {
    super();
    this.timeout = timeout;
  }
}

export function default$() {
  return new Options(5000);
}

export function timeout(_, timeout) {
  return new Options(timeout);
}
