/// <reference types="./lightbulb_flash.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import { attribute } from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $svg from "../../../lustre/lustre/element/svg.mjs";
import { toList } from "../../gleam.mjs";

export function lightbulb_flash() {
  return $html.svg(
    toList([
      attribute("xmlns", "http://www.w3.org/2000/svg"),
      attribute("fill", "none"),
      attribute("viewBox", "0 0 162 162"),
      attribute("height", "162"),
      attribute("width", "162"),
    ]),
    toList([
      $svg.path(
        toList([
          attribute("stroke-width", "1.62"),
          attribute("stroke", "url(#paint1_linear_6792_147)"),
          attribute("fill", "url(#paint0_linear_6792_147)"),
          attribute("d", "M64.8979 106.377H95.8559V110.631H64.8979V106.377Z"),
        ]),
      ),
      $svg.path(
        toList([
          attribute("stroke-width", "1.62"),
          attribute("stroke", "url(#paint3_linear_6792_147)"),
          attribute("fill", "url(#paint2_linear_6792_147)"),
          attribute("d", "M95.856 114.922H64.898V119.176H95.856V114.922Z"),
        ]),
      ),
      $svg.mask(
        toList([
          attribute("fill", "white"),
          $attribute.id("path-3-inside-1_6792_147"),
        ]),
        toList([
          $svg.path(
            toList([
              attribute(
                "d",
                "M67.6069 124.145C67.8377 130.954 73.4309 136.404 80.2969 136.404C87.1629 136.404 92.756 130.954 92.9868 124.145H67.6069Z",
              ),
              attribute("clip-rule", "evenodd"),
              attribute("fill-rule", "evenodd"),
            ]),
          ),
        ]),
      ),
      $svg.path(
        toList([
          attribute("fill", "url(#paint4_linear_6792_147)"),
          attribute(
            "d",
            "M67.6069 124.145C67.8377 130.954 73.4309 136.404 80.2969 136.404C87.1629 136.404 92.756 130.954 92.9868 124.145H67.6069Z",
          ),
          attribute("clip-rule", "evenodd"),
          attribute("fill-rule", "evenodd"),
        ]),
      ),
      $svg.path(
        toList([
          attribute("mask", "url(#path-3-inside-1_6792_147)"),
          attribute("fill", "url(#paint5_linear_6792_147)"),
          attribute(
            "d",
            "M67.6069 124.145V122.525H65.9311L65.9879 124.2L67.6069 124.145ZM92.9868 124.145L94.6059 124.2L94.6626 122.525H92.9868V124.145ZM80.2969 134.784C74.3072 134.784 69.4273 130.03 69.226 124.09L65.9879 124.2C66.2481 131.879 72.5545 138.024 80.2969 138.024V134.784ZM91.3677 124.09C91.1665 130.03 86.2866 134.784 80.2969 134.784V138.024C88.0392 138.024 94.3456 131.879 94.6059 124.2L91.3677 124.09ZM92.9868 122.525H67.6069V125.765H92.9868V122.525Z",
          ),
        ]),
      ),
      $svg.mask(
        toList([
          attribute("fill", "white"),
          $attribute.id("path-5-inside-2_6792_147"),
        ]),
        toList([
          $svg.path(
            toList([
              attribute(
                "d",
                "M110.402 83.786C114.534 77.9311 116.96 70.7873 116.96 63.0768C116.96 43.2164 100.86 27.1164 80.9999 27.1164C61.1396 27.1164 45.0396 43.2164 45.0396 63.0768C45.0396 70.0674 47.0343 76.5922 50.4857 82.1129L60.9531 101.803H99.8467L110.402 83.786Z",
              ),
              attribute("clip-rule", "evenodd"),
              attribute("fill-rule", "evenodd"),
            ]),
          ),
        ]),
      ),
      $svg.path(
        toList([
          attribute("fill", "url(#paint6_linear_6792_147)"),
          attribute(
            "d",
            "M110.402 83.786C114.534 77.9311 116.96 70.7873 116.96 63.0768C116.96 43.2164 100.86 27.1164 80.9999 27.1164C61.1396 27.1164 45.0396 43.2164 45.0396 63.0768C45.0396 70.0674 47.0343 76.5922 50.4857 82.1129L60.9531 101.803H99.8467L110.402 83.786Z",
          ),
          attribute("clip-rule", "evenodd"),
          attribute("fill-rule", "evenodd"),
        ]),
      ),
      $svg.path(
        toList([
          attribute("mask", "url(#path-5-inside-2_6792_147)"),
          attribute("fill", "url(#paint7_linear_6792_147)"),
          attribute(
            "d",
            "M110.402 83.786L109.079 82.852L109.039 82.908L109.004 82.9671L110.402 83.786ZM50.4857 82.1129L51.9161 81.3525L51.8894 81.3023L51.8593 81.2542L50.4857 82.1129ZM60.9531 101.803L59.5227 102.564L59.9797 103.423H60.9531V101.803ZM99.8467 101.803V103.423H100.775L101.245 102.622L99.8467 101.803ZM115.34 63.0768C115.34 70.4421 113.023 77.2614 109.079 82.852L111.726 84.72C116.044 78.6008 118.58 71.1326 118.58 63.0768H115.34ZM80.9999 28.7364C99.9656 28.7364 115.34 44.1111 115.34 63.0768H118.58C118.58 42.3217 101.755 25.4964 80.9999 25.4964V28.7364ZM46.6596 63.0768C46.6596 44.1111 62.0343 28.7364 80.9999 28.7364V25.4964C60.2449 25.4964 43.4196 42.3217 43.4196 63.0768H46.6596ZM51.8593 81.2542C48.5642 75.9834 46.6596 69.755 46.6596 63.0768H43.4196C43.4196 70.3799 45.5044 77.201 49.112 82.9717L51.8593 81.2542ZM62.3836 101.043L51.9161 81.3525L49.0552 82.8733L59.5227 102.564L62.3836 101.043ZM99.8467 100.183H60.9531V103.423H99.8467V100.183ZM109.004 82.9671L98.4489 100.984L101.245 102.622L111.8 84.6049L109.004 82.9671Z",
          ),
        ]),
      ),
      $svg.path(
        toList([
          attribute("stroke-width", "1.62"),
          attribute("stroke", "url(#paint8_linear_6792_147)"),
          attribute("fill", "white"),
          attribute(
            "d",
            "M81.947 43.6151L80.4269 43.2253L66.0482 69.4188L65.3896 70.6186H66.7583H78.2729V87.9425L79.7929 88.3323L94.1716 62.1388L94.8303 60.939H93.4616H81.947V43.6151Z",
          ),
        ]),
      ),
      $svg.linear_gradient(
        toList([
          attribute("gradientUnits", "userSpaceOnUse"),
          attribute("y2", "108.504"),
          attribute("x2", "64.0879"),
          attribute("y1", "108.504"),
          attribute("x1", "103.905"),
          $attribute.id("paint0_linear_6792_147"),
        ]),
        toList([
          $svg.stop(
            toList([
              attribute("stop-opacity", "0"),
              attribute("stop-color", "#E6FAB5"),
            ]),
          ),
          $svg.stop(
            toList([
              attribute("stop-color", "#E6FAB5"),
              attribute("offset", "1"),
            ]),
          ),
        ]),
      ),
      $svg.linear_gradient(
        toList([
          attribute("gradientUnits", "userSpaceOnUse"),
          attribute("y2", "98.1621"),
          attribute("x2", "138.164"),
          attribute("y1", "98.1621"),
          attribute("x1", "58.9533"),
          $attribute.id("paint1_linear_6792_147"),
        ]),
        toList([
          $svg.stop(
            toList([
              attribute("stop-opacity", "0.6"),
              attribute("stop-color", "#B1DB4B"),
            ]),
          ),
          $svg.stop(
            toList([
              attribute("stop-opacity", "0"),
              attribute("stop-color", "#E6FAB5"),
              attribute("offset", "1"),
            ]),
          ),
        ]),
      ),
      $svg.linear_gradient(
        toList([
          attribute("gradientUnits", "userSpaceOnUse"),
          attribute("y2", "117.049"),
          attribute("x2", "96.666"),
          attribute("y1", "117.049"),
          attribute("x1", "56.8484"),
          $attribute.id("paint2_linear_6792_147"),
        ]),
        toList([
          $svg.stop(
            toList([
              attribute("stop-opacity", "0"),
              attribute("stop-color", "#E6FAB5"),
            ]),
          ),
          $svg.stop(
            toList([
              attribute("stop-color", "#E6FAB5"),
              attribute("offset", "1"),
            ]),
          ),
        ]),
      ),
      $svg.linear_gradient(
        toList([
          attribute("gradientUnits", "userSpaceOnUse"),
          attribute("y2", "106.707"),
          attribute("x2", "22.5898"),
          attribute("y1", "106.707"),
          attribute("x1", "101.801"),
          $attribute.id("paint3_linear_6792_147"),
        ]),
        toList([
          $svg.stop(
            toList([
              attribute("stop-opacity", "0.6"),
              attribute("stop-color", "#B1DB4B"),
            ]),
          ),
          $svg.stop(
            toList([
              attribute("stop-opacity", "0"),
              attribute("stop-color", "#E6FAB5"),
              attribute("offset", "1"),
            ]),
          ),
        ]),
      ),
      $svg.linear_gradient(
        toList([
          attribute("gradientUnits", "userSpaceOnUse"),
          attribute("y2", "113.735"),
          attribute("x2", "93.5605"),
          attribute("y1", "139.075"),
          attribute("x1", "64.5462"),
          $attribute.id("paint4_linear_6792_147"),
        ]),
        toList([
          $svg.stop(
            toList([
              attribute("stop-opacity", "0"),
              attribute("stop-color", "#D3C4FB"),
            ]),
          ),
          $svg.stop(
            toList([
              attribute("stop-color", "#D3C4FB"),
              attribute("offset", "1"),
            ]),
          ),
        ]),
      ),
      $svg.linear_gradient(
        toList([
          attribute("gradientUnits", "userSpaceOnUse"),
          attribute("y2", "64.2641"),
          attribute("x2", "100.179"),
          attribute("y1", "144.037"),
          attribute("x1", "58.4427"),
          $attribute.id("paint5_linear_6792_147"),
        ]),
        toList([
          $svg.stop(
            toList([
              attribute("stop-opacity", "0.6"),
              attribute("stop-color", "#AD94F0"),
            ]),
          ),
          $svg.stop(
            toList([
              attribute("stop-opacity", "0"),
              attribute("stop-color", "#D3C4FB"),
              attribute("offset", "1"),
            ]),
          ),
        ]),
      ),
      $svg.linear_gradient(
        toList([
          attribute("gradientUnits", "userSpaceOnUse"),
          attribute("y2", "98.2651"),
          attribute("x2", "129.797"),
          attribute("y1", "118.073"),
          attribute("x1", "36.3661"),
          $attribute.id("paint6_linear_6792_147"),
        ]),
        toList([
          $svg.stop(
            toList([
              attribute("stop-opacity", "0"),
              attribute("stop-color", "#D3C4FB"),
            ]),
          ),
          $svg.stop(
            toList([
              attribute("stop-color", "#D3C4FB"),
              attribute("offset", "1"),
            ]),
          ),
        ]),
      ),
      $svg.linear_gradient(
        toList([
          attribute("gradientUnits", "userSpaceOnUse"),
          attribute("y2", "39.1273"),
          attribute("x2", "8.20749"),
          attribute("y1", "-0.693582"),
          attribute("x1", "86.1042"),
          $attribute.id("paint7_linear_6792_147"),
        ]),
        toList([
          $svg.stop(
            toList([
              attribute("stop-opacity", "0.6"),
              attribute("stop-color", "#AD94F0"),
            ]),
          ),
          $svg.stop(
            toList([
              attribute("stop-opacity", "0"),
              attribute("stop-color", "#D3C4FB"),
              attribute("offset", "1"),
            ]),
          ),
        ]),
      ),
      $svg.linear_gradient(
        toList([
          attribute("gradientUnits", "userSpaceOnUse"),
          attribute("y2", "38.9065"),
          attribute("x2", "53.1757"),
          attribute("y1", "31.0486"),
          attribute("x1", "85.7484"),
          $attribute.id("paint8_linear_6792_147"),
        ]),
        toList([
          $svg.stop(
            toList([
              attribute("stop-opacity", "0.6"),
              attribute("stop-color", "#AD94F0"),
            ]),
          ),
          $svg.stop(
            toList([
              attribute("stop-opacity", "0"),
              attribute("stop-color", "#D3C4FB"),
              attribute("offset", "1"),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
