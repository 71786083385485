/// <reference types="./route.d.mts" />
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { None } from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $modem from "../../modem/modem.mjs";
import { updateTitle as do_update_title } from "../frontend.ffi.mjs";
import { Ok, Error, toList, CustomType as $CustomType, isEqual } from "../gleam.mjs";

export class Account extends $CustomType {}

export class Collaborators extends $CustomType {}

export class Integrations extends $CustomType {}

export class OnGoing extends $CustomType {}

export class Previous extends $CustomType {}

export class Add extends $CustomType {
  constructor(loading) {
    super();
    this.loading = loading;
  }
}

export class Show extends $CustomType {
  constructor(id, sheet, page) {
    super();
    this.id = id;
    this.sheet = sheet;
    this.page = page;
  }
}

export class AiSearch extends $CustomType {}

export class AiResponse extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class DataSources extends $CustomType {}

export class QuestionBank extends $CustomType {}

export class NewQuestion extends $CustomType {}

export class Ingestion extends $CustomType {}

export class Overview extends $CustomType {}

export class ContentLibrary extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Proposals extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class CoPilot extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Settings extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Organization extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Analytics extends $CustomType {}

export class Support extends $CustomType {}

export class Login extends $CustomType {
  constructor(invitation, organization) {
    super();
    this.invitation = invitation;
    this.organization = organization;
  }
}

export class SlackLogin extends $CustomType {
  constructor(team_id, user_id) {
    super();
    this.team_id = team_id;
    this.user_id = user_id;
  }
}

export class NotFound extends $CustomType {}

function optional_query_find(query, a) {
  let _pipe = $list.key_find(query, a);
  return $option.from_result(_pipe);
}

export function from_uri(uri) {
  let $ = $uri.path_segments(uri.path);
  if ($.hasLength(0)) {
    return new Overview();
  } else if ($.hasLength(1) && $.head === "content-library") {
    return new ContentLibrary(new DataSources());
  } else if ($.hasLength(1) && $.head === "question-bank") {
    return new ContentLibrary(new QuestionBank());
  } else if ($.hasLength(2) &&
  $.head === "question-bank" &&
  $.tail.head === "new") {
    return new ContentLibrary(new NewQuestion());
  } else if ($.hasLength(2) &&
  $.head === "question-bank" &&
  $.tail.head === "ingestion") {
    return new ContentLibrary(new Ingestion());
  } else if ($.hasLength(1) && $.head === "proposals") {
    return new Proposals(new OnGoing());
  } else if ($.hasLength(2) &&
  $.head === "proposals" &&
  $.tail.head === "previous") {
    return new Proposals(new Previous());
  } else if ($.hasLength(2) && $.head === "proposals" && $.tail.head === "add") {
    return new Proposals(new Add(false));
  } else if ($.hasLength(1) && $.head === "co-pilot") {
    return new CoPilot(new AiSearch());
  } else if ($.hasLength(2) && $.head === "co-pilot") {
    let id = $.tail.head;
    return new CoPilot(new AiResponse(id));
  } else if ($.hasLength(1) && $.head === "settings") {
    return new Settings(new Account());
  } else if ($.hasLength(2) &&
  $.head === "organization" &&
  $.tail.head === "collaborators") {
    return new Organization(new Collaborators());
  } else if ($.hasLength(2) &&
  $.head === "organization" &&
  $.tail.head === "integrations") {
    return new Organization(new Integrations());
  } else if ($.hasLength(2) && $.head === "slack" && $.tail.head === "login") {
    let _pipe = $uri.parse_query($option.unwrap(uri.query, ""));
    let _pipe$1 = $result.then$(
      _pipe,
      (query) => {
        return $result.try$(
          $list.key_find(query, "slack_team_id"),
          (team_id) => {
            return $result.try$(
              $list.key_find(query, "slack_user_id"),
              (user_id) => { return new Ok(new SlackLogin(team_id, user_id)); },
            );
          },
        );
      },
    );
    return $result.unwrap(_pipe$1, new NotFound());
  } else if ($.hasLength(1) && $.head === "login") {
    let _pipe = $option.to_result(uri.query, undefined);
    let _pipe$1 = $result.then$(_pipe, $uri.parse_query);
    let _pipe$2 = $result.then$(
      _pipe$1,
      (query) => {
        let invitation = optional_query_find(query, "invitation");
        let organization = optional_query_find(query, "organization");
        return new Ok(new Login(invitation, organization));
      },
    );
    return $result.unwrap(
      _pipe$2,
      new Login(new $option.None(), new $option.None()),
    );
  } else if ($.hasLength(1) && $.head === "analytics") {
    return new Analytics();
  } else if ($.hasLength(1) && $.head === "support") {
    return new Support();
  } else if ($.hasLength(2) && $.head === "proposals") {
    let id = $.tail.head;
    let _pipe = $option.to_result(uri.query, undefined);
    let _pipe$1 = $result.then$(_pipe, $uri.parse_query);
    let _pipe$2 = $result.then$(
      _pipe$1,
      (query) => {
        let page = (() => {
          let _pipe$2 = $list.key_find(query, "page");
          let _pipe$3 = $result.then$(_pipe$2, $int.parse);
          let _pipe$4 = $result.unwrap(_pipe$3, 1);
          return $int.subtract(_pipe$4, 1);
        })();
        let sheet = optional_query_find(query, "sheet");
        return new Ok(new Proposals(new Show(id, sheet, page)));
      },
    );
    return $result.unwrap(
      _pipe$2,
      new Proposals(new Show(id, new $option.None(), 0)),
    );
  } else {
    return new NotFound();
  }
}

function not_found() {
  return new $uri.Uri(
    new None(),
    new None(),
    new None(),
    new None(),
    "#",
    new None(),
    new None(),
  );
}

export function to_uri(route) {
  let _pipe = (() => {
    if (route instanceof Overview) {
      return $uri.parse("/");
    } else if (route instanceof ContentLibrary &&
    route[0] instanceof DataSources) {
      return $uri.parse("/content-library");
    } else if (route instanceof ContentLibrary &&
    route[0] instanceof QuestionBank) {
      return $uri.parse("/question-bank");
    } else if (route instanceof ContentLibrary &&
    route[0] instanceof NewQuestion) {
      return $uri.parse("/question-bank/new");
    } else if (route instanceof ContentLibrary && route[0] instanceof Ingestion) {
      return $uri.parse("/question-bank/ingestion");
    } else if (route instanceof Proposals && route[0] instanceof OnGoing) {
      return $uri.parse("/proposals");
    } else if (route instanceof Proposals && route[0] instanceof Previous) {
      return $uri.parse("/proposals/previous");
    } else if (route instanceof Proposals && route[0] instanceof Add) {
      return $uri.parse("/proposals/add");
    } else if (route instanceof CoPilot && route[0] instanceof AiSearch) {
      return $uri.parse("/co-pilot");
    } else if (route instanceof CoPilot && route[0] instanceof AiResponse) {
      let id = route[0][0];
      return $uri.parse("/co-pilot/" + id);
    } else if (route instanceof Settings && route[0] instanceof Account) {
      return $uri.parse("/settings");
    } else if (route instanceof Organization &&
    route[0] instanceof Collaborators) {
      return $uri.parse("/organization/collaborators");
    } else if (route instanceof Organization && route[0] instanceof Integrations) {
      return $uri.parse("/organization/integrations");
    } else if (route instanceof Analytics) {
      return $uri.parse("/analytics");
    } else if (route instanceof Support) {
      return $uri.parse("/support");
    } else if (route instanceof SlackLogin) {
      let team_id = route.team_id;
      let user_id = route.user_id;
      let _pipe = $uri.parse("/slack/login");
      return $result.map(
        _pipe,
        (uri) => {
          let _pipe$1 = toList([
            ["slack_team_id", team_id],
            ["slack_user_id", user_id],
          ]);
          let _pipe$2 = $uri.query_to_string(_pipe$1);
          return ((query) => {
            return uri.withFields({ query: new $option.Some(query) });
          })(_pipe$2);
        },
      );
    } else if (route instanceof NotFound) {
      return new Ok(not_found());
    } else if (route instanceof Login) {
      let invitation = route.invitation;
      let organization = route.organization;
      return $result.map(
        $uri.parse("/login"),
        (uri) => {
          if (invitation instanceof $option.Some &&
          organization instanceof $option.Some) {
            let i = invitation[0];
            let o = organization[0];
            let query = $uri.query_to_string(
              toList([["invitation", i], ["organization", o]]),
            );
            return uri.withFields({ query: new $option.Some(query) });
          } else {
            return uri;
          }
        },
      );
    } else {
      let id = route[0].id;
      let sheet = route[0].sheet;
      let page = route[0].page;
      return $result.map(
        $uri.parse("/proposals/" + id),
        (uri) => {
          let base_queries = (() => {
            let _pipe = $option.map(
              sheet,
              (s) => { return toList([["sheet", s]]); },
            );
            return $option.unwrap(_pipe, toList([]));
          })();
          let _pipe = toList([["page", $int.to_string(page + 1)]]);
          let _pipe$1 = $list.append(_pipe, base_queries);
          let _pipe$2 = $uri.query_to_string(_pipe$1);
          let _pipe$3 = new $option.Some(_pipe$2);
          return ((query) => { return uri.withFields({ query: query }); })(
            _pipe$3,
          );
        },
      );
    }
  })();
  return $result.lazy_unwrap(_pipe, not_found);
}

export function update_title(route) {
  return $effect.from(
    (_) => {
      let title = (() => {
        if (route instanceof Overview) {
          return "Overview";
        } else if (route instanceof ContentLibrary) {
          return "Content Library";
        } else if (route instanceof Proposals) {
          return "Proposals";
        } else if (route instanceof CoPilot) {
          return "Co-pilot";
        } else if (route instanceof Settings && route[0] instanceof Account) {
          return "Settings";
        } else if (route instanceof Organization &&
        route[0] instanceof Collaborators) {
          return "Organization Members";
        } else if (route instanceof Organization &&
        route[0] instanceof Integrations) {
          return "Organization Integrations";
        } else if (route instanceof Analytics) {
          return "Analytics";
        } else if (route instanceof Support) {
          return "Support";
        } else if (route instanceof Login) {
          return "Login";
        } else if (route instanceof SlackLogin) {
          return "Slack Login";
        } else {
          return "Not Found";
        }
      })();
      do_update_title("Steerlab — " + title);
      return undefined;
    },
  );
}

export function push(route) {
  let uri = to_uri(route);
  return $modem.push(uri.path, uri.query, uri.fragment);
}

export function replace(route) {
  let uri = to_uri(route);
  return $modem.replace(uri.path, uri.query, uri.fragment);
}

export function current_proposal_id(route) {
  if (route instanceof Proposals && route[0] instanceof Show) {
    let pid = route[0].id;
    return new Ok(pid);
  } else {
    return new Error(undefined);
  }
}

export function is_already_on_proposal(route, proposal_id) {
  return isEqual(current_proposal_id(route), new Ok(proposal_id));
}
