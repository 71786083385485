/// <reference types="./question.d.mts" />
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $translate from "../data/translate.mjs";
import * as $decode_ from "../decode/extra.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../gleam.mjs";

export class Long extends $CustomType {}

export class YesNo extends $CustomType {}

export class MultipleChoice extends $CustomType {}

export class Display extends $CustomType {
  constructor(score) {
    super();
    this.score = score;
  }
}

export class Answer extends $CustomType {
  constructor(short, long, yes_no, choice, custom) {
    super();
    this.short = short;
    this.long = long;
    this.yes_no = yes_no;
    this.choice = choice;
    this.custom = custom;
  }
}

export class Metadata extends $CustomType {
  constructor(x, y, language) {
    super();
    this.x = x;
    this.y = y;
    this.language = language;
  }
}

export class Question extends $CustomType {
  constructor(id, proposal_id, data_points_id, display, owner, kind, choices, sheet, content, answer, validated, metadata, ai_processing, assign_processing, qa_order) {
    super();
    this.id = id;
    this.proposal_id = proposal_id;
    this.data_points_id = data_points_id;
    this.display = display;
    this.owner = owner;
    this.kind = kind;
    this.choices = choices;
    this.sheet = sheet;
    this.content = content;
    this.answer = answer;
    this.validated = validated;
    this.metadata = metadata;
    this.ai_processing = ai_processing;
    this.assign_processing = assign_processing;
    this.qa_order = qa_order;
  }
}

export function translate_question_and_answer(q, translations) {
  let language = q.metadata.language;
  if (language instanceof $option.Some) {
    let language$1 = language[0];
    let language$2 = $translate.decode_language_iso639_1(language$1);
    let content = (() => {
      let _pipe = translations;
      let _pipe$1 = $dict.get(
        _pipe,
        (() => {
          let _pipe$1 = toList([
            language$2,
            (() => {
              let _pipe$1 = new $translate.Question();
              return $translate.decode_translation_target_small(_pipe$1);
            })(),
            q.id,
          ]);
          return $string.join(_pipe$1, "_");
        })(),
      );
      let _pipe$2 = $result.then$(_pipe$1, (t) => { return new Ok(t.content); });
      let _pipe$3 = $option.from_result(_pipe$2);
      return $option.unwrap(_pipe$3, q.content);
    })();
    let custom = (() => {
      let _pipe = translations;
      let _pipe$1 = $dict.get(
        _pipe,
        (() => {
          let _pipe$1 = toList([
            language$2,
            (() => {
              let _pipe$1 = new $translate.Answer();
              return $translate.decode_translation_target_small(_pipe$1);
            })(),
            q.id,
          ]);
          return $string.join(_pipe$1, "_");
        })(),
      );
      let _pipe$2 = $result.then$(_pipe$1, (t) => { return new Ok(t.content); });
      let _pipe$3 = $option.from_result(_pipe$2);
      return $option.or(_pipe$3, q.answer.custom);
    })();
    return q.withFields({
      content: content,
      answer: q.answer.withFields({ custom: custom })
    });
  } else {
    return q;
  }
}

export function decode_question_type(dyn) {
  let $ = $dynamic.string(dyn);
  if ($.isOk()) {
    let s = $[0];
    if (s === "long") {
      return new Ok(new Long());
    } else if (s === "yes-no") {
      return new Ok(new YesNo());
    } else if (s === "multiple-choice") {
      return new Ok(new MultipleChoice());
    } else {
      return new Error(toList([]));
    }
  } else {
    let e = $[0];
    return new Error(e);
  }
}

export function encode_metadata(metadata) {
  return $json.object(
    toList([
      ["x", $json.nullable(metadata.x, $json.int)],
      ["y", $json.nullable(metadata.y, $json.int)],
    ]),
  );
}

export function encode_answer(answer) {
  return $json.object(
    toList([
      ["short", $json.nullable(answer.short, $json.string)],
      ["long", $json.nullable(answer.long, $json.string)],
      ["custom", $json.nullable(answer.custom, $json.string)],
      ["yes_no", $json.nullable(answer.yes_no, $json.string)],
      ["choice", $json.nullable(answer.choice, $json.string)],
    ]),
  );
}

export function encode_display(display) {
  return $json.object(
    toList([["score", $json.nullable(display.score, $json.float)]]),
  );
}

export function question_type_to_string(q) {
  if (q instanceof Long) {
    return "long";
  } else if (q instanceof YesNo) {
    return "yes-no";
  } else {
    return "multiple-choice";
  }
}

export function encode_question_type(q) {
  let _pipe = question_type_to_string(q);
  return $json.string(_pipe);
}

export function encode(question) {
  return $json.object(
    toList([
      ["id", $json.string(question.id)],
      ["proposal_id", $json.string(question.proposal_id)],
      [
        "data_points_id",
        $json.array(
          question.data_points_id,
          (s) => {
            return $json.object(
              toList([
                ["data_point_id", $json.string(s[0])],
                ["score", $json.float(s[1])],
              ]),
            );
          },
        ),
      ],
      ["owner", $json.nullable(question.owner, $json.string)],
      ["display", encode_display(question.display)],
      ["kind", $json.array(question.kind, encode_question_type)],
      ["choices", $json.array(question.choices, $json.string)],
      ["sheet", $json.nullable(question.sheet, $json.string)],
      ["content", $json.string(question.content)],
      ["answer", encode_answer(question.answer)],
      ["validated", $json.bool(question.validated)],
      ["metadata", encode_metadata(question.metadata)],
      ["ai_processing", $json.bool(question.ai_processing)],
      ["assign_processing", $json.bool(question.assign_processing)],
      ["qa_order", $json.int(question.qa_order)],
    ]),
  );
}

export function update_custom_answer(question, custom) {
  let custom$1 = new $option.Some(custom);
  let answer = question.answer.withFields({ custom: custom$1 });
  return question.withFields({ answer: answer });
}

export function get_answer_text(answer) {
  if (answer instanceof Answer && answer.custom instanceof $option.Some) {
    let q = answer.custom[0];
    return q;
  } else if (answer instanceof Answer && answer.short instanceof $option.Some) {
    let q = answer.short[0];
    return q;
  } else if (answer instanceof Answer &&
  answer.short instanceof $option.None &&
  answer.long instanceof $option.Some) {
    let q = answer.long[0];
    return q;
  } else if (answer instanceof Answer &&
  answer.short instanceof $option.None &&
  answer.long instanceof $option.None &&
  answer.yes_no instanceof $option.Some) {
    let q = answer.yes_no[0];
    return q;
  } else if (answer instanceof Answer &&
  answer.short instanceof $option.None &&
  answer.long instanceof $option.None &&
  answer.yes_no instanceof $option.None &&
  answer.choice instanceof $option.Some) {
    let q = answer.choice[0];
    return q;
  } else {
    return "";
  }
}

function decode_metadata(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (x) => {
        return $decode.parameter(
          (y) => {
            return $decode.parameter(
              (language) => { return new Metadata(x, y, language); },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "x", $decode.optional($decode.int));
  let _pipe$2 = $decode.field(_pipe$1, "y", $decode.optional($decode.int));
  let _pipe$3 = $decode.field(
    _pipe$2,
    "language",
    $decode.optional($decode_.dynamic($translate.decode_language_iso639_1_dyn)),
  );
  return $decode.from(_pipe$3, dyn);
}

export function decode(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (id) => {
        return $decode.parameter(
          (proposal_id) => {
            return $decode.parameter(
              (data_points_id) => {
                return $decode.parameter(
                  (owner) => {
                    return $decode.parameter(
                      (display) => {
                        return $decode.parameter(
                          (kind) => {
                            return $decode.parameter(
                              (choices) => {
                                return $decode.parameter(
                                  (sheet) => {
                                    return $decode.parameter(
                                      (content) => {
                                        return $decode.parameter(
                                          (answer) => {
                                            return $decode.parameter(
                                              (validated) => {
                                                return $decode.parameter(
                                                  (metadata) => {
                                                    return $decode.parameter(
                                                      (ai_processing) => {
                                                        return $decode.parameter(
                                                          (assign_processing) => {
                                                            return $decode.parameter(
                                                              (qa_order) => {
                                                                return new Question(
                                                                  id,
                                                                  proposal_id,
                                                                  data_points_id,
                                                                  display,
                                                                  owner,
                                                                  kind,
                                                                  choices,
                                                                  sheet,
                                                                  content,
                                                                  answer,
                                                                  validated,
                                                                  metadata,
                                                                  ai_processing,
                                                                  assign_processing,
                                                                  qa_order,
                                                                );
                                                              },
                                                            );
                                                          },
                                                        );
                                                      },
                                                    );
                                                  },
                                                );
                                              },
                                            );
                                          },
                                        );
                                      },
                                    );
                                  },
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "id",
    (() => {
      let _pipe$1 = $decode.string;
      return $decode.map(_pipe$1, $string.lowercase);
    })(),
  );
  let _pipe$2 = $decode.field(
    _pipe$1,
    "proposal_id",
    (() => {
      let _pipe$2 = $decode.string;
      return $decode.map(_pipe$2, $string.lowercase);
    })(),
  );
  let _pipe$3 = $decode.field(
    _pipe$2,
    "data_points_id",
    (() => {
      let _pipe$3 = $decode.optional(
        $decode_.json(
          (dyn) => {
            let _pipe$3 = $decode.list(
              (() => {
                let _pipe$3 = $decode.into(
                  $decode.parameter(
                    (a) => {
                      return $decode.parameter(
                        (b) => {
                          if (a instanceof $option.Some &&
                          b instanceof $option.Some) {
                            let a$1 = a[0];
                            let b$1 = b[0];
                            return new $option.Some([a$1, b$1]);
                          } else {
                            return new $option.None();
                          }
                        },
                      );
                    },
                  ),
                );
                let _pipe$4 = $decode.field(
                  _pipe$3,
                  "data_point_id",
                  $decode.optional($decode.string),
                );
                return $decode.field(
                  _pipe$4,
                  "score",
                  $decode.optional($decode.float),
                );
              })(),
            );
            let _pipe$4 = $decode.map(_pipe$3, $option.values);
            let _pipe$5 = $decode.map(
              _pipe$4,
              (_capture) => {
                return $list.sort(
                  _capture,
                  (a, b) => { return $float.compare(b[1], a[1]); },
                );
              },
            );
            return $decode.from(_pipe$5, dyn);
          },
        ),
      );
      return $decode.map(
        _pipe$3,
        (_capture) => { return $option.unwrap(_capture, toList([])); },
      );
    })(),
  );
  let _pipe$4 = $decode.field(
    _pipe$3,
    "owner",
    $decode.optional($decode.string),
  );
  let _pipe$5 = $decode.field(_pipe$4, "display", $decode_.json(decode_display));
  let _pipe$6 = $decode.field(
    _pipe$5,
    "kind",
    $decode.list($decode_.dynamic(decode_question_type)),
  );
  let _pipe$7 = $decode.field(_pipe$6, "choices", $decode.list($decode.string));
  let _pipe$8 = $decode.field(
    _pipe$7,
    "sheet",
    $decode.optional($decode.string),
  );
  let _pipe$9 = $decode.field(_pipe$8, "content", $decode.string);
  let _pipe$10 = $decode.field(_pipe$9, "answer", $decode_.json(decode_answer));
  let _pipe$11 = $decode.field(_pipe$10, "validated", $decode.bool);
  let _pipe$12 = $decode.field(
    _pipe$11,
    "metadata",
    $decode_.json(decode_metadata),
  );
  let _pipe$13 = $decode.field(_pipe$12, "ai_processing", $decode.bool);
  let _pipe$14 = $decode.field(_pipe$13, "assign_processing", $decode.bool);
  let _pipe$15 = $decode.field(_pipe$14, "qa_order", $decode.int);
  return $decode.from(_pipe$15, dyn);
}

export function decode_display(dyn) {
  let _pipe = $decode.into(
    $decode.parameter((score) => { return new Display(score); }),
  );
  let _pipe$1 = $decode.field(_pipe, "score", $decode.optional($decode.float));
  return $decode.from(_pipe$1, dyn);
}

export function decode_answer(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (short) => {
        return $decode.parameter(
          (long) => {
            return $decode.parameter(
              (yes_no) => {
                return $decode.parameter(
                  (choice) => {
                    return $decode.parameter(
                      (custom) => {
                        return new Answer(short, long, yes_no, choice, custom);
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "short", $decode.optional($decode.string));
  let _pipe$2 = $decode.field(_pipe$1, "long", $decode.optional($decode.string));
  let _pipe$3 = $decode.field(
    _pipe$2,
    "yes_no",
    $decode.optional($decode.string),
  );
  let _pipe$4 = $decode.field(
    _pipe$3,
    "choice",
    $decode.optional($decode.string),
  );
  let _pipe$5 = $decode.field(
    _pipe$4,
    "custom",
    $decode.optional($decode.string),
  );
  let _pipe$6 = $decode.optional(_pipe$5);
  let _pipe$7 = $decode.map(
    _pipe$6,
    (_capture) => {
      return $option.unwrap(
        _capture,
        new Answer(
          new $option.None(),
          new $option.None(),
          new $option.None(),
          new $option.None(),
          new $option.None(),
        ),
      );
    },
  );
  return $decode.from(_pipe$7, dyn);
}
