import * as lustre from '../lustre/lustre.mjs'
import * as effect from '../lustre/lustre/effect.mjs'
import * as element from '../lustre/lustre/element.mjs'
import * as event from '../lustre/lustre/event.mjs'
import * as runtime from '../lustre/lustre/internals/runtime.mjs'
import * as gleam from './gleam.mjs'

export function coerceEventDetails(event) {
  event.stopPropagation()
  return event.detail
}

let id = 0

export function uniqueId() {
  return id++
}

export function coerce(a) {
  return a
}

export class LustrePortal extends HTMLElement {
  #mountNode
  #renderNode
  #content
  #send
  #model

  constructor() {
    super()
    this.#model = 0
    this.#mountNode = document.createElement('div')
    this.#mountNode.setAttribute('class', 'lustre-portal')
    this.#mountNode.addEventListener('portal-event', event => {
      this.dispatchEvent(
        new CustomEvent('portal-event', {
          bubbles: true,
          detail: event.detail,
          composed: true,
        })
      )
    })
    this.#renderNode = document.createElement('div')
    this.#mountNode.appendChild(this.#renderNode)
    this.#content = gleam.List.fromArray([element.none()])
    Object.defineProperty(this, 'content', {
      set(value) {
        this.#content = value
        this.#model += 1
        this.#send?.(new runtime.Debug(new runtime.ForceModel(this.#model)))
      },
    })
  }

  connectedCallback() {
    document.body.appendChild(this.#mountNode)
    const init = () => [this.#model, effect.none()]
    const update = (model, msg) => [model, event.emit('portal-event', msg)]
    const view = model => this.#content
    const app = lustre.application(init, update, view)
    const result = lustre.start(app, this.#renderNode, null)
    if (result instanceof gleam.Ok) {
      this.#send = result[0]
    }
  }

  disconnectedCallback() {
    document.body.removeChild(this.#mountNode)
    this.#send?.(new runtime.Shutdown())
  }
}

export function register() {
  const name = 'lustre-portal'
  if (window.customElements.get(name)) {
    return new gleam.Error(new lustre.ComponentAlreadyRegistered(name))
  }
  customElements.define(name, LustrePortal)
  return new gleam.Ok()
}
