/// <reference types="./connector_settings.d.mts" />
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $function from "../../gleam_stdlib/gleam/function.mjs";
import * as $connector from "../data/connector.mjs";
import * as $decode_ from "../decode/extra.mjs";
import { Ok, toList, CustomType as $CustomType } from "../gleam.mjs";

export class GoogleDrive extends $CustomType {
  constructor(folder) {
    super();
    this.folder = folder;
  }
}

export function encode_data(connector_settings) {
  {
    let folder = connector_settings.folder;
    return $json.object(toList([["folder", $json.string(folder)]]));
  }
}

export function encode(connector_settings) {
  {
    return $json.object(
      toList([
        ["kind", $connector.encode(new $connector.GoogleDrive())],
        ["data", encode_data(connector_settings)],
      ]),
    );
  }
}

export function decode(dyn) {
  let _pipe = $decode.into($function.identity);
  let _pipe$1 = $decode.field(
    _pipe,
    "kind",
    $decode_.dynamic($connector.decode),
  );
  let _pipe$2 = $decode.then$(
    _pipe$1,
    (type_) => {
      if (type_ instanceof $connector.GoogleDrive) {
        let _pipe$2 = $decode.into((var0) => { return new GoogleDrive(var0); });
        return $decode.field(
          _pipe$2,
          "data",
          $decode_.json(
            (dyn) => { return $dynamic.field("folder", $dynamic.string)(dyn); },
          ),
        );
      } else {
        return $decode.fail(
          ("Incorrect connector. Got " + $connector.to_string(type_)) + ", unsupported.",
        );
      }
    },
  );
  return $decode.from(_pipe$2, dyn);
}

export function is_google_drive(settings) {
  {
    return true;
  }
}

export function google_folder_id(settings) {
  {
    let folder = settings.folder;
    return new Ok(folder);
  }
}

export function kind(settings) {
  {
    return new $connector.GoogleDrive();
  }
}
