import * as Sentry from '@sentry/browser'

export function init(dsn) {
  const integrations = [Sentry.browserTracingIntegration(), Sentry.replayIntegration()]
  const environment =
    window?.location?.host?.includes('localhost') ?? false
      ? 'development'
      : import.meta.env.VITE_GLEAM_ENV ?? 'production'
  Sentry.init({ dsn, integrations, replaysOnErrorSampleRate: 1.0, tracesSampleRate: 1.0, environment })
}

export function captureError(error) {
  const err = new Error(error)
  Sentry.captureException(err)
}

export function captureMessage(message) {
  Sentry.captureMessage(message)
}
