/// <reference types="./pie_chart.d.mts" />
import * as $attribute from "../../lustre/lustre/attribute.mjs";
import * as $element from "../../lustre/lustre/element.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Dataset extends $CustomType {
  constructor(colors, data) {
    super();
    this.colors = colors;
    this.data = data;
  }
}

export function pie_chart(datasets) {
  let datasets$1 = $attribute.property("datasets", datasets);
  return $element.element(
    "pie-chart",
    toList([$attribute.style(toList([["display", "block"]])), datasets$1]),
    toList([]),
  );
}
