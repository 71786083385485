/// <reference types="./loading.d.mts" />
import { CustomType as $CustomType } from "../../gleam.mjs";

export class Loading extends $CustomType {
  constructor(content_library, proposals, copilot_questions, connectors, organization_members, connectors_settings, notifications, users, questions) {
    super();
    this.content_library = content_library;
    this.proposals = proposals;
    this.copilot_questions = copilot_questions;
    this.connectors = connectors;
    this.organization_members = organization_members;
    this.connectors_settings = connectors_settings;
    this.notifications = notifications;
    this.users = users;
    this.questions = questions;
  }
}

export function new$() {
  return new Loading(true, true, true, true, true, true, true, true, false);
}

export function content_library(loading) {
  return loading.withFields({ content_library: false });
}

export function proposals(loading) {
  return loading.withFields({ proposals: false });
}

export function copilot_questions(loading) {
  return loading.withFields({ copilot_questions: false });
}

export function connectors(loading) {
  return loading.withFields({ connectors: false });
}

export function organization_members(loading) {
  return loading.withFields({ organization_members: false });
}

export function connectors_settings(loading) {
  return loading.withFields({ connectors_settings: false });
}

export function notifications(loading) {
  return loading.withFields({ notifications: false });
}

export function users(loading) {
  return loading.withFields({ users: true });
}

export function questions_loading(loading) {
  return loading.withFields({ questions: true });
}

export function questions_loaded(loading) {
  return loading.withFields({ questions: false });
}
