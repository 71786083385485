/// <reference types="./position.d.mts" />
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $float from "../../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $window from "../../../plinth/plinth/browser/window.mjs";
import * as $sketch from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px, px_ } from "../../../sketch/sketch/size.mjs";
import { getEventBoundingClientRect as do_get_event_bounding_client_rect } from "../../frontend.ffi.mjs";
import { toList, CustomType as $CustomType, divideInt } from "../../gleam.mjs";

export class DomRect extends $CustomType {
  constructor(top, bottom, left, right, width, height, x, y) {
    super();
    this.top = top;
    this.bottom = bottom;
    this.left = left;
    this.right = right;
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
  }
}

export class Right extends $CustomType {}

export class Left extends $CustomType {}

export function get_event_bounding_client_rect(event) {
  let _pipe = do_get_event_bounding_client_rect(event);
  return $result.map_error(
    _pipe,
    (found) => {
      let expected = "domrect-parent-wrapper";
      return toList([new $dynamic.DecodeError(expected, found, toList([]))]);
    },
  );
}

function compute_bottom_position(rect, inner_height) {
  let bottom = (inner_height - $float.round(rect.y)) + 6;
  let bottom$1 = px(bottom);
  return $sketch.bottom(bottom$1);
}

function compute_top_position(rect) {
  let top = ((0 + $float.round(rect.y)) + $float.round(rect.height)) + 6;
  let top$1 = px(top);
  return $sketch.top(top$1);
}

export function compute_vertical_position(rect) {
  let inner_height = $window.inner_height($window.self());
  let is_third_quarter_down = $float.round(rect.y) > 3 * (divideInt(
    inner_height,
    4
  ));
  if (is_third_quarter_down) {
    return compute_bottom_position(rect, inner_height);
  } else {
    return compute_top_position(rect);
  }
}

export function compute_horizontal_position(rect, position) {
  if (position instanceof Left) {
    return $sketch.left(px_(rect.left));
  } else {
    let inner_width = $int.to_float($window.inner_width($window.self()));
    return $sketch.right(px_(inner_width - rect.right));
  }
}

export function require_dom_rect(dom_rect, next) {
  let _pipe = dom_rect;
  let _pipe$1 = $option.map(_pipe, next);
  return $option.lazy_unwrap(_pipe$1, $element.none);
}
