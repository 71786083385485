/// <reference types="./content_library.d.mts" />
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $level from "../../grille_pain/grille_pain/toast/level.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $modem from "../../modem/modem.mjs";
import * as $content_library from "../data/content_library.mjs";
import { ContentLibrary } from "../data/content_library.mjs";
import * as $model from "../data/model.mjs";
import { Model } from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import * as $loading from "../data/ui/loading.mjs";
import * as $effects from "../frontend/effects.mjs";
import * as $content_library_effects from "../frontend/effects/content_library.mjs";
import * as $middleware from "../frontend/middleware.mjs";
import { require_not_modifying_question } from "../frontend/middleware.mjs";
import { toList, isEqual } from "../gleam.mjs";
import * as $spreadsheet_selector from "../update/spreadsheet_selector.mjs";

function handle_content_library_spreadsheet_selector(model, msg) {
  if (msg instanceof $msg.UserSubmittedXlsx) {
    let _pipe = model.content_library.withFields({ ingestion_loading: true });
    let _pipe$1 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe);
    return $pair.new$(
      _pipe$1,
      $content_library_effects.ingest_question_bank(model),
    );
  } else if (msg instanceof $msg.UserCancelledSpreadsheetSelection) {
    let _pipe = model.content_library;
    let _pipe$1 = $content_library.reset_ingestion_spreadsheet(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $modem.back(1));
  } else {
    let msg$1 = msg;
    let $ = model.content_library;
    let ingestion_spreadsheet = $.ingestion_spreadsheet;
    let _pipe = $spreadsheet_selector.update(
      model,
      ingestion_spreadsheet,
      msg$1,
    );
    let _pipe$1 = $pair.map_second(
      _pipe,
      (e) => {
        let _pipe$1 = $effect.map(
          e,
          (var0) => {
            return new $msg.ContentLibraryHandledSpreadsheetSelector(var0);
          },
        );
        let _pipe$2 = $effect.map(
          _pipe$1,
          (var0) => { return new $msg.ContentLibrary(var0); },
        );
        let _pipe$3 = (() => {
          let $1 = $spreadsheet_selector.is_xlsx_fetched(msg$1);
          if (!$1) {
            return $list.wrap;
          } else {
            return (_capture) => {
              return $list.prepend(
                toList([
                  $route.push(new $route.ContentLibrary(new $route.Ingestion())),
                ]),
                _capture,
              );
            };
          }
        })()(_pipe$2);
        return $effect.batch(_pipe$3);
      },
    );
    return $pair.map_first(
      _pipe$1,
      (ingestion_spreadsheet) => {
        let _pipe$2 = model.content_library.withFields({
          ingestion_spreadsheet: ingestion_spreadsheet
        });
        return ((_capture) => {
          return $model.set_content_library(model, _capture);
        })(_pipe$2);
      },
    );
  }
}

export function update(model, msg) {
  if (msg instanceof $msg.ApiReturnedSource) {
    let source = msg.source;
    let _pipe = model.content_library;
    let _pipe$1 = $content_library.upsert_data_source(_pipe, source);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.ApiReturnedSources) {
    let sources = msg.sources;
    return require_not_modifying_question(
      model,
      () => {
        let _pipe = model.content_library;
        let _pipe$1 = $content_library.set_data_sources(_pipe, sources);
        let _pipe$2 = ((_capture) => {
          return $model.set_content_library(model, _capture);
        })(_pipe$1);
        let _pipe$3 = $model.mark_as_loaded(_pipe$2, $loading.content_library);
        return $pair.new$(_pipe$3, $effect.none());
      },
    );
  } else if (msg instanceof $msg.UserSelectedSource) {
    let id = msg.id;
    let checked = msg.checked;
    let handler = (() => {
      if (checked) {
        return $content_library.select;
      } else {
        return $content_library.deselect;
      }
    })();
    let _pipe = model.content_library;
    let _pipe$1 = handler(_pipe, id);
    let _pipe$2 = $content_library.close_popups(_pipe$1);
    let _pipe$3 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$2);
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.UserToggledChangeOwnerPopup) {
    let _pipe = model.content_library;
    let _pipe$1 = $content_library.toggle_change_owner(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserToggledChangeStatusPopup) {
    let _pipe = model.content_library;
    let _pipe$1 = $content_library.toggle_change_status(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserToggledChangeTags) {
    let _pipe = model.content_library;
    let _pipe$1 = $content_library.toggle_change_tags(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserToggledChangeExpirationDatePopup) {
    let _pipe = model.content_library;
    let _pipe$1 = $content_library.toggle_change_expiration_date(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserClickedGlobalCheckbox) {
    let in_question_bank = isEqual(
      model.route,
      new $route.ContentLibrary(new $route.QuestionBank())
    );
    let selected = (() => {
      let all_selected = (() => {
        if (in_question_bank) {
          return $content_library.all_qna_selected(model.content_library);
        } else {
          return $content_library.all_non_qna_selected(model.content_library);
        }
      })();
      return $bool.guard(
        all_selected,
        $dict.new$(),
        () => {
          let sources = (() => {
            if (in_question_bank) {
              return $content_library.filtered_qna_sources(
                model.content_library,
              );
            } else {
              return $content_library.filtered_non_qna_sources(
                model.content_library,
              );
            }
          })();
          return $list.fold(
            sources,
            $dict.new$(),
            (selected, source) => {
              return $dict.insert(selected, source.id, true);
            },
          );
        },
      );
    })();
    let _pipe = model.content_library.withFields({ selected: selected });
    let _pipe$1 = $content_library.close_popups(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserAssignedStatus) {
    let status = msg.status;
    let _pipe = model.content_library;
    let _pipe$1 = $content_library.attribute_status(_pipe, status);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $content_library_effects.update_data_sources_status(model, status),
    );
  } else if (msg instanceof $msg.UserToggledAssignUserPopup) {
    let user_id = msg.user_id;
    let _pipe = model.content_library;
    let _pipe$1 = $content_library.attribute_source(_pipe, user_id);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $content_library_effects.update_data_sources_owner(model, user_id),
    );
  } else if (msg instanceof $msg.UserAssignedTag) {
    let tag = msg.tag;
    let add_remove = msg.add_remove;
    let _pipe = model.content_library;
    let _pipe$1 = $content_library.attribute_tag(_pipe, tag, add_remove);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $content_library_effects.add_remove_data_sources_tag(
        model,
        tag,
        add_remove,
      ),
    );
  } else if (msg instanceof $msg.UserAssignedExpirationDate) {
    let expiration_date = msg.expiration_date;
    let _pipe = model.content_library;
    let _pipe$1 = $content_library.attribute_expiration_date(
      _pipe,
      expiration_date,
    );
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    let _pipe$3 = update(
      _pipe$2,
      new $msg.UserToggledChangeExpirationDatePopup(),
    );
    return $pair.map_second(
      _pipe$3,
      (e) => {
        let _pipe$4 = $content_library_effects.update_data_sources_expiration_date(
          model,
          expiration_date,
        );
        let _pipe$5 = ((_capture) => {
          return $list.prepend(toList([e]), _capture);
        })(_pipe$4);
        return $effect.batch(_pipe$5);
      },
    );
  } else if (msg instanceof $msg.UserSelectedFilter) {
    let filter = msg.filter;
    let filter$1 = new $option.Some(filter);
    let _pipe = (() => {
      let $ = isEqual(model.content_library.filter_selected, filter$1);
      if ($) {
        return (_capture) => {
          return $content_library.set_filter(_capture, new $option.None());
        };
      } else {
        return (_capture) => {
          return $content_library.set_filter(_capture, filter$1);
        };
      }
    })();
    let _pipe$1 = ((f) => { return f(model.content_library); })(_pipe);
    let _pipe$2 = $content_library.deselect_all(_pipe$1);
    let _pipe$3 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$2);
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedSearch) {
    let search = msg.search;
    let _pipe = model.content_library.withFields({ search: search });
    let _pipe$1 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedExpirationDateInput) {
    let value = msg.value;
    let _pipe = model.content_library.withFields({
      change_expiration_date_input: value
    });
    let _pipe$1 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUploadedSourceFiles) {
    let sources = msg.sources;
    let _pipe = $content_library_effects.upload_content_library_sources(
      model,
      sources,
    );
    return ((_capture) => { return $pair.new$(model, _capture); })(_pipe);
  } else if (msg instanceof $msg.UserUpdatedQuestionBankEditQuestion) {
    let question = msg.question;
    let _pipe = model.content_library.new_qna.withFields({ question: question });
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(model.content_library, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedQuestionBankEditAnswer) {
    let answer = msg.answer;
    let _pipe = model.content_library.new_qna.withFields({ answer: answer });
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(model.content_library, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserSubmittedQuestionBankEdit) {
    let _pipe = model.content_library.new_qna.withFields({ loading: true });
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(model.content_library, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $content_library_effects.upload_question_bank_question(model),
    );
  } else if (msg instanceof $msg.ApiRejectedUploadingQuestion) {
    let cl = model.content_library.withFields({ ingestion_loading: false });
    let _pipe = model.content_library.new_qna.withFields({ loading: false });
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(cl, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      (() => {
        let _pipe$3 = "Internal server error. Please, retry later.";
        return $effects.toast(_pipe$3, new $level.Error());
      })(),
    );
  } else if (msg instanceof $msg.ApiReturnedUploadingQuestion) {
    let ingestion = msg.ingestion;
    let text = (() => {
      if (ingestion) {
        return "Questions";
      } else {
        return "Question";
      }
    })();
    let _pipe = model.content_library;
    let _pipe$1 = $content_library.reset_ingestion_spreadsheet(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $effect.batch(
        toList([
          $effects.toast(text + " successfully added!", new $level.Success()),
          (() => {
            let $ = model.route;
            if ($ instanceof $route.ContentLibrary &&
            $[0] instanceof $route.NewQuestion) {
              return $modem.back(1);
            } else if ($ instanceof $route.ContentLibrary &&
            $[0] instanceof $route.Ingestion) {
              return $modem.back(1);
            } else {
              return $effect.none();
            }
          })(),
        ]),
      ),
    );
  } else if (msg instanceof $msg.UserCreatedQuestion) {
    let _pipe = $content_library.init_new_qna();
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(model.content_library, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_content_library(model, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $route.push(new $route.ContentLibrary(new $route.NewQuestion())),
    );
  } else if (msg instanceof $msg.UserEdittedQuestion) {
    let question_id = msg.question_id;
    let all = model.content_library.qna_sources.all;
    let _pipe = $list.find(all, (s) => { return s.id === question_id; });
    let _pipe$1 = $result.map(
      _pipe,
      (source) => {
        let qna = source.display.qna;
        let map = (mapper) => {
          let _pipe$1 = $option.map(qna, mapper);
          return $option.unwrap(_pipe$1, "");
        };
        let question = map((q) => { return q.question; });
        let answer = map((q) => { return q.answer; });
        let _pipe$1 = new $content_library.NewQna(
          question,
          answer,
          false,
          new $option.Some(source),
        );
        let _pipe$2 = ((_capture) => {
          return $content_library.set_new_qna(model.content_library, _capture);
        })(_pipe$1);
        let _pipe$3 = ((_capture) => {
          return $model.set_content_library(model, _capture);
        })(_pipe$2);
        return $pair.new$(
          _pipe$3,
          $route.push(new $route.ContentLibrary(new $route.NewQuestion())),
        );
      },
    );
    return $result.unwrap(_pipe$1, [model, $effect.none()]);
  } else if (msg instanceof $msg.ContentLibraryHandledSpreadsheetSelector) {
    let msg$1 = msg[0];
    return handle_content_library_spreadsheet_selector(model, msg$1);
  } else {
    let non_qna_status = msg[0][0];
    let qna_status = msg[0][1];
    return require_not_modifying_question(
      model,
      () => {
        let _pipe = model.content_library.withFields({
          non_qna_status: non_qna_status,
          qna_status: qna_status
        });
        let _pipe$1 = ((_capture) => {
          return $model.set_content_library(model, _capture);
        })(_pipe);
        return $pair.new$(_pipe$1, $effect.none());
      },
    );
  }
}
