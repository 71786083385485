/// <reference types="./view.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $event from "../../../lustre/lustre/event.mjs";
import * as $sketch from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import * as $element from "../../../sketch_lustre/sketch/lustre/element.mjs";
import * as $html from "../../../sketch_lustre/sketch/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";
import * as $css from "../../grille_pain/internals/css.mjs";
import * as $model from "../../grille_pain/internals/data/model.mjs";
import { Model } from "../../grille_pain/internals/data/model.mjs";
import * as $msg from "../../grille_pain/internals/data/msg.mjs";
import { Hide, Resume, Stop } from "../../grille_pain/internals/data/msg.mjs";
import * as $toast from "../../grille_pain/internals/data/toast.mjs";
import * as $colors from "../../grille_pain/internals/view/colors.mjs";
import * as $progress_bar from "../../grille_pain/internals/view/progress_bar.mjs";
import * as $level from "../../grille_pain/toast/level.mjs";

function select_on_click_action(toast) {
  return $bool.lazy_guard(
    toast.sticky,
    $attribute.none,
    () => { return $event.on_click(new Hide(toast.id, toast.iteration)); },
  );
}

function toast_progress_bar(toast) {
  return $bool.lazy_guard(
    toast.sticky,
    $element.none,
    () => { return $progress_bar.view(toast); },
  );
}

function toast_wrapper(toast, attributes, children) {
  let min_bot = $int.max(0, toast.bottom);
  let _pipe = $sketch.class$(
    toList([
      $sketch.padding(px(12)),
      $sketch.position("fixed"),
      $sketch.top(px(min_bot)),
      $sketch.transition("right 0.7s, top 0.7s"),
      $sketch.z_index(1_000_000),
      (() => {
        let $ = toast.displayed;
        if ($) {
          return $sketch.right(px(0));
        } else {
          let width = $css.var$("grille_pain-width", "320px");
          return $sketch.right_(("calc(-1 * " + width) + " - 100px)");
        }
      })(),
    ]),
  );
  return $html.div(_pipe, attributes, children);
}

function wrapper_dom_classes(toast) {
  let displayed = (() => {
    let $ = toast.displayed;
    if ($) {
      return "visible";
    } else {
      return "hidden";
    }
  })();
  return $attribute.classes(
    toList([
      ["grille_pain-toast", true],
      ["grille_pain-toast-" + $int.to_string(toast.id), true],
      ["grille_pain-toast-" + displayed, true],
    ]),
  );
}

function toast_class() {
  return $sketch.class$(
    toList([
      $sketch.display("flex"),
      $sketch.flex_direction("column"),
      $sketch.width_($css.var$("grille_pain-toast-width", "320px")),
      $sketch.min_height_($css.var$("grille_pain-toast-min-height", "64px")),
      $sketch.max_height_($css.var$("grille_pain-toast-max-height", "800px")),
      $sketch.border_radius_(
        $css.var$("grille_pain-toast-border-radius", "6px"),
      ),
      $sketch.box_shadow("0px 4px 12px rgba(0, 0, 0, 0.1)"),
      $sketch.overflow("hidden"),
      $sketch.cursor("pointer"),
    ]),
  );
}

function toast_colors(level) {
  let $ = $colors.from_level(level);
  let background = $[0];
  let text_color = $[1];
  let level$1 = $level.to_string(level);
  let background_ = ("grille_pain-" + level$1) + "-background";
  let text = ("grille_pain-" + level$1) + "-text-color";
  return $sketch.class$(
    toList([
      $sketch.background($css.var$(background_, background)),
      $sketch.color($css.var$(text, text_color)),
    ]),
  );
}

function toast_container(toast, children) {
  let mouse_enter = $event.on_mouse_enter(new Stop(toast.id));
  let mouse_leave = $event.on_mouse_leave(new Resume(toast.id));
  let _pipe = toList([toast_colors(toast.level), toast_class()]);
  let _pipe$1 = $list.map(_pipe, $sketch.compose);
  let _pipe$2 = $sketch.class$(_pipe$1);
  return $html.div(_pipe$2, toList([mouse_enter, mouse_leave]), children);
}

function toast_content(attributes, children) {
  let _pipe = $sketch.class$(
    toList([
      $sketch.display("flex"),
      $sketch.align_items("center"),
      $sketch.flex("1"),
      $sketch.padding_("8px 16px"),
      $sketch.font_size(px(14)),
    ]),
  );
  return $html.div(_pipe, attributes, children);
}

function view_toast(toast) {
  let on_hide = select_on_click_action(toast);
  return toast_wrapper(
    toast,
    toList([wrapper_dom_classes(toast)]),
    toList([
      toast_container(
        toast,
        toList([
          toast_content(toList([on_hide]), toList([$html.text(toast.message)])),
          toast_progress_bar(toast),
        ]),
      ),
    ]),
  );
}

export function view(model) {
  let toasts = model.toasts;
  return $element.keyed(
    (_capture) => {
      return $html.div_(toList([$attribute.class$("grille-pain")]), _capture);
    },
    $list.map(
      toasts,
      (toast) => {
        let id = $int.to_string(toast.id);
        return [id, view_toast(toast)];
      },
    ),
  );
}
