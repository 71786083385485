/// <reference types="./proposals_table.d.mts" />
import * as $birl from "../../../birl/birl.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $status from "../../../common/data/status.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import * as $html from "../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $colors from "../../design_system/colors.mjs";
import * as $label from "../../design_system/components/label.mjs";
import * as $pp from "../../design_system/components/profile_picture.mjs";
import * as $progress_bar from "../../design_system/components/progress/bar.mjs";
import * as $skeleton from "../../design_system/components/skeleton.mjs";
import * as $table from "../../design_system/components/table.mjs";
import * as $text from "../../design_system/typography/text.mjs";
import { formatDate as format_date } from "../../frontend.ffi.mjs";
import { toList, makeError } from "../../gleam.mjs";
import * as $common from "../../view/common.mjs";
import * as $sp from "../../view/styles/proposals.mjs";

function to_due_date(time) {
  let _pipe = time;
  let _pipe$1 = $birl.to_iso8601(_pipe);
  return format_date(_pipe$1);
}

function empty_cell(child) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.padding_("30px 0"),
    ]),
  );
  return $html.div(_pipe, toList([]), toList([child]));
}

function empty_text_content(child) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.xs($text.medium)),
      $s.max_width(px(300)),
      $s.white_space("wrap"),
      $s.text_align("center"),
      $s.color($colors.light.grey_800),
    ]),
  );
  return $html.div(_pipe, toList([]), toList([child]));
}

const empty_text = "After you upload a proposal, it will appear here.";

function loading_table() {
  let _pipe = $table.cell($skeleton.skeleton(100, 25));
  let _pipe$1 = $list.repeat(_pipe, 7);
  let _pipe$2 = $table.row(_pipe$1);
  let _pipe$3 = $list.repeat(_pipe$2, 5);
  return $table.body(_pipe$3);
}

export function table(loading, proposals, users, show_trash_button) {
  return $table.table(
    toList([
      $table.headers(
        toList([
          $table.header("Name"),
          (() => {
            let _pipe = $table.header("Due Date");
            return $table.fit(_pipe);
          })(),
          $table.header("Owner"),
          $table.header("Client"),
          $table.header("Collaborators"),
          $table.header("Progress"),
          (() => {
            let _pipe = $table.header("Creation Date");
            return $table.fit(_pipe);
          })(),
          (() => {
            let _pipe = $table.header("Status");
            return $table.fit(_pipe);
          })(),
          (() => {
            let _pipe = $table.header("");
            return $table.fit(_pipe);
          })(),
        ]),
      ),
      (() => {
        if (loading) {
          return loading_table();
        } else {
          return body(proposals, users, show_trash_button);
        }
      })(),
    ]),
  );
}

function empty_table() {
  let empty_cell$1 = empty_cell(empty_text_content($el.text(empty_text)));
  return $table.body(
    toList([
      (() => {
        let _pipe = $table.row(
          toList([
            (() => {
              let _pipe = $table.cell(empty_cell$1);
              return $table.span(_pipe, 7);
            })(),
          ]),
        );
        return $table.hover(_pipe, false);
      })(),
    ]),
  );
}

function body(proposals, users, show_trash_button) {
  return $bool.lazy_guard(
    $list.is_empty(proposals),
    empty_table,
    () => {
      return $table.body(
        $list.map(
          proposals,
          (_use0) => {
            let id = _use0[0];
            let proposal = _use0[1];
            let _pipe = $table.row(
              toList([
                $table.cell($el.text(proposal.name)),
                $table.cell(
                  $el.text(
                    (() => {
                      let _pipe = proposal.deadline;
                      return to_due_date(_pipe);
                    })(),
                  ),
                ),
                $table.cell(
                  (() => {
                    let user = $list.find(
                      users,
                      (u) => { return u.id === proposal.owner; },
                    );
                    return $bool.guard(
                      $result.is_error(user),
                      $el.none(),
                      () => {
                        if (!user.isOk()) {
                          throw makeError(
                            "let_assert",
                            "view/components/proposals_table",
                            86,
                            "",
                            "Pattern match failed, no pattern matched the value.",
                            { value: user }
                          )
                        }
                        let user$1 = user[0];
                        return $common.name_small(
                          toList([]),
                          toList([
                            $pp.from_user(
                              user$1.picture,
                              user$1.initials,
                              new $pp.Medium(),
                            ),
                            $el.text(user$1.name),
                          ]),
                        );
                      },
                    );
                  })(),
                ),
                $table.cell($el.text(proposal.client)),
                $table.cell(
                  (() => {
                    let $ = proposal.collaborators;
                    if ($.hasLength(0)) {
                      return $el.none();
                    } else if ($.hasLength(1)) {
                      let user_id = $.head;
                      let user = $list.find(
                        users,
                        (u) => { return u.id === user_id; },
                      );
                      return $bool.guard(
                        $result.is_error(user),
                        $el.none(),
                        () => {
                          if (!user.isOk()) {
                            throw makeError(
                              "let_assert",
                              "view/components/proposals_table",
                              102,
                              "",
                              "Pattern match failed, no pattern matched the value.",
                              { value: user }
                            )
                          }
                          let user$1 = user[0];
                          return $common.name_small(
                            toList([]),
                            toList([
                              $pp.from_user(
                                user$1.picture,
                                user$1.initials,
                                new $pp.Medium(),
                              ),
                              $el.text(user$1.name),
                            ]),
                          );
                        },
                      );
                    } else {
                      let users_id = $;
                      return $common.stacked_avatars(users_id, users);
                    }
                  })(),
                ),
                $table.cell($progress_bar.progress(proposal.progress)),
                $table.cell(
                  $el.text(
                    (() => {
                      let _pipe = proposal.created_at;
                      return to_due_date(_pipe);
                    })(),
                  ),
                ),
                (() => {
                  let _pipe = $table.cell(
                    $label.label(
                      (() => {
                        let $ = proposal.status;
                        if ($ instanceof $status.Won) {
                          return new $label.Success();
                        } else if ($ instanceof $status.Cancelled) {
                          return new $label.Error();
                        } else if ($ instanceof $status.InProgress) {
                          return new $label.Current();
                        } else if ($ instanceof $status.Lost) {
                          return new $label.Error();
                        } else {
                          return new $label.Neutral();
                        }
                      })(),
                      $status.to_string(proposal.status),
                    ),
                  );
                  return $table.justify(_pipe, "start");
                })(),
                (() => {
                  if (!show_trash_button) {
                    return $table.cell($el.none());
                  } else {
                    let _pipe = $table.cell(
                      $sp.trash_icon_button(
                        toList([
                          $e.on_click(
                            new $msg.UserDisplayedDeleteProposalModal(id),
                          ),
                        ]),
                        toList([$sp.trash_icon_child()]),
                      ),
                    );
                    let _pipe$1 = $table.justify(_pipe, "end");
                    return $table.stop_cell_propagation(_pipe$1);
                  }
                })(),
              ]),
            );
            return $table.on_click(
              _pipe,
              new $msg.UserClickedProposal(id, new $option.None()),
            );
          },
        ),
      );
    },
  );
}
