/// <reference types="./text.d.mts" />
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { rem } from "../../../sketch/sketch/size.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

class Regular extends $CustomType {}

class Medium extends $CustomType {}

function weight_to_font_weight(weight) {
  if (weight instanceof Regular) {
    return "400";
  } else {
    return "500";
  }
}

export function xl(weight) {
  let weight$1 = weight_to_font_weight(weight);
  return $s.class$(
    toList([
      $s.font_weight(weight$1),
      $s.font_size(rem(1.25)),
      $s.line_height("1.875rem"),
    ]),
  );
}

export function lg(weight) {
  let weight$1 = weight_to_font_weight(weight);
  return $s.class$(
    toList([
      $s.font_weight(weight$1),
      $s.font_size(rem(1.125)),
      $s.line_height("1.75rem"),
    ]),
  );
}

export function md(weight) {
  let weight$1 = weight_to_font_weight(weight);
  return $s.class$(
    toList([
      $s.font_weight(weight$1),
      $s.font_size(rem(1.0)),
      $s.line_height("1.5rem"),
    ]),
  );
}

export function sm(weight) {
  let weight$1 = weight_to_font_weight(weight);
  return $s.class$(
    toList([
      $s.font_weight(weight$1),
      $s.font_size(rem(0.875)),
      $s.line_height("1.25rem"),
    ]),
  );
}

export function xs(weight) {
  let weight$1 = weight_to_font_weight(weight);
  return $s.class$(
    toList([
      $s.font_weight(weight$1),
      $s.font_size(rem(0.75)),
      $s.line_height("1.125rem"),
    ]),
  );
}

export const regular = /* @__PURE__ */ new Regular();

export const medium = /* @__PURE__ */ new Medium();
