/// <reference types="./dropdown.d.mts" />
import * as $d from "../../../common/gleam/dynamic/extra.mjs";
import * as $sentry from "../../../common/sentry.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $dyn from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $lustre from "../../../lustre/lustre.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $eff from "../../../lustre/lustre/effect.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $sketch from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import * as $magic from "../../../sketch_magic/sketch/magic.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $portal from "../../design_system/components/portal.mjs";
import * as $icons from "../../design_system/icons.mjs";
import * as $position from "../../design_system/internals/position.mjs";
import * as $s from "../../design_system/internals/styles/dropdown.mjs";
import * as $unsafe from "../../design_system/internals/unsafe.mjs";
import { toggleDomFreeze as do_toggle_dom_freeze } from "../../frontend.ffi.mjs";
import * as $effects from "../../frontend/effects.mjs";
import { Ok, Error, toList, CustomType as $CustomType, makeError, isEqual } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";

class Label extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Placeholder extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Disabled extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Choices extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Selected extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Icon extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnSelected extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class PanelClass extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class InputClass extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class ShowArrow extends $CustomType {}

class Position extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class NoAttribute extends $CustomType {}

class NoChoice extends $CustomType {}

class Section extends $CustomType {
  constructor(title, class$) {
    super();
    this.title = title;
    this.class = class$;
  }
}

class Choice extends $CustomType {
  constructor(title, icon, value, class$, mark_selected) {
    super();
    this.title = title;
    this.icon = icon;
    this.value = value;
    this.class = class$;
    this.mark_selected = mark_selected;
  }
}

export class ApplicationUpdatedChoices extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApplicationUpdatedDisabled extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApplicationUpdatedIcon extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApplicationUpdatedInputClass extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApplicationUpdatedLabel extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApplicationUpdatedPanelClass extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApplicationUpdatedPlaceholder extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApplicationUpdatedSelected extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApplicationUpdatedShowArrow extends $CustomType {}

export class ApplicationUpdatedPanelPosition extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class DocumentReturnedUnsubscriber extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserClickedOutside extends $CustomType {}

export class UserSelectedChoice extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserToggledDropdown extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Model extends $CustomType {
  constructor(disabled, icon, id, input_class, label, opened, options, panel_class, panel_position, placeholder, rect, selected, show_arrow, unsubscriber) {
    super();
    this.disabled = disabled;
    this.icon = icon;
    this.id = id;
    this.input_class = input_class;
    this.label = label;
    this.opened = opened;
    this.options = options;
    this.panel_class = panel_class;
    this.panel_position = panel_position;
    this.placeholder = placeholder;
    this.rect = rect;
    this.selected = selected;
    this.show_arrow = show_arrow;
    this.unsubscriber = unsubscriber;
  }
}

function attributes_to_html_attributes(attrs) {
  if (attrs instanceof Label) {
    let content = attrs[0];
    return $a.property("label", content);
  } else if (attrs instanceof Placeholder) {
    let content = attrs[0];
    return $a.property("placeholder", content);
  } else if (attrs instanceof Disabled) {
    let content = attrs[0];
    return $a.property("disabled", content);
  } else if (attrs instanceof Choices) {
    let content = attrs[0];
    return $a.property("choices", content);
  } else if (attrs instanceof Selected) {
    let content = attrs[0];
    return $a.property("selected", content);
  } else if (attrs instanceof Icon) {
    let content = attrs[0];
    return $a.property("icon", content);
  } else if (attrs instanceof PanelClass) {
    let class$ = attrs[0];
    return $a.property("panel-class", class$);
  } else if (attrs instanceof InputClass) {
    let class$ = attrs[0];
    return $a.property("input-class", class$);
  } else if (attrs instanceof NoAttribute) {
    return $a.none();
  } else if (attrs instanceof ShowArrow) {
    return $a.property("show-arrow", true);
  } else if (attrs instanceof Position) {
    let position = attrs[0];
    return $a.property("panel-position", position);
  } else {
    let map = attrs[0];
    return $e.on(
      "selected",
      (event) => {
        let _pipe = $dyn.field("detail", $dyn.dynamic)(event);
        let _pipe$1 = $result.map(_pipe, $unsafe.coerce);
        return $result.map(_pipe$1, map);
      },
    );
  }
}

export function label(text) {
  return new Label(text);
}

export function placeholder(text) {
  return new Placeholder(text);
}

export function disabled(value) {
  return new Disabled(value);
}

function options_list(value) {
  return new Choices(value);
}

export function selected(value) {
  return new Selected(value);
}

export function icon(value) {
  return new Icon(value);
}

export function none() {
  return new NoAttribute();
}

export function on_selected(msg) {
  return new OnSelected(msg);
}

export function input_class(class$) {
  return new InputClass(class$);
}

export function panel_class(class$) {
  return new PanelClass(class$);
}

export function show_arrow() {
  return new ShowArrow();
}

function succeed(dyn) {
  return new Ok($unsafe.coerce(dyn));
}

function on_attributes_change() {
  return $dict.from_list(
    toList([
      [
        "choices",
        (() => {
          let _pipe = $dyn.list(succeed);
          return $d.map(
            _pipe,
            (var0) => { return new ApplicationUpdatedChoices(var0); },
          );
        })(),
      ],
      [
        "label",
        (() => {
          let _pipe = $dyn.optional($dyn.string);
          return $d.map(
            _pipe,
            (var0) => { return new ApplicationUpdatedLabel(var0); },
          );
        })(),
      ],
      [
        "placeholder",
        (() => {
          let _pipe = $dyn.string;
          return $d.map(
            _pipe,
            (var0) => { return new ApplicationUpdatedPlaceholder(var0); },
          );
        })(),
      ],
      [
        "selected",
        (() => {
          let _pipe = $dyn.optional(succeed);
          return $d.map(
            _pipe,
            (var0) => { return new ApplicationUpdatedSelected(var0); },
          );
        })(),
      ],
      [
        "icon",
        (() => {
          let _pipe = $dyn.optional(succeed);
          return $d.map(
            _pipe,
            (var0) => { return new ApplicationUpdatedIcon(var0); },
          );
        })(),
      ],
      [
        "disabled",
        (() => {
          let _pipe = $dyn.optional($dyn.bool);
          return $d.map(
            _pipe,
            (var0) => { return new ApplicationUpdatedDisabled(var0); },
          );
        })(),
      ],
      [
        "panel-class",
        (() => {
          let _pipe = $dyn.optional(succeed);
          return $d.map(
            _pipe,
            (var0) => { return new ApplicationUpdatedPanelClass(var0); },
          );
        })(),
      ],
      [
        "panel-position",
        (() => {
          let _pipe = $dyn.optional(succeed);
          return $d.map(
            _pipe,
            (d) => {
              return new ApplicationUpdatedPanelPosition(
                $option.unwrap(d, new $position.Left()),
              );
            },
          );
        })(),
      ],
      [
        "show-arrow",
        (() => {
          let _pipe = $dyn.optional($dyn.bool);
          return $d.map(
            _pipe,
            (_) => { return new ApplicationUpdatedShowArrow(); },
          );
        })(),
      ],
      [
        "input-class",
        (() => {
          let _pipe = $dyn.optional(succeed);
          return $d.map(
            _pipe,
            (var0) => { return new ApplicationUpdatedInputClass(var0); },
          );
        })(),
      ],
    ]),
  );
}

export function choice(title, value) {
  return new Choice(title, new None(), value, new $option.None(), false);
}

export function section(title) {
  return new Section(title, new None());
}

export function no_choice() {
  return new NoChoice();
}

export function panel_right() {
  return new Position(new $position.Right());
}

export function with_class(choice, class$) {
  if (choice instanceof NoChoice) {
    return new NoChoice();
  } else if (choice instanceof Section) {
    let title = choice.title;
    return new Section(title, new Some(class$));
  } else {
    let title = choice.title;
    let icon$1 = choice.icon;
    let value = choice.value;
    let mark_selected = choice.mark_selected;
    let class$1 = new $option.Some(class$);
    return new Choice(title, icon$1, value, class$1, mark_selected);
  }
}

export function with_icon(choice, icon) {
  if (choice instanceof NoChoice) {
    return new NoChoice();
  } else if (choice instanceof Section) {
    return choice;
  } else {
    let title = choice.title;
    let value = choice.value;
    let class$ = choice.class;
    let mark_selected = choice.mark_selected;
    let icon$1 = new $option.Some(icon);
    return new Choice(title, icon$1, value, class$, mark_selected);
  }
}

export function as_selected(choice, selected) {
  if (choice instanceof NoChoice) {
    return new NoChoice();
  } else if (choice instanceof Section) {
    return choice;
  } else {
    let title = choice.title;
    let icon$1 = choice.icon;
    let value = choice.value;
    let class$ = choice.class;
    return new Choice(title, icon$1, value, class$, selected);
  }
}

function init(_) {
  let _pipe = new Model(
    new None(),
    new None(),
    $unsafe.unique_id(),
    new None(),
    new None(),
    false,
    toList([]),
    new None(),
    new $position.Left(),
    "Default placeholder",
    new None(),
    new None(),
    false,
    () => { return undefined; },
  );
  return $pair.new$(_pipe, $eff.none());
}

function toggle_dom_freeze() {
  return $eff.from((_) => { return do_toggle_dom_freeze(); });
}

function on_close(model) {
  let _pipe = $eff.from((_) => { return model.unsubscriber(); });
  let _pipe$1 = ((_capture) => {
    return $list.prepend(toList([toggle_dom_freeze()]), _capture);
  })(_pipe);
  return $eff.batch(_pipe$1);
}

function handle_user_clicked_outside(model) {
  let opened = model.opened;
  let model$1 = model.withFields({ opened: false });
  if (opened) {
    return [model$1, on_close(model$1)];
  } else {
    return [model$1, $eff.none()];
  }
}

function handle_user_toggled_dropdown(model, dom_rect) {
  let $ = model.opened;
  if ($) {
    return [
      model.withFields({ opened: false, rect: new None() }),
      on_close(model),
    ];
  } else {
    let _pipe = (var0) => { return new DocumentReturnedUnsubscriber(var0); };
    let _pipe$1 = $effects.subscribe_dom_click(_pipe, new UserClickedOutside());
    let _pipe$2 = ((_capture) => {
      return $list.prepend(toList([toggle_dom_freeze()]), _capture);
    })(_pipe$1);
    let _pipe$3 = $eff.batch(_pipe$2);
    return ((_capture) => {
      return $pair.new$(
        model.withFields({ opened: true, rect: new Some(dom_rect) }),
        _capture,
      );
    })(_pipe$3);
  }
}

function get_choice_value(choice) {
  if (choice instanceof NoChoice) {
    return new None();
  } else if (choice instanceof Section) {
    return new None();
  } else {
    let value = choice.value;
    return new Some(value);
  }
}

function handle_user_selected_choice(model, value) {
  let _pipe = model.options;
  let _pipe$1 = $list.find(
    _pipe,
    (option) => { return isEqual(get_choice_value(option), new Some(value)); },
  );
  let _pipe$2 = $result.map(
    _pipe$1,
    (choice) => {
      let $ = get_choice_value(choice);
      if (!($ instanceof Some)) {
        throw makeError(
          "let_assert",
          "design_system/components/dropdown",
          354,
          "",
          "Pattern match failed, no pattern matched the value.",
          { value: $ }
        )
      }
      let value$1 = $[0];
      let _pipe$2 = $e.emit("selected", $unsafe.coerce($dyn.from(value$1)));
      return ((_capture) => { return $pair.new$(model, _capture); })(_pipe$2);
    },
  );
  let _pipe$3 = $result.unwrap(
    _pipe$2,
    [model.withFields({ selected: new None() }), $eff.none()],
  );
  let _pipe$4 = $pair.map_first(
    _pipe$3,
    (model) => { return model.withFields({ opened: false }); },
  );
  return ((value) => {
    return [value[0], $eff.batch(toList([value[1], on_close(value[0])]))];
  })(_pipe$4);
}

function update(model, msg) {
  if (msg instanceof ApplicationUpdatedChoices) {
    let content = msg[0];
    let _pipe = model.withFields({ options: content });
    return $pair.new$(_pipe, $eff.none());
  } else if (msg instanceof ApplicationUpdatedDisabled) {
    let content = msg[0];
    let _pipe = model.withFields({ disabled: content });
    return $pair.new$(_pipe, $eff.none());
  } else if (msg instanceof ApplicationUpdatedIcon) {
    let content = msg[0];
    let _pipe = model.withFields({ icon: content });
    return $pair.new$(_pipe, $eff.none());
  } else if (msg instanceof ApplicationUpdatedInputClass) {
    let input_class$1 = msg[0];
    let _pipe = model.withFields({ input_class: input_class$1 });
    return $pair.new$(_pipe, $eff.none());
  } else if (msg instanceof ApplicationUpdatedLabel) {
    let content = msg[0];
    let _pipe = model.withFields({ label: content });
    return $pair.new$(_pipe, $eff.none());
  } else if (msg instanceof ApplicationUpdatedPanelClass) {
    let panel_class$1 = msg[0];
    let _pipe = model.withFields({ panel_class: panel_class$1 });
    return $pair.new$(_pipe, $eff.none());
  } else if (msg instanceof ApplicationUpdatedPlaceholder) {
    let content = msg[0];
    let _pipe = model.withFields({ placeholder: content });
    return $pair.new$(_pipe, $eff.none());
  } else if (msg instanceof ApplicationUpdatedSelected) {
    let content = msg[0];
    let _pipe = model.withFields({ selected: content });
    return $pair.new$(_pipe, $eff.none());
  } else if (msg instanceof ApplicationUpdatedShowArrow) {
    let _pipe = model.withFields({ show_arrow: true });
    return $pair.new$(_pipe, $eff.none());
  } else if (msg instanceof DocumentReturnedUnsubscriber) {
    let unsubscriber = msg[0];
    let _pipe = model.withFields({ unsubscriber: unsubscriber });
    return $pair.new$(_pipe, $eff.none());
  } else if (msg instanceof ApplicationUpdatedPanelPosition) {
    let panel_position = msg[0];
    let _pipe = model.withFields({ panel_position: panel_position });
    return $pair.new$(_pipe, $eff.none());
  } else if (msg instanceof UserClickedOutside) {
    return handle_user_clicked_outside(model);
  } else if (msg instanceof UserSelectedChoice) {
    let value = msg[0];
    return handle_user_selected_choice(model, value);
  } else {
    let dom_rect = msg[0];
    return handle_user_toggled_dropdown(model, dom_rect);
  }
}

function get_choice_icon(choice) {
  if (choice instanceof NoChoice) {
    return new None();
  } else if (choice instanceof Section) {
    return new None();
  } else {
    let icon$1 = choice.icon;
    return icon$1;
  }
}

function get_choice_title(choice) {
  if (choice instanceof NoChoice) {
    return new Error(undefined);
  } else if (choice instanceof Section) {
    return new Error(undefined);
  } else {
    let title = choice.title;
    return new Ok(title);
  }
}

function on_toggle() {
  return $e.on(
    "click",
    (event) => {
      let _pipe = $position.get_event_bounding_client_rect(event);
      let _pipe$1 = $result.map(
        _pipe,
        (var0) => { return new UserToggledDropdown(var0); },
      );
      let _pipe$2 = $result.map_error(_pipe$1, $sentry.capture);
      return $result.replace_error(_pipe$2, toList([]));
    },
  );
}

function select_input_class(model) {
  let $ = model.disabled;
  let $1 = model.opened;
  if ($ instanceof Some && $[0]) {
    return $s.disabled;
  } else if ($1) {
    return $s.active;
  } else {
    return $s.standard;
  }
}

function view_dropdown_icon(model, content) {
  let _pipe = content;
  let _pipe$1 = $option.then$(_pipe, get_choice_icon);
  let _pipe$2 = $option.or(_pipe$1, model.icon);
  let _pipe$3 = $option.map(
    _pipe$2,
    (_capture) => { return $s.full_icon_wrapper(_capture); },
  );
  return $option.unwrap(_pipe$3, $el.none());
}

function view_dropdown_value(model, content) {
  let _pipe = content;
  let _pipe$1 = $option.then$(
    _pipe,
    (choice) => {
      let _pipe$1 = get_choice_title(choice);
      return $option.from_result(_pipe$1);
    },
  );
  let _pipe$2 = $option.unwrap(_pipe$1, model.placeholder);
  return $el.text(_pipe$2);
}

function view_section(option) {
  if (!(option instanceof Section)) {
    throw makeError(
      "let_assert",
      "design_system/components/dropdown",
      490,
      "view_section",
      "Pattern match failed, no pattern matched the value.",
      { value: option }
    )
  }
  let title = option.title;
  let class$ = option.class;
  return $s.section_wrapper(class$, toList([]), toList([$h.text(title)]));
}

function view_icon(icon) {
  let _pipe = $option.map(icon, $s.full_icon_wrapper);
  return $option.lazy_unwrap(_pipe, $el.none);
}

function view_choice(current_value, option) {
  if (!(option instanceof Choice)) {
    throw makeError(
      "let_assert",
      "design_system/components/dropdown",
      495,
      "view_choice",
      "Pattern match failed, no pattern matched the value.",
      { value: option }
    )
  }
  let title = option.title;
  let icon$1 = option.icon;
  let value = option.value;
  let class$ = option.class;
  let mark_selected = option.mark_selected;
  let on_click = $e.on_click(new UserSelectedChoice(value));
  return $s.choice_wrapper(
    class$,
    toList([on_click]),
    toList([
      $s.choice_icon_wrapper(
        toList([]),
        toList([view_icon(icon$1), $h.text(title)]),
      ),
      (() => {
        let $ = (isEqual(current_value, new Some(value))) || mark_selected;
        if ($) {
          return $s.icon_wrapper(toList([]), toList([$icons.checkmark()]));
        } else {
          return $el.none();
        }
      })(),
    ]),
  );
}

function view_dropdown_modal(model, rect, value) {
  return $portal.portal(
    model.id,
    (() => {
      let position = $position.compute_vertical_position(rect);
      let left = $position.compute_horizontal_position(
        rect,
        model.panel_position,
      );
      let width = $sketch.width($size.px_(rect.right - rect.left));
      let style = $sketch.class$(
        toList([
          (() => {
            let _pipe = model.panel_class;
            let _pipe$1 = $option.map(_pipe, $sketch.compose);
            return $option.lazy_unwrap(_pipe$1, $sketch.none);
          })(),
          $sketch.position("fixed"),
          left,
          width,
          position,
        ]),
      );
      return $s.children_wrapper(
        style,
        toList([$utils.stop_propagation()]),
        $list.filter_map(
          model.options,
          (option) => {
            if (option instanceof NoChoice) {
              return new Error(undefined);
            } else if (option instanceof Section) {
              return new Ok(view_section(option));
            } else {
              return new Ok(view_choice(value, option));
            }
          },
        ),
      );
    })(),
  );
}

function optional_attribute(value, attribute) {
  let _pipe = value;
  let _pipe$1 = $option.map(_pipe, attribute);
  return $option.lazy_unwrap(_pipe$1, $a.none);
}

function view_content(model) {
  let content = (() => {
    let _pipe = model.options;
    let _pipe$1 = $list.find(
      _pipe,
      (option) => { return isEqual(get_choice_value(option), model.selected); },
    );
    return $option.from_result(_pipe$1);
  })();
  let input = select_input_class(model);
  let value = $option.then$(content, get_choice_value);
  let disabled$1 = optional_attribute(model.disabled, $a.disabled);
  return $s.full_wrapper(
    toList([]),
    toList([
      input(
        model.input_class,
        toList([disabled$1]),
        toList([
          $s.text_icon_wrapper(
            $option.is_some(model.selected),
            toList([]),
            toList([
              view_dropdown_icon(model, content),
              view_dropdown_value(model, content),
            ]),
          ),
          (() => {
            let $ = model.show_arrow;
            if (!$) {
              return $el.none();
            } else {
              return $s.icon_wrapper(
                toList([]),
                toList([$icons.arrow_dropdown()]),
              );
            }
          })(),
        ]),
      ),
      (() => {
        let $ = model.opened;
        let $1 = model.rect;
        if (!$) {
          return $el.none();
        } else if ($ && $1 instanceof None) {
          return $el.none();
        } else {
          let rect = $1[0];
          return view_dropdown_modal(model, rect, value);
        }
      })(),
    ]),
  );
}

function view(model, stylesheet) {
  return $magic.render(
    toList([stylesheet, $magic.node()]),
    () => {
      let id = $a.id("domrect-" + $int.to_string(model.id));
      let class$ = $a.class$("domrect-parent-wrapper");
      return $s.wrapper(
        toList([id, class$, on_toggle()]),
        (() => {
          let $ = model.label;
          if ($ instanceof Some) {
            let label$1 = $[0];
            return toList([$html.text(label$1), view_content(model)]);
          } else {
            return toList([view_content(model)]);
          }
        })(),
      );
    },
  );
}

const tag_name = "steerlab-dropdown";

export function dropdown(attrs, options) {
  let _pipe = options_list(options);
  let _pipe$1 = ((_capture) => { return $list.prepend(attrs, _capture); })(
    _pipe,
  );
  let _pipe$2 = $list.map(_pipe$1, attributes_to_html_attributes);
  return ((_capture) => { return $el.element(tag_name, _capture, toList([])); })(
    _pipe$2,
  );
}

export function register(stylesheet) {
  let _pipe = (_capture) => { return view(_capture, stylesheet); };
  let _pipe$1 = ((_capture) => {
    return $lustre.component(init, update, _capture, on_attributes_change());
  })(_pipe);
  return $lustre.register(_pipe$1, tag_name);
}
