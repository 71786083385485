/// <reference types="./show.d.mts" />
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $question from "../../../common/data/question.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $float from "../../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $mime_types from "../../../mime_types/mime_types.mjs";
import * as $sk from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $colors from "../../design_system/colors.mjs";
import * as $button from "../../design_system/components/button.mjs";
import * as $dropdown from "../../design_system/components/dropdown.mjs";
import * as $input from "../../design_system/components/input.mjs";
import * as $label from "../../design_system/components/label.mjs";
import * as $layout from "../../design_system/components/layout.mjs";
import * as $pp from "../../design_system/components/profile_picture.mjs";
import * as $skeleton from "../../design_system/components/skeleton.mjs";
import * as $tab_bar from "../../design_system/components/tab_bar.mjs";
import * as $tooltip from "../../design_system/components/tooltip.mjs";
import * as $icons from "../../design_system/icons.mjs";
import * as $outline from "../../design_system/outline.mjs";
import {
  blurTargetWithEscape as blur_target,
  extractInnerText as inner_text_decoder,
} from "../../frontend.ffi.mjs";
import {
  Ok,
  Error,
  toList,
  prepend as listPrepend,
  CustomType as $CustomType,
  divideInt,
  isEqual,
} from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";
import * as $common from "../../view/common.mjs";
import * as $translated_text from "../../view/components/translated_text.mjs";
import * as $dropdown_styles from "../../view/styles/dropdowns.mjs";
import * as $s from "../../view/styles/proposals.mjs";

class ProposalLoading extends $CustomType {}

class QuestionsLoading extends $CustomType {
  constructor(proposal) {
    super();
    this.proposal = proposal;
  }
}

class Loaded extends $CustomType {
  constructor(proposal) {
    super();
    this.proposal = proposal;
  }
}

class NotFound extends $CustomType {}

function find_proposal(model, id) {
  let proposal = $list.key_find(model.proposals, id);
  let $ = model.loading.proposals;
  let $1 = model.loading.questions;
  if ($) {
    return new ProposalLoading();
  } else if (!$ && !proposal.isOk()) {
    return new NotFound();
  } else if (!$ && proposal.isOk() && $1) {
    let proposal$1 = proposal[0];
    return new QuestionsLoading(proposal$1);
  } else {
    let proposal$1 = proposal[0];
    return new Loaded(proposal$1);
  }
}

function language_to_choice(language) {
  return $dropdown.choice(
    $translate.display_language(language),
    $translate.decode_language_iso639_1(language),
  );
}

function select_language(language) {
  let _pipe = $translate.map_string_to_iso639(language);
  return new $msg.UserSelectedLanguage(_pipe);
}

function view_translation(model, languages) {
  let $ = $list.length(languages) > 0;
  if (!$) {
    return $el.none();
  } else {
    return $dropdown.dropdown(
      toList([
        $dropdown.panel_right(),
        $dropdown.placeholder(
          (() => {
            let _pipe = model.language;
            return $translate.display_language(_pipe);
          })(),
        ),
        $dropdown.icon($icons.translate()),
        $dropdown.on_selected(select_language),
      ]),
      (() => {
        let _pipe = languages;
        return $list.map(_pipe, language_to_choice);
      })(),
    );
  }
}

function view_proposal_back() {
  return $layout.row(
    toList([]),
    toList([]),
    toList([
      $button.link(
        toList([$e.on_click(new $msg.Back())]),
        toList([$button.icon($icons.left_arrow()), $h.text("See all proposals")]),
      ),
    ]),
  );
}

function view_export_button(id) {
  let on_export = (() => {
    let _pipe = id;
    let _pipe$1 = $option.map(
      _pipe,
      (var0) => { return new $msg.GenerateXlsx(var0); },
    );
    let _pipe$2 = $option.map(
      _pipe$1,
      (var0) => { return new $msg.Xlsx(var0); },
    );
    let _pipe$3 = $option.map(_pipe$2, $e.on_click);
    return $option.unwrap(_pipe$3, $a.none());
  })();
  return $button.primary(
    toList([$a.disabled($option.is_none(id)), on_export]),
    toList([$button.icon($icons.export$()), $h.text("Export document")]),
  );
}

function view_proposal_title(proposal) {
  let id = proposal.id;
  let name = proposal.name;
  return $s.show_title_form(
    toList([$e.on_submit(new $msg.SaveProposalTitle(id))]),
    toList([
      $input.input(
        toList([
          $input.value(name),
          $input.id("input-proposal-name"),
          $input.invisible(),
          $input.on_input(
            (_capture) => {
              return new $msg.UserUpdatedProposalTitle(id, _capture);
            },
          ),
        ]),
      ),
    ]),
  );
}

function view_tab_bar(enabled) {
  return $layout.row(
    toList([]),
    toList([]),
    toList([
      $tab_bar.tab_bar(
        toList([]),
        toList([
          $tab_bar.tab(
            enabled,
            toList([]),
            toList([
              $layout.row(
                toList([$layout.align("center"), $layout.gap(8)]),
                toList([]),
                toList([
                  $s.details_collaborators_icon($icons.menu()),
                  $h.text("Blocks"),
                ]),
              ),
            ]),
          ),
          $tab_bar.tab(
            false,
            toList([]),
            toList([
              $layout.row(
                toList([$layout.align("center"), $layout.gap(8)]),
                toList([]),
                toList([
                  $s.details_collaborators_icon($icons.table_line()),
                  $h.text("Spreadsheet (coming soon)"),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function proposal_collaborators_choice(model, proposal) {
  let collaborators = listPrepend(proposal.owner, proposal.collaborators);
  return $list.filter_map(
    model.users,
    (user) => {
      let is_contributor = $list.any(
        user.roles,
        (u) => { return u.name === "Contributor"; },
      );
      let is_present = $list.contains(collaborators, user.id);
      let is_owner = proposal.owner === user.id;
      let is_yourself = isEqual(
        $option.map(model.user, (u) => { return u.sub; }),
        new Some(user.id)
      );
      let $ = (is_contributor && !is_owner) && !is_yourself;
      if (!$) {
        return new Error(undefined);
      } else {
        let _pipe = $dropdown.choice(user.name, user.id);
        let _pipe$1 = $dropdown.with_icon(
          _pipe,
          $pp.from_user(user.picture, user.initials, new $pp.Small()),
        );
        let _pipe$2 = $dropdown.as_selected(_pipe$1, is_present);
        return new Ok(_pipe$2);
      }
    },
  );
}

function view_proposal_collaborators(model, proposal) {
  let is_owner = isEqual(
    new Some(proposal.owner),
    $option.map(model.user, (u) => { return u.sub; })
  );
  return $bool.lazy_guard(
    !is_owner,
    $el.none,
    () => {
      let collaborators = listPrepend(proposal.owner, proposal.collaborators);
      let choices = proposal_collaborators_choice(model, proposal);
      return $layout.row(
        toList([$layout.gap(10), $layout.align("center")]),
        toList([]),
        toList([
          $common.stacked_avatars(collaborators, model.users),
          $dropdown.dropdown(
            toList([
              $dropdown.panel_right(),
              $dropdown.placeholder("Add / Remove collaborator"),
              $dropdown.icon($icons.add_user()),
              $dropdown.disabled($list.is_empty(choices) || !is_owner),
              $dropdown.on_selected(
                (_capture) => {
                  return new $msg.UserToggledProposalCollaborator(
                    proposal.id,
                    _capture,
                  );
                },
              ),
            ]),
            choices,
          ),
        ]),
      );
    },
  );
}

function get_data_point_by_id(id, data_points) {
  return $list.find(data_points, (dp) => { return dp.id === id; });
}

function page_selector(page, number_page) {
  let on_page_click = (page) => {
    return $e.on_click(new $msg.UserSelectedPage(page));
  };
  return $s.page_selector(
    toList([]),
    toList([
      $s.page_selector_prev(
        toList([on_page_click(page - 1), $a.disabled(page === 0)]),
        toList([$icons.small($icons.arrow_left_line())]),
      ),
      $el.fragment(
        $list.map(
          $list.range(0, number_page),
          (n) => {
            return $s.page_selector_page(
              n === page,
              toList([on_page_click(n)]),
              toList([$h.text($int.to_string(n + 1))]),
            );
          },
        ),
      ),
      $s.page_selector_next(
        toList([on_page_click(page + 1), $a.disabled(page === number_page)]),
        toList([$icons.small($icons.arrow_right_line())]),
      ),
    ]),
  );
}

function view_no_questions_placeholder() {
  return $layout.column(
    toList([
      $layout.flex("1"),
      $layout.align("center"),
      $layout.justify("center"),
      $layout.class$(
        $sk.class$(
          toList([
            $sk.border("1px solid " + $colors.light.grey_400),
            $sk.border_radius_("8px 8px 8px 0px"),
          ]),
        ),
      ),
    ]),
    toList([]),
    toList([
      $s.empty_sheet(
        toList([]),
        toList([$el.text("This sheet does not contain any questions.")]),
      ),
      $s.empty_sheet(
        toList([]),
        toList([$el.text("However it will be kept as-is during the export.")]),
      ),
    ]),
  );
}

function view_no_questions_found() {
  return $layout.column(
    toList([
      $layout.flex("1"),
      $layout.align("center"),
      $layout.justify("center"),
      $layout.class$(
        $sk.class$(
          toList([
            $sk.border("1px solid " + $colors.light.grey_400),
            $sk.border_radius_("8px 8px 8px 0px"),
          ]),
        ),
      ),
    ]),
    toList([]),
    toList([
      $s.empty_sheet(
        toList([]),
        toList([$el.text("No proposal has been found.")]),
      ),
      $s.empty_sheet(toList([]), toList([$el.text("Come back to home.")])),
    ]),
  );
}

function view_not_found() {
  return $el.fragment(
    toList([
      $layout.column(
        toList([$layout.gap(8)]),
        toList([]),
        toList([
          view_proposal_back(),
          $layout.row(
            toList([$layout.gap(8), $layout.justify("space-between")]),
            toList([]),
            toList([
              $h.div(
                toList([$a.style(toList([["height", "25px"]]))]),
                toList([]),
              ),
              view_export_button(new None()),
            ]),
          ),
        ]),
      ),
      $layout.row(
        toList([$layout.justify("space-between")]),
        toList([]),
        toList([view_tab_bar(false)]),
      ),
      $s.questions_wrapper(toList([]), toList([view_no_questions_found()])),
    ]),
  );
}

function yes_no_button(question, content, title) {
  let node = (() => {
    let $ = isEqual(question.answer.yes_no, new $option.Some(content));
    if ($) {
      return $button.primary;
    } else {
      return $button.secondary;
    }
  })();
  let disabled = $a.disabled(question.ai_processing);
  let on_click = $e.on_click(
    new $msg.UserUpdatedProposalQuestionYesNo(question, content),
  );
  return node(toList([disabled, on_click]), toList([$h.text(title)]));
}

function multiple_choice_button(question, content, title) {
  let node = (() => {
    let $ = isEqual(question.answer.choice, new $option.Some(content));
    if ($) {
      return $button.primary;
    } else {
      return $button.secondary;
    }
  })();
  let disabled = $a.disabled(question.ai_processing);
  let on_click = $e.on_click(
    new $msg.UserUpdatedProposalQuestionChoice(question, content),
  );
  return node(toList([disabled, on_click]), toList([$h.text(title)]));
}

function view_loading_question() {
  return $s.question_card(
    toList([]),
    toList([
      $s.question_card_title(
        toList([]),
        toList([
          $s.question_number(toList([]), toList([$skeleton.skeleton(80, 20)])),
          $s.question_status(toList([]), toList([$skeleton.skeleton(80, 30)])),
          $s.question_question(
            toList([]),
            toList([$skeleton.skeleton(200, 25)]),
          ),
          $s.question_owner(toList([]), toList([$skeleton.skeleton(150, 40)])),
        ]),
      ),
      $layout.column(
        toList([$layout.gap(10)]),
        toList([$a.style(toList([["grid-column", "1"], ["grid-row", "2"]]))]),
        toList([$skeleton.full_width(60)]),
      ),
      $s.question_actions(
        toList([]),
        toList([$skeleton.skeleton(40, 40), $skeleton.skeleton(40, 40)]),
      ),
      $s.question_sources(
        toList([]),
        toList([$skeleton.skeleton(80, 20), $skeleton.skeleton(150, 20)]),
      ),
    ]),
  );
}

function view_loading_questions() {
  return $s.questions_wrapper(
    toList([]),
    toList([
      $s.question_questions(
        toList([]),
        (() => {
          let _pipe = $list.range(0, 5);
          return $list.map(_pipe, (_) => { return view_loading_question(); });
        })(),
      ),
      $s.question_spreadsheet_sheets(
        toList([]),
        toList([$skeleton.skeleton(200, 25)]),
      ),
    ]),
  );
}

function view_proposal_loading() {
  return $el.fragment(
    toList([
      $layout.column(
        toList([$layout.gap(8)]),
        toList([]),
        toList([
          view_proposal_back(),
          $layout.row(
            toList([$layout.gap(8), $layout.justify("space-between")]),
            toList([]),
            toList([$skeleton.skeleton(200, 25), view_export_button(new None())]),
          ),
        ]),
      ),
      $layout.row(
        toList([$layout.justify("space-between")]),
        toList([]),
        toList([view_tab_bar(false)]),
      ),
      view_loading_questions(),
    ]),
  );
}

function view_proposal_questions_loading(_, proposal) {
  return $el.fragment(
    toList([
      $layout.column(
        toList([$layout.gap(8)]),
        toList([]),
        toList([
          view_proposal_back(),
          $layout.row(
            toList([$layout.gap(8), $layout.justify("space-between")]),
            toList([]),
            toList([
              view_proposal_title(proposal),
              view_export_button(new None()),
            ]),
          ),
        ]),
      ),
      $layout.row(
        toList([$layout.justify("space-between")]),
        toList([]),
        toList([view_tab_bar(false), $skeleton.skeleton(24, 24)]),
      ),
      view_loading_questions(),
    ]),
  );
}

function ai_rewording_popup(
  opened,
  proposal_id,
  question_id,
  custom_rewording_input
) {
  return $bool.guard(
    !opened,
    $el.none(),
    () => {
      let on_click_ai_rewrite = (asked) => {
        return $e.on_click(
          new $msg.UserClickedAiRewrite(proposal_id, question_id, asked),
        );
      };
      let on_click_add_question_bank = $e.on_click(
        new $msg.UserClickedAddToQuestionBank(proposal_id, question_id),
      );
      let on_click_remove_question = $e.on_click(
        new $msg.UserDisplayedDeleteQuestionInProposalModal(
          proposal_id,
          question_id,
        ),
      );
      return $s.ai_rewording_popup(
        toList([$utils.stop_propagation()]),
        toList([
          $s.ai_rewording_title(toList([]), toList([$h.text("Actions")])),
          $s.ai_rewording_option(
            toList([on_click_add_question_bank]),
            toList([
              $s.details_collaborators_icon($icons.add()),
              $h.text("Add to question bank"),
            ]),
          ),
          $s.ai_rewording_option(
            toList([on_click_remove_question]),
            toList([
              $s.details_collaborators_icon($outline.trash()),
              $h.text("Remove question"),
            ]),
          ),
          $s.ai_rewording_title(toList([]), toList([$h.text("AI Edits")])),
          $s.ai_rewording_option(
            toList([on_click_ai_rewrite("shorter")]),
            toList([
              $s.details_collaborators_icon($icons.shorter()),
              $h.text("Make shorter"),
            ]),
          ),
          $s.ai_rewording_option(
            toList([on_click_ai_rewrite("longer")]),
            toList([
              $s.details_collaborators_icon($icons.longer()),
              $h.text("Make longer"),
            ]),
          ),
          $s.ai_rewording_option(
            toList([on_click_ai_rewrite("proofread")]),
            toList([
              $s.details_collaborators_icon($icons.file_edit()),
              $h.text("Proofread"),
            ]),
          ),
          $s.ai_text_input(
            toList([]),
            toList([
              $h.form(
                toList([
                  $e.on_submit(
                    new $msg.UserSubmittedCustomRewording(
                      proposal_id,
                      question_id,
                    ),
                  ),
                ]),
                toList([
                  $input.input(
                    toList([
                      $input.placeholder("Custom rewording"),
                      $input.on_input(
                        (var0) => {
                          return new $msg.UserEdittedCustomRewording(var0);
                        },
                      ),
                      $input.value(custom_rewording_input),
                    ]),
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      );
    },
  );
}

function proposal_filters(model, proposal_id) {
  return $bool.guard(
    !model.filter_proposal_opened,
    $el.none(),
    () => {
      let proposal_collaborators = (() => {
        let _pipe = model.proposals;
        let _pipe$1 = $list.key_find(_pipe, proposal_id);
        let _pipe$2 = $result.map(
          _pipe$1,
          (proposal) => {
            return listPrepend(proposal.owner, proposal.collaborators);
          },
        );
        return $result.unwrap(_pipe$2, toList([]));
      })();
      let white_choice = $sk.class$(
        toList([
          $sk.border("1px solid " + $colors.light.grey_200),
          $sk.border_radius($size.px(10)),
          $sk.hover(toList([$sk.border("1px solid " + $colors.light.grey_400)])),
        ]),
      );
      let other_choices = $sk.class$(
        toList([$sk.padding_("10px 12px"), $sk.border_radius($size.px(10))]),
      );
      return $layout.row(
        toList([$layout.gap(8)]),
        toList([]),
        toList([
          $dropdown.dropdown(
            toList([
              $dropdown.placeholder("No status selected"),
              $dropdown.selected(
                new $option.Some(model.proposal_filters.status),
              ),
              $dropdown.on_selected(
                (var0) => {
                  return new $msg.UserUpdatedProposalFiltersStatus(var0);
                },
              ),
              $dropdown.panel_class($dropdown_styles.spacing()),
              $dropdown.show_arrow(),
              $dropdown.input_class(
                $sk.class$(
                  toList([
                    $sk.compose(
                      $sk.class$(
                        toList([
                          (() => {
                            let $ = model.proposal_filters.status;
                            if ($ instanceof $proposal.InReview) {
                              return $sk.compose($dropdown_styles.current());
                            } else if ($ instanceof $proposal.Validated) {
                              return $sk.compose($dropdown_styles.success());
                            } else {
                              return $sk.none();
                            }
                          })(),
                          $sk.compose(other_choices),
                          $sk.min_width($size.px(125)),
                        ]),
                      ),
                    ),
                  ]),
                ),
              ),
              $dropdown.disabled($list.is_empty(proposal_collaborators)),
            ]),
            toList([
              (() => {
                let _pipe = $dropdown.choice(
                  "All status",
                  new $proposal.AllStatus(),
                );
                return $dropdown.with_class(_pipe, white_choice);
              })(),
              (() => {
                let _pipe = $dropdown.choice(
                  "In review",
                  new $proposal.InReview(),
                );
                return $dropdown.with_class(
                  _pipe,
                  $sk.class$(
                    toList([
                      $sk.compose($dropdown_styles.current()),
                      $sk.compose(other_choices),
                    ]),
                  ),
                );
              })(),
              (() => {
                let _pipe = $dropdown.choice(
                  "Validated",
                  new $proposal.Validated(),
                );
                return $dropdown.with_class(
                  _pipe,
                  $sk.class$(
                    toList([
                      $sk.compose($dropdown_styles.success()),
                      $sk.compose(other_choices),
                    ]),
                  ),
                );
              })(),
            ]),
          ),
          $dropdown.dropdown(
            toList([
              $dropdown.placeholder("No collaborator selected"),
              $dropdown.selected(new Some(model.proposal_filters.owner)),
              $dropdown.on_selected(
                (_capture) => {
                  return new $msg.UserUpdatedProposalFiltersOwner(_capture);
                },
              ),
              $dropdown.panel_class($dropdown_styles.spacing()),
              $dropdown.show_arrow(),
              $dropdown.input_class(
                $sk.class$(toList([$sk.min_width($size.px(200))])),
              ),
            ]),
            listPrepend(
              (() => {
                let _pipe = $dropdown.choice(
                  "All collaborators",
                  new $option.None(),
                );
                return $dropdown.with_class(_pipe, white_choice);
              })(),
              $list.filter_map(
                model.users,
                (user) => {
                  let user_in_proposal = $list.contains(
                    proposal_collaborators,
                    user.id,
                  );
                  return $bool.guard(
                    !user_in_proposal,
                    new Error(undefined),
                    () => {
                      let id = user.id;
                      let name = user.name;
                      let picture = user.picture;
                      let initials = user.initials;
                      let icon = $pp.from_user(
                        picture,
                        initials,
                        new $pp.Medium(),
                      );
                      let _pipe = $dropdown.choice(name, new $option.Some(id));
                      let _pipe$1 = $dropdown.with_icon(_pipe, icon);
                      let _pipe$2 = $dropdown.with_class(_pipe$1, white_choice);
                      return new Ok(_pipe$2);
                    },
                  );
                },
              ),
            ),
          ),
        ]),
      );
    },
  );
}

function can_contribute(role) {
  return (role.name === "Contributor") || (role.name === "Proposal Manager");
}

function collaborator_button(model, proposal, question) {
  let disabled = !$list.contains(model.permissions, "rfp:users:add") || question.assign_processing;
  let skeleton = () => { return $skeleton.skeleton(150, 35); };
  return $bool.lazy_guard(
    question.assign_processing,
    skeleton,
    () => {
      return $el.fragment(
        toList([
          $dropdown.dropdown(
            toList([
              $dropdown.placeholder("Add collaborator"),
              $dropdown.input_class($s.details_collaborators_class()),
              $dropdown.panel_right(),
              $dropdown.disabled(disabled),
              $dropdown.selected(question.owner),
              $dropdown.on_selected(
                (id) => {
                  return new $msg.AddQuestionOwner(
                    question.id,
                    question.proposal_id,
                    id,
                  );
                },
              ),
            ]),
            listPrepend(
              $dropdown.section("Select collaborator"),
              $list.filter_map(
                model.users,
                (user) => {
                  let is_contributable = $list.any(user.roles, can_contribute);
                  let is_proposal_member = $list.contains(
                    proposal.collaborators,
                    user.id,
                  ) || (proposal.owner === user.id);
                  let is_selectable = is_contributable && is_proposal_member;
                  return $bool.guard(
                    !is_selectable,
                    new Error(undefined),
                    () => {
                      let id = user.id;
                      let name = user.name;
                      let picture = user.picture;
                      let initials = user.initials;
                      let icon = $pp.from_user(
                        picture,
                        initials,
                        new $pp.Medium(),
                      );
                      return new Ok(
                        (() => {
                          let _pipe = $dropdown.choice(name, id);
                          return $dropdown.with_icon(_pipe, icon);
                        })(),
                      );
                    },
                  );
                },
              ),
            ),
          ),
        ]),
      );
    },
  );
}

function view_question_status(question) {
  let status = (() => {
    let $ = question.validated;
    if ($) {
      return new $label.Success();
    } else {
      return new $label.Current();
    }
  })();
  let content = (() => {
    let $ = question.validated;
    if ($) {
      return "Validated";
    } else {
      return "In review";
    }
  })();
  return $s.question_status(toList([]), toList([$label.label(status, content)]));
}

function view_question_answer(model, question, answer) {
  let user_id = $model.find_current_user_id(model);
  let is_hint = (($option.is_none(answer.custom) && $option.is_none(
    answer.short,
  )) && $option.is_none(answer.long)) && $option.is_none(answer.yes_no);
  let is_owner = isEqual($option.to_result(question.owner, undefined), user_id);
  let editable = $a.attribute(
    "contenteditable",
    (() => {
      let $ = is_hint || !is_owner;
      if ($) {
        return "false";
      } else {
        return "true";
      }
    })(),
  );
  let on_blur = $e.on(
    "blur",
    (event) => {
      return $result.map(
        inner_text_decoder(event),
        (content) => {
          return new $msg.UserUpdatedProposalQuestion(question, content);
        },
      );
    },
  );
  let on_escape = $e.on(
    "keyup",
    (event) => {
      blur_target(event);
      return new Error(toList([]));
    },
  );
  return $s.question_response(
    is_hint,
    toList([editable, on_blur, on_escape]),
    toList([
      $translated_text.translated_answer(question, model, model.language),
      (() => {
        let $ = question.ai_processing;
        if (!$) {
          return $el.none();
        } else {
          return $s.loading_answer_indicator(toList([$s.typing_indicator(12)]));
        }
      })(),
    ]),
  );
}

function view_question_card(
  model,
  current_user,
  proposal,
  question,
  index,
  total,
  data_sources,
  data_points
) {
  let style = $a.style(toList([["z-index", $int.to_string(9000 - index)]]));
  let index$1 = $int.to_string(index + 1);
  let total$1 = $int.to_string(total);
  let points = data_points;
  let actions_disabled = $a.disabled(
    (question.validated || (!isEqual(
      current_user,
      $option.to_result(question.owner, undefined)
    ))) || question.ai_processing,
  );
  let more_proposal_opened = isEqual(
    model.more_proposal_opened,
    new $option.Some(question.id)
  );
  return $s.question_card(
    toList([$a.id("question-" + question.id), style]),
    toList([
      $s.question_card_title(
        toList([]),
        toList([
          $s.question_number(
            toList([]),
            toList([$h.text((("Question " + index$1) + "/") + total$1)]),
          ),
          view_question_status(question),
          $s.question_question(
            toList([]),
            toList([
              $translated_text.translated_question(
                question,
                model,
                model.language,
              ),
            ]),
          ),
          $s.question_owner(
            toList([]),
            toList([collaborator_button(model, proposal, question)]),
          ),
        ]),
      ),
      $layout.column(
        toList([$layout.gap(10)]),
        toList([$a.style(toList([["grid-column", "1"], ["grid-row", "2"]]))]),
        toList([
          (() => {
            let $ = $list.contains(question.kind, new $question.Long());
            if (!$) {
              return $el.none();
            } else {
              return view_question_answer(model, question, question.answer);
            }
          })(),
          (() => {
            let $ = $list.contains(question.kind, new $question.YesNo());
            if (!$) {
              return $el.none();
            } else {
              return $layout.column(
                toList([$layout.gap(10), $layout.align("start")]),
                toList([]),
                toList([
                  $layout.row(
                    toList([$layout.gap(10)]),
                    toList([]),
                    toList([
                      yes_no_button(question, "YES", "Yes"),
                      yes_no_button(question, "NO", "No"),
                      yes_no_button(question, "NA", "N/A"),
                    ]),
                  ),
                ]),
              );
            }
          })(),
          (() => {
            let $ = $list.contains(
              question.kind,
              new $question.MultipleChoice(),
            );
            if (!$) {
              return $el.none();
            } else {
              return $layout.column(
                toList([$layout.gap(10), $layout.align("start")]),
                toList([]),
                toList([
                  $layout.row(
                    toList([$layout.gap(10)]),
                    toList([]),
                    $list.map(
                      question.choices,
                      (c) => { return multiple_choice_button(question, c, c); },
                    ),
                  ),
                ]),
              );
            }
          })(),
        ]),
      ),
      $s.question_actions(
        toList([]),
        toList([
          (() => {
            let handler = $e.on_click(new $msg.UserValidatedQuestion(question));
            return $s.button_icon(
              false,
              toList([handler, actions_disabled]),
              toList([$s.large_icon($icons.checkmark())]),
            );
          })(),
          $s.button_icon(
            more_proposal_opened,
            toList([
              $e.on_click(new $msg.UserToggledQuestionMoreButton(question.id)),
              actions_disabled,
            ]),
            toList([$s.large_icon($icons.more())]),
          ),
          ai_rewording_popup(
            more_proposal_opened,
            proposal.id,
            question.id,
            model.custom_rewording_input,
          ),
        ]),
      ),
      $s.question_sources(
        toList([]),
        toList([
          $layout.row(
            toList([$layout.align("center"), $layout.gap(8)]),
            toList([]),
            toList([
              $s.question_sources_title(
                toList([]),
                toList([$h.text("Sources")]),
              ),
              (() => {
                let $ = $list.is_empty(points);
                if ($) {
                  return $h.text("∅");
                } else {
                  return $el.fragment(
                    $list.index_map(
                      points,
                      (point, index) => {
                        let _pipe = $list.find(
                          data_sources,
                          (s) => { return s.id === point.source_id; },
                        );
                        let _pipe$1 = $result.try_recover(
                          _pipe,
                          (_) => { return new Error($el.none()); },
                        );
                        let _pipe$2 = $result.map(
                          _pipe$1,
                          (source) => {
                            let index$1 = $h.text(
                              ("[" + $int.to_string(index + 1)) + "]",
                            );
                            return $tooltip.tooltip(
                              toList([]),
                              $s.question_source_index(
                                toList([]),
                                toList([index$1]),
                              ),
                              (() => {
                                let icon = $common.select_icon(
                                  source.display.mime_type,
                                );
                                return $s.question_source_tooltip(
                                  toList([]),
                                  toList([
                                    $layout.row(
                                      toList([
                                        $layout.gap(12),
                                        $layout.align("center"),
                                      ]),
                                      toList([]),
                                      toList([
                                        $common.small_icon(icon),
                                        $s.question_source_tooltip_title(
                                          toList([]),
                                          toList([
                                            (() => {
                                              let $1 = source.display.mime_type;
                                              if ($1 instanceof $mime_types.Qna) {
                                                return $h.text("Question bank");
                                              } else {
                                                return $h.text(
                                                  source.display.name,
                                                );
                                              }
                                            })(),
                                          ]),
                                        ),
                                      ]),
                                    ),
                                    $translated_text.translated_data_point(
                                      point,
                                      model,
                                      model.language,
                                    ),
                                  ]),
                                );
                              })(),
                            );
                          },
                        );
                        return $result.unwrap_both(_pipe$2);
                      },
                    ),
                  );
                }
              })(),
            ]),
          ),
          (() => {
            let $ = question.display.score;
            if ($ instanceof $option.None) {
              return $el.none();
            } else {
              let score = $[0];
              return $s.confidence_score(
                toList([]),
                toList([
                  $h.text("Confidence Score"),
                  $s.confidence_score_highlight(
                    score,
                    toList([]),
                    (() => {
                      let value = $int.to_string($float.round(score * 100.0));
                      let value$1 = $string.slice(value, 0, 5);
                      return toList([$h.text(value$1 + " %")]);
                    })(),
                  ),
                ]),
              );
            }
          })(),
        ]),
      ),
    ]),
  );
}

function view_questions_list(
  model,
  proposal,
  questions,
  current_user,
  active_sheet,
  page
) {
  let non_displayed_questions = (() => {
    let _pipe = model.questions.by_proposal;
    let _pipe$1 = $dict.get(_pipe, proposal.id);
    return $result.unwrap(_pipe$1, toList([]));
  })();
  let nb_questions_per_sheet = (() => {
    let _pipe = $list.group(
      non_displayed_questions,
      (q) => { return q[1].sheet; },
    );
    return $dict.map_values(
      _pipe,
      (_, value) => { return $list.length(value); },
    );
  })();
  let page_size = model.proposal_block_page_size;
  let total = (() => {
    let _pipe = $dict.get(nb_questions_per_sheet, active_sheet);
    return $result.lazy_unwrap(_pipe, () => { return $list.length(questions); });
  })();
  let questions$1 = $list.index_map(
    questions,
    (q, index) => { return [q, index]; },
  );
  let start = page * page_size;
  let splitted_questions = (() => {
    let _pipe = questions$1;
    return $utils.slice(_pipe, start, start + page_size);
  })();
  return $el.fragment(
    toList([
      $s.question_questions(
        toList([]),
        toList([
          $s.question_list(
            toList([]),
            toList([
              $el.fragment(
                $list.map(
                  splitted_questions,
                  (_use0) => {
                    let question = _use0[0];
                    let data_sources = (() => {
                      let _pipe = model.questions.data_sources;
                      let _pipe$1 = $dict.get(_pipe, question[1].id);
                      return $result.unwrap(_pipe$1, toList([]));
                    })();
                    let tmp_data_points = (() => {
                      let _pipe = model.questions.data_points;
                      let _pipe$1 = $dict.get(_pipe, question[1].id);
                      return $result.unwrap(_pipe$1, toList([]));
                    })();
                    let list_of_elements = question[1].data_points_id;
                    let data_points = $list.filter_map(
                      list_of_elements,
                      (element) => {
                        return get_data_point_by_id(element[0], tmp_data_points);
                      },
                    );
                    return view_question_card(
                      model,
                      current_user,
                      proposal,
                      question[1],
                      question[1].qa_order,
                      total,
                      data_sources,
                      data_points,
                    );
                  },
                ),
              ),
            ]),
          ),
        ]),
      ),
      page_selector(page, $int.max(divideInt((total - 1), page_size), 0)),
    ]),
  );
}

function view_questions(model, proposal, sheet, page) {
  let current_user = $model.find_current_user_id(model);
  let questions = model.displayed_questions;
  let by_sheet = (() => {
    let _pipe = $list.group(questions, (q) => { return q[1].sheet; });
    return $dict.map_values(
      _pipe,
      (_, value) => { return $list.reverse(value); },
    );
  })();
  let first_non_empty_sheet = (() => {
    let _pipe = proposal.metadata.worksheets;
    return $list.find_map(
      _pipe,
      (s) => {
        let is_empty = (() => {
          let _pipe$1 = $dict.get(by_sheet, new $option.Some(s.name));
          let _pipe$2 = $result.unwrap(_pipe$1, toList([]));
          return $list.is_empty(_pipe$2);
        })();
        if (is_empty) {
          return new Error("");
        } else {
          return new Ok(s.name);
        }
      },
    );
  })();
  let active_sheet = (() => {
    let _pipe = $list.find(
      proposal.metadata.sheets,
      (s) => { return isEqual(new $option.Some(s), sheet); },
    );
    let _pipe$1 = $result.try_recover(
      _pipe,
      (_) => { return first_non_empty_sheet; },
    );
    return $option.from_result(_pipe$1);
  })();
  return $s.questions_wrapper(
    toList([]),
    toList([
      (() => {
        let $ = $dict.get(by_sheet, active_sheet);
        if (!$.isOk()) {
          return view_no_questions_placeholder();
        } else {
          let questions$1 = $[0];
          return view_questions_list(
            model,
            proposal,
            questions$1,
            current_user,
            active_sheet,
            page,
          );
        }
      })(),
      $s.question_spreadsheet_sheets(
        toList([]),
        $list.map(
          proposal.metadata.sheets,
          (sheet) => {
            let s = new $option.Some(sheet);
            let on_click = $e.on_click(
              new $msg.UserClickedProposal(proposal.id, s),
            );
            let $ = isEqual(s, active_sheet);
            if ($) {
              return $s.active_spreadsheet_sheet(
                toList([on_click]),
                toList([$el.text(sheet)]),
              );
            } else {
              return $s.spreadsheet_sheet(
                toList([on_click]),
                toList([$el.text(sheet)]),
              );
            }
          },
        ),
      ),
    ]),
  );
}

function view_proposal(model, proposal, sheet, page) {
  return $el.fragment(
    toList([
      $layout.column(
        toList([$layout.gap(8)]),
        toList([]),
        toList([
          view_proposal_back(),
          $layout.row(
            toList([$layout.gap(8), $layout.justify("space-between")]),
            toList([]),
            toList([
              view_proposal_title(proposal),
              view_export_button(new Some(proposal.id)),
            ]),
          ),
        ]),
      ),
      $layout.row(
        toList([$layout.justify("space-between")]),
        toList([]),
        toList([
          view_tab_bar(true),
          view_proposal_collaborators(model, proposal),
        ]),
      ),
      $layout.row(
        toList([
          $layout.gap(8),
          $layout.align("center"),
          $layout.justify("space-between"),
        ]),
        toList([]),
        toList([
          $layout.row(
            toList([
              $layout.gap(32),
              $layout.align("center"),
              $layout.justify("space-between"),
            ]),
            toList([]),
            toList([
              $s.filters_button(
                model.filter_proposal_opened,
                toList([$e.on_click(new $msg.UserToggledFilterProposal())]),
                toList([$button.icon($icons.filters()), $h.text("Filters")]),
              ),
              proposal_filters(model, proposal.id),
            ]),
          ),
          view_translation(model, proposal.metadata.languages),
        ]),
      ),
      view_questions(model, proposal, sheet, page),
    ]),
  );
}

export function view(model, id, sheet, page) {
  let $ = find_proposal(model, id);
  if ($ instanceof ProposalLoading) {
    return view_proposal_loading();
  } else if ($ instanceof QuestionsLoading) {
    let proposal = $.proposal;
    return view_proposal_questions_loading(model, proposal);
  } else if ($ instanceof Loaded) {
    let proposal = $.proposal;
    return view_proposal(model, proposal, sheet, page);
  } else {
    return view_not_found();
  }
}
