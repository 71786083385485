/// <reference types="./filters.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function filters() {
  let content = "<g clip-path=\"url(#clip0_7164_5580)\">\n    <path d=\"M8.33333 15H11.6667V13.3333H8.33333V15ZM2.5 5V6.66667H17.5V5H2.5ZM5 10.8333H15V9.16667H5V10.8333Z\" fill=\"currentColor\"/>\n    </g>\n    <defs>\n    <clipPath id=\"clip0_7164_5580\">\n    <rect width=\"20\" height=\"20\" fill=\"none\"/>\n    </clipPath>\n    </defs>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 20"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
