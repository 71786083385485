/// <reference types="./table.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $e from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $event from "../../../lustre/lustre/event.mjs";
import * as $sketch from "../../../sketch/sketch.mjs";
import * as $button from "../../design_system/components/button.mjs";
import * as $dropdown from "../../design_system/components/dropdown.mjs";
import * as $icons from "../../design_system/icons.mjs";
import * as $s from "../../design_system/internals/styles/table.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";

class Fit extends $CustomType {}

class MinContent extends $CustomType {}

class Fraction extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Clamp extends $CustomType {
  constructor(x0, x1) {
    super();
    this[0] = x0;
    this[1] = x1;
  }
}

class Header extends $CustomType {
  constructor(title, size) {
    super();
    this.title = title;
    this.size = size;
  }
}

class Cell extends $CustomType {
  constructor(content, style, stop_propagation) {
    super();
    this.content = content;
    this.style = style;
    this.stop_propagation = stop_propagation;
  }
}

class Row extends $CustomType {
  constructor(cells, hover, on_click) {
    super();
    this.cells = cells;
    this.hover = hover;
    this.on_click = on_click;
  }
}

class Headers extends $CustomType {
  constructor(headers) {
    super();
    this.headers = headers;
  }
}

class Rows extends $CustomType {
  constructor(rows) {
    super();
    this.rows = rows;
  }
}

class Footer extends $CustomType {
  constructor(disabled) {
    super();
    this.disabled = disabled;
  }
}

function size_to_string(size) {
  if (size instanceof Fit) {
    return "auto";
  } else if (size instanceof MinContent) {
    return "min-content";
  } else if (size instanceof Fraction) {
    let value = size[0];
    return $int.to_string(value) + "fr";
  } else {
    let preferred = size[0];
    let value = size[1];
    return ((("clamp(0px, " + preferred) + ", ") + $int.to_string(value)) + "px)";
  }
}

function is_headers(node) {
  if (node instanceof Headers) {
    return true;
  } else if (node instanceof Rows) {
    return false;
  } else {
    return false;
  }
}

function is_rows(node) {
  if (node instanceof Headers) {
    return false;
  } else if (node instanceof Rows) {
    return true;
  } else {
    return false;
  }
}

function is_footer(node) {
  if (node instanceof Headers) {
    return false;
  } else if (node instanceof Rows) {
    return false;
  } else {
    return true;
  }
}

function compute_columns(lines) {
  if (lines instanceof Headers && lines.headers.hasLength(0)) {
    return new Error(undefined);
  } else if (lines instanceof Rows && lines.rows.hasLength(0)) {
    return new Error(undefined);
  } else if (lines instanceof Rows) {
    return new Error(undefined);
  } else if (lines instanceof Footer) {
    return new Error(undefined);
  } else {
    let headers$1 = lines.headers;
    let count = $list.length(headers$1);
    let _pipe = $list.map(headers$1, (h) => { return h.size; });
    let _pipe$1 = $list.map(_pipe, size_to_string);
    let _pipe$2 = $string.join(_pipe$1, " ");
    let _pipe$3 = ((_capture) => { return $pair.new$(count, _capture); })(
      _pipe$2,
    );
    return new Ok(_pipe$3);
  }
}

function view_table(columns, headers, body, footer) {
  return $s.table(
    columns,
    toList([]),
    toList([
      (() => {
        if (!headers.isOk()) {
          return $e.none();
        } else if (headers.isOk() && headers[0] instanceof Footer) {
          return $e.none();
        } else if (headers.isOk() && headers[0] instanceof Rows) {
          return $e.none();
        } else if (headers.isOk() &&
        headers[0] instanceof Headers &&
        headers[0].headers.hasLength(0)) {
          return $e.none();
        } else {
          let headers$1 = headers[0].headers;
          return $s.thead(
            columns,
            toList([]),
            toList([
              $s.thr(
                columns,
                toList([]),
                $list.map(
                  headers$1,
                  (header) => {
                    return $s.theader(
                      toList([]),
                      toList([$h.text(header.title)]),
                    );
                  },
                ),
              ),
            ]),
          );
        }
      })(),
      (() => {
        if (!body.isOk()) {
          return $e.none();
        } else if (body.isOk() && body[0] instanceof Footer) {
          return $e.none();
        } else if (body.isOk() &&
        body[0] instanceof Rows &&
        body[0].rows.hasLength(0)) {
          return $e.none();
        } else if (body.isOk() && body[0] instanceof Headers) {
          return $e.none();
        } else {
          let rows = body[0].rows;
          let _pipe = rows;
          let _pipe$1 = $list.map(
            _pipe,
            (row) => {
              let on_click$1 = (() => {
                let $ = row.on_click;
                if ($ instanceof $option.None) {
                  return $a.none();
                } else {
                  let msg = $[0];
                  return $event.on_click(msg);
                }
              })();
              return $s.tr(
                columns,
                row.hover,
                toList([on_click$1]),
                $list.map(
                  row.cells,
                  (data) => {
                    let on_click$2 = (() => {
                      let $ = data.stop_propagation;
                      if ($) {
                        return $utils.stop_propagation();
                      } else {
                        return $a.none();
                      }
                    })();
                    return $s.tcell(
                      $option.unwrap(data.style, toList([])),
                      toList([on_click$2]),
                      toList([data.content]),
                    );
                  },
                ),
              );
            },
          );
          let _pipe$2 = $list.intersperse(_pipe$1, $s.separator(columns));
          return ((_capture) => {
            return $s.tbody(columns, toList([]), _capture);
          })(_pipe$2);
        }
      })(),
      (() => {
        if (!footer.isOk()) {
          return $e.none();
        } else if (footer.isOk() && footer[0] instanceof Rows) {
          return $e.none();
        } else if (footer.isOk() && footer[0] instanceof Headers) {
          return $e.none();
        } else {
          let disabled = footer[0].disabled;
          let disabled$1 = $a.attribute("disabled", $bool.to_string(disabled));
          return $e.fragment(
            toList([
              $s.separator(columns),
              $s.tfoot(
                columns[0],
                toList([disabled$1]),
                toList([
                  $s.footer_dropdown(
                    toList([]),
                    toList([
                      $h.text("Showing"),
                      $dropdown.dropdown(
                        toList([
                          $dropdown.selected(new $option.Some(10)),
                          $dropdown.show_arrow(),
                        ]),
                        toList([
                          $dropdown.choice("10", 10),
                          $dropdown.choice("15", 15),
                          $dropdown.choice("20", 20),
                          $dropdown.choice("25", 25),
                        ]),
                      ),
                    ]),
                  ),
                  $s.footer_actions(
                    toList([]),
                    toList([
                      $button.secondary(
                        toList([$a.disabled(true)]),
                        toList([
                          $s.icon_wrapper(
                            toList([]),
                            toList([$icons.left_arrow()]),
                          ),
                          $h.text("Previous"),
                        ]),
                      ),
                      $button.secondary(
                        toList([]),
                        toList([
                          $h.text("Next"),
                          $s.icon_wrapper(
                            toList([]),
                            toList([$icons.right_arrow()]),
                          ),
                        ]),
                      ),
                    ]),
                  ),
                ]),
              ),
            ]),
          );
        }
      })(),
    ]),
  );
}

export function table(children) {
  let headers$1 = $list.find(children, is_headers);
  let body$1 = $list.find(children, is_rows);
  let footer$1 = $list.find(children, is_footer);
  let _pipe = $result.try$(headers$1, compute_columns);
  let _pipe$1 = $result.try_recover(
    _pipe,
    (_) => { return $result.try$(body$1, compute_columns); },
  );
  let _pipe$2 = $result.map(
    _pipe$1,
    (_capture) => { return view_table(_capture, headers$1, body$1, footer$1); },
  );
  return $result.unwrap(_pipe$2, $e.none());
}

export function headers(headers) {
  return new Headers(headers);
}

export function header(title) {
  return new Header(title, new Fraction(1));
}

export function fit(header) {
  return header.withFields({ size: new Fit() });
}

export function fraction(header, fr) {
  return header.withFields({ size: new Fraction(fr) });
}

export function min_content(header) {
  return header.withFields({ size: new MinContent() });
}

export function clamp(header, preferred, max_width) {
  return header.withFields({ size: new Clamp(preferred, max_width) });
}

export function body(rows) {
  return new Rows(rows);
}

export function row(cells) {
  return new Row(cells, true, new $option.None());
}

export function hover(row, value) {
  return row.withFields({ hover: value });
}

export function on_click(row, on_click) {
  return row.withFields({ on_click: new $option.Some(on_click) });
}

export function cell(cell) {
  return new Cell(cell, new $option.None(), false);
}

export function stop_cell_propagation(cell) {
  return cell.withFields({ stop_propagation: true });
}

function apply_style(cell, style) {
  let style$1 = (() => {
    let _pipe = cell.style;
    let _pipe$1 = $option.unwrap(_pipe, toList([]));
    let _pipe$2 = $list.prepend(_pipe$1, style);
    return new $option.Some(_pipe$2);
  })();
  return cell.withFields({ style: style$1 });
}

export function span(cell, span) {
  return apply_style(cell, $sketch.grid_column("span " + $int.to_string(span)));
}

export function justify(cell, justify) {
  return apply_style(cell, $sketch.justify_self(justify));
}

export function footer(disabled) {
  return new Footer(disabled);
}
