/// <reference types="./msg.d.mts" />
import * as $auth0 from "../../auth0_client/auth0/client.mjs";
import * as $auth0_user from "../../auth0_client/auth0/client/user.mjs";
import * as $birl from "../../birl/birl.mjs";
import * as $connector from "../../common/data/connector.mjs";
import * as $connector_settings from "../../common/data/connector_settings.mjs";
import * as $copilot_question from "../../common/data/copilot_question.mjs";
import * as $data_point from "../../common/data/data_point.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import * as $notification from "../../common/data/notification.mjs";
import * as $proposal from "../../common/data/proposal.mjs";
import * as $qualification_matrix from "../../common/data/qualification_matrix.mjs";
import * as $question from "../../common/data/question.mjs";
import * as $spreadsheet from "../../common/data/spreadsheet.mjs";
import * as $tag from "../../common/data/tag.mjs";
import * as $translate from "../../common/data/translate.mjs";
import * as $user from "../../common/data/user.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $toast from "../../grille_pain/grille_pain/lustre/toast.mjs";
import * as $level from "../../grille_pain/grille_pain/toast/level.mjs";
import * as $file from "../../plinth/plinth/browser/file.mjs";
import * as $route from "../data/route.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";

export class Add extends $CustomType {}

export class Remove extends $CustomType {}

export class ApplicationPerformedRequest extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApplicationCompletedRequest extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class AddQuestionOwner extends $CustomType {
  constructor(question_id, proposal_id, user_id) {
    super();
    this.question_id = question_id;
    this.proposal_id = proposal_id;
    this.user_id = user_id;
  }
}

export class Auth0 extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class AuthStateChanged extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Back extends $CustomType {}

export class ContentLibrary extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserDeletedDataSource extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class UserDisplayedDeleteProposalModal extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class UserDisplayedDeleteDataSourceModal extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class UserDisplayedDeleteQuestionInProposalModal extends $CustomType {
  constructor(proposal_id, question_id) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
  }
}

export class UserClosedModal extends $CustomType {}

export class UserSelectedPage extends $CustomType {
  constructor(page_index) {
    super();
    this.page_index = page_index;
  }
}

export class DisplayToast extends $CustomType {
  constructor(level, message) {
    super();
    this.level = level;
    this.message = message;
  }
}

export class FetchUserData extends $CustomType {}

export class HideToast extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class OnConnectors extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnCopilotInput extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnCopilotQuestionUpdated extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class OnCopilotSubmit extends $CustomType {}

export class OnCopilotThreads extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserEdittedCustomRewording extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnNewProposal extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiReturnedProposalQuestion extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class ApiRejectedProposalQuestion extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class ApiReturnedProposals extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiReturnedProposalsQuestions extends $CustomType {
  constructor(questions, data_sources, data_points) {
    super();
    this.questions = questions;
    this.data_sources = data_sources;
    this.data_points = data_points;
  }
}

export class ApiTimeoutedProposalsQuestions extends $CustomType {}

export class OnRouteChange extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnUserFunctionSelected extends $CustomType {
  constructor(user_id, function$) {
    super();
    this.user_id = user_id;
    this.function = function$;
  }
}

export class OnUsers extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OpenLink extends $CustomType {
  constructor(link) {
    super();
    this.link = link;
  }
}

export class UserDeletedProposal extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class UserDeletedQuestionInProposal extends $CustomType {
  constructor(proposal_id, question_id) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
  }
}

export class UserClickedProposal extends $CustomType {
  constructor(id, sheet) {
    super();
    this.id = id;
    this.sheet = sheet;
  }
}

export class ApplicationQueriedProposalQuestions extends $CustomType {
  constructor(timeout, id) {
    super();
    this.timeout = timeout;
    this.id = id;
  }
}

export class SaveProposalTitle extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class UserToggledQuestionMoreButton extends $CustomType {
  constructor(question_id) {
    super();
    this.question_id = question_id;
  }
}

export class DocumentReturnedMoreButtonUnsubscriber extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserClickedAiRewrite extends $CustomType {
  constructor(proposal_id, question_id, asked) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
    this.asked = asked;
  }
}

export class UserClickedAddToQuestionBank extends $CustomType {
  constructor(proposal_id, question_id) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
  }
}

export class UserSubmittedCustomRewording extends $CustomType {
  constructor(proposal_id, question_id) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
  }
}

export class UserUpdatedProposalFiltersStatus extends $CustomType {
  constructor(status) {
    super();
    this.status = status;
  }
}

export class UserUpdatedProposalFiltersOwner extends $CustomType {
  constructor(owner) {
    super();
    this.owner = owner;
  }
}

export class ToggleFeed extends $CustomType {}

export class UserToggledFilterProposal extends $CustomType {}

export class UserSelectedLanguage extends $CustomType {
  constructor(language) {
    super();
    this.language = language;
  }
}

export class ToggleProposalCollaboratorsPopup extends $CustomType {
  constructor(question_id) {
    super();
    this.question_id = question_id;
  }
}

export class UpdateNotifications extends $CustomType {
  constructor(notifications) {
    super();
    this.notifications = notifications;
  }
}

export class MarkAllNotificationsAsRead extends $CustomType {}

export class MarkNotificationAsRead extends $CustomType {
  constructor(notification_id) {
    super();
    this.notification_id = notification_id;
  }
}

export class UpdateNotification extends $CustomType {
  constructor(notification) {
    super();
    this.notification = notification;
  }
}

export class UserUpdatedProposalQuestion extends $CustomType {
  constructor(question, content) {
    super();
    this.question = question;
    this.content = content;
  }
}

export class UserUpdatedProposalQuestionYesNo extends $CustomType {
  constructor(question, yes_no) {
    super();
    this.question = question;
    this.yes_no = yes_no;
  }
}

export class UserUpdatedProposalQuestionChoice extends $CustomType {
  constructor(question, choice) {
    super();
    this.question = question;
    this.choice = choice;
  }
}

export class UserUpdatedProposalTitle extends $CustomType {
  constructor(id, title) {
    super();
    this.id = id;
    this.title = title;
  }
}

export class UserToggledProposalCollaborator extends $CustomType {
  constructor(proposal_id, user_id) {
    super();
    this.proposal_id = proposal_id;
    this.user_id = user_id;
  }
}

export class ApiRejectedProposal extends $CustomType {
  constructor(proposal) {
    super();
    this.proposal = proposal;
  }
}

export class UpsertQuestion extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class ClosePopups extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class Xlsx extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserValidatedQuestion extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class UserClickedNotification extends $CustomType {
  constructor(notification) {
    super();
    this.notification = notification;
  }
}

export class ApiReturnedTranslations extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ToastUser extends $CustomType {
  constructor(options, content) {
    super();
    this.options = options;
    this.content = content;
  }
}

export class UpdateTags extends $CustomType {
  constructor(tags) {
    super();
    this.tags = tags;
  }
}

export class WebsocketReceivedMessage extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class ApiReturnedQualificationMatrix extends $CustomType {
  constructor(qualification_matrix) {
    super();
    this.qualification_matrix = qualification_matrix;
  }
}

export class None extends $CustomType {}

export class GenerateXlsx extends $CustomType {
  constructor(proposal_id) {
    super();
    this.proposal_id = proposal_id;
  }
}

export class DownloadXlsx extends $CustomType {
  constructor(proposal_id, toast_id) {
    super();
    this.proposal_id = proposal_id;
    this.toast_id = toast_id;
  }
}

export class ApiReturnedConnectorData extends $CustomType {
  constructor(x0, x1) {
    super();
    this[0] = x0;
    this[1] = x1;
  }
}

export class ApiReturnedConnectorSettings extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserAskedConnectingTo extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserFetchedConnectorData extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserSubmitedGoogleFolderId extends $CustomType {}

export class UserToggledConfluencePanel extends $CustomType {}

export class UserUpdatedConfluenceApiKey extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserUpdatedConfluenceDomain extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserUpdatedConfluenceUser extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserToggledNotionPanel extends $CustomType {}

export class UserUpdatedNotionApiKey extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserUpdatedNotionPageId extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserUpdatedGoogleFolderId extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserAddedCollaborator extends $CustomType {
  constructor(user_id) {
    super();
    this.user_id = user_id;
  }
}

export class UserRemovedCollaborator extends $CustomType {
  constructor(user_id) {
    super();
    this.user_id = user_id;
  }
}

export class UserAddedLanguage extends $CustomType {
  constructor(language) {
    super();
    this.language = language;
  }
}

export class UserRemovedLanguage extends $CustomType {
  constructor(language) {
    super();
    this.language = language;
  }
}

export class UserSkippedQualificationMatrix extends $CustomType {}

export class UserSubmittedDetails extends $CustomType {}

export class UserSelectedQualificationMatrixState extends $CustomType {
  constructor(id, answer) {
    super();
    this.id = id;
    this.answer = answer;
  }
}

export class UserUpdatedClient extends $CustomType {
  constructor(name) {
    super();
    this.name = name;
  }
}

export class UserUpdatedDate extends $CustomType {
  constructor(date) {
    super();
    this.date = date;
  }
}

export class UserUpdatedName extends $CustomType {
  constructor(name) {
    super();
    this.name = name;
  }
}

export class UserUsedQualificationMatrix extends $CustomType {}

export class OnNewProposalSpreadsheetSelector extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiReturnedSavedProposal extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserConfirmedQualificationMatrix extends $CustomType {}

export class UserConfirmedModalQualificationMatrix extends $CustomType {}

export class OnNewProposalSkip extends $CustomType {}

export class UserChangedColumnType extends $CustomType {
  constructor(sheet_name, index, type_) {
    super();
    this.sheet_name = sheet_name;
    this.index = index;
    this.type_ = type_;
  }
}

export class UserChangedInvalidLine extends $CustomType {
  constructor(sheet_name, index, validity) {
    super();
    this.sheet_name = sheet_name;
    this.index = index;
    this.validity = validity;
  }
}

export class UserUpdatedSelectedSheet extends $CustomType {
  constructor(sheet_name) {
    super();
    this.sheet_name = sheet_name;
  }
}

export class UserSkippedSelectedSheet extends $CustomType {
  constructor(current_sheet_name, next_sheet_name) {
    super();
    this.current_sheet_name = current_sheet_name;
    this.next_sheet_name = next_sheet_name;
  }
}

export class UserCancelledSpreadsheetSelection extends $CustomType {}

export class ApiFetchedXlsx extends $CustomType {
  constructor(decoded_sheet) {
    super();
    this.decoded_sheet = decoded_sheet;
  }
}

export class ApiAutoDetectedXlsxRows extends $CustomType {
  constructor(sheet, rows_type) {
    super();
    this.sheet = sheet;
    this.rows_type = rows_type;
  }
}

export class UserSelectedXlsx extends $CustomType {
  constructor(file) {
    super();
    this.file = file;
  }
}

export class UserSubmittedXlsx extends $CustomType {}

export class ApiRejectedUploadingQuestion extends $CustomType {
  constructor(ingestion) {
    super();
    this.ingestion = ingestion;
  }
}

export class ApiReturnedSource extends $CustomType {
  constructor(source) {
    super();
    this.source = source;
  }
}

export class ApiReturnedSources extends $CustomType {
  constructor(sources) {
    super();
    this.sources = sources;
  }
}

export class ApiReturnedStatusStats extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiReturnedUploadingQuestion extends $CustomType {
  constructor(ingestion) {
    super();
    this.ingestion = ingestion;
  }
}

export class ContentLibraryHandledSpreadsheetSelector extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserAssignedExpirationDate extends $CustomType {
  constructor(expiration_date) {
    super();
    this.expiration_date = expiration_date;
  }
}

export class UserAssignedStatus extends $CustomType {
  constructor(status) {
    super();
    this.status = status;
  }
}

export class UserAssignedTag extends $CustomType {
  constructor(tag, add_remove) {
    super();
    this.tag = tag;
    this.add_remove = add_remove;
  }
}

export class UserClickedGlobalCheckbox extends $CustomType {}

export class UserCreatedQuestion extends $CustomType {}

export class UserEdittedQuestion extends $CustomType {
  constructor(question_id) {
    super();
    this.question_id = question_id;
  }
}

export class UserSelectedFilter extends $CustomType {
  constructor(filter) {
    super();
    this.filter = filter;
  }
}

export class UserSelectedSource extends $CustomType {
  constructor(id, checked) {
    super();
    this.id = id;
    this.checked = checked;
  }
}

export class UserSubmittedQuestionBankEdit extends $CustomType {}

export class UserToggledAssignUserPopup extends $CustomType {
  constructor(user_id) {
    super();
    this.user_id = user_id;
  }
}

export class UserToggledChangeExpirationDatePopup extends $CustomType {}

export class UserToggledChangeOwnerPopup extends $CustomType {}

export class UserToggledChangeStatusPopup extends $CustomType {}

export class UserToggledChangeTags extends $CustomType {}

export class UserUpdatedExpirationDateInput extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class UserUpdatedQuestionBankEditAnswer extends $CustomType {
  constructor(answer) {
    super();
    this.answer = answer;
  }
}

export class UserUpdatedQuestionBankEditQuestion extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class UserUpdatedSearch extends $CustomType {
  constructor(search) {
    super();
    this.search = search;
  }
}

export class UserUploadedSourceFiles extends $CustomType {
  constructor(sources) {
    super();
    this.sources = sources;
  }
}
