/// <reference types="./trash.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function trash() {
  let content = "<path d=\"M15 4.11475H20V6.11475H18V19.1147C18 19.38 17.8946 19.6343 17.7071 19.8219C17.5196 20.0094 17.2652 20.1147 17 20.1147H3C2.73478 20.1147 2.48043 20.0094 2.29289 19.8219C2.10536 19.6343 2 19.38 2 19.1147V6.11475H0V4.11475H5V1.11475C5 0.84953 5.10536 0.595176 5.29289 0.407639C5.48043 0.220103 5.73478 0.114746 6 0.114746H14C14.2652 0.114746 14.5196 0.220103 14.7071 0.407639C14.8946 0.595176 15 0.84953 15 1.11475V4.11475ZM16 6.11475H4V18.1147H16V6.11475ZM7 9.11475H9V15.1147H7V9.11475ZM11 9.11475H13V15.1147H11V9.11475ZM7 2.11475V4.11475H13V2.11475H7Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 20 20"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
