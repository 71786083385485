/// <reference types="./spreadsheet_selector.d.mts" />
import * as $spreadsheet from "../../common/data/spreadsheet.mjs";
import * as $http from "../../gleam_http/gleam/http.mjs";
import * as $promise from "../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $function from "../../gleam_stdlib/gleam/function.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $toast from "../../grille_pain/grille_pain/lustre/toast.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $file from "../../plinth/plinth/browser/file.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $spreadsheet_selector from "../data/ui/spreadsheet_selector.mjs";
import * as $form_data from "../form_data.mjs";
import * as $ask from "../frontend/ask.mjs";
import * as $middleware from "../frontend/middleware.mjs";
import { Ok, Error, toList, makeError, isEqual } from "../gleam.mjs";

export function is_xlsx_fetched(msg) {
  if (msg instanceof $msg.ApiFetchedXlsx && msg.decoded_sheet.isOk()) {
    return true;
  } else {
    return false;
  }
}

function xlsx_fetched(selector, xlsx) {
  let _pipe = selector;
  let _pipe$1 = $spreadsheet_selector.update_xlsx(_pipe, xlsx);
  return (() => {
    let $ = $list.first(xlsx.data);
    if ($.isOk()) {
      let worksheet = $[0];
      return (_capture) => {
        return $spreadsheet_selector.update_selected_sheet(
          _capture,
          worksheet.name,
        );
      };
    } else {
      return $function.identity;
    }
  })()(_pipe$1);
}

function xlsx_rows_auto_detected(selector, sheet, rows_type) {
  return $dict.fold(
    rows_type,
    selector,
    (sel, row, value) => {
      return $spreadsheet_selector.update_invalid_line(sel, sheet, row, value);
    },
  );
}

function send_excel_decoding_request(model, file) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $middleware.require_org_id(
        model,
        (org_id) => {
          return $middleware.require_user_id(
            model,
            (user_id) => {
              return $effect.from(
                (dispatch) => {
                  if (file instanceof $option.None) {
                    $promise.resolve(undefined)
                  } else {
                    let file$1 = file[0];
                    let _pipe = $file.bytes(file$1);
                    $promise.map(
                      _pipe,
                      (blob) => {
                        let at = toList(["decode-excel"]);
                        let _pipe$1 = $ask.to(new $ask.Nabu(), at);
                        let _pipe$2 = $ask.via(_pipe$1, new $http.Post());
                        let _pipe$3 = $ask.bearing(_pipe$2, access_token);
                        let _pipe$4 = $ask.data(
                          _pipe$3,
                          $form_data.to_form_data(
                            (() => {
                              let _pipe$4 = $json.object(
                                toList([
                                  ["org_id", $json.string(org_id)],
                                  ["user_id", $json.string(user_id)],
                                ]),
                              );
                              return $json.to_string(_pipe$4);
                            })(),
                            blob,
                          ),
                        );
                        let _pipe$5 = $ask.expect(
                          _pipe$4,
                          $spreadsheet.decode(blob),
                        );
                        let _pipe$6 = $ask.notify(
                          _pipe$5,
                          (q) => {
                            return dispatch(new $msg.ApiFetchedXlsx(new Ok(q)));
                          },
                        );
                        $ask.run(_pipe$6)
                        return undefined;
                      },
                    )
                  }
                  return undefined;
                },
              );
            },
          );
        },
      );
    },
  );
}

function send_detect_rows_request(model, file, columns_type, sheet) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $middleware.require_org_id(
        model,
        (org_id) => {
          return $middleware.require_user_id(
            model,
            (user_id) => {
              return $effect.from(
                (dispatch) => {
                  if (file instanceof $option.None) {
                    $promise.resolve(undefined)
                  } else {
                    let file$1 = file[0];
                    let _pipe = $file.bytes(file$1);
                    $promise.map(
                      _pipe,
                      (blob) => {
                        let at = toList(["detect-rows"]);
                        let _pipe$1 = $ask.to(new $ask.Nabu(), at);
                        let _pipe$2 = $ask.via(_pipe$1, new $http.Post());
                        let _pipe$3 = $ask.bearing(_pipe$2, access_token);
                        let _pipe$4 = $ask.data(
                          _pipe$3,
                          $form_data.to_form_data(
                            (() => {
                              let _pipe$4 = $json.object(
                                toList([
                                  [
                                    "columns_type",
                                    (() => {
                                      let _pipe$4 = columns_type;
                                      let _pipe$5 = $list.map(
                                        _pipe$4,
                                        (t) => {
                                          return [
                                            t[0],
                                            (() => {
                                              let _pipe$5 = t[1];
                                              return $spreadsheet.encode_columns_type(
                                                _pipe$5,
                                              );
                                            })(),
                                          ];
                                        },
                                      );
                                      return $json.object(_pipe$5);
                                    })(),
                                  ],
                                  ["sheet", $json.string(sheet)],
                                  ["user_id", $json.string(user_id)],
                                  ["org_id", $json.string(org_id)],
                                ]),
                              );
                              return $json.to_string(_pipe$4);
                            })(),
                            blob,
                          ),
                        );
                        let _pipe$5 = $ask.expect(
                          _pipe$4,
                          $spreadsheet.decode_type(
                            $spreadsheet.row_type_from_string,
                          ),
                        );
                        let _pipe$6 = $ask.notify(
                          _pipe$5,
                          (r) => {
                            return dispatch(
                              new $msg.ApiAutoDetectedXlsxRows(sheet, r),
                            );
                          },
                        );
                        $ask.run(_pipe$6)
                        return undefined;
                      },
                    )
                  }
                  return undefined;
                },
              );
            },
          );
        },
      );
    },
  );
}

const error_msg = "The spreadsheet is too huge. Please, upload a file with less questions, or contact us directly.";

export function update(model, selector, msg) {
  if (msg instanceof $msg.UserChangedColumnType) {
    let sheet = msg.sheet_name;
    let index = msg.index;
    let value = msg.type_;
    if (value instanceof $spreadsheet.QuestionColumn) {
      let selector$1 = $spreadsheet_selector.update_column_type(
        selector,
        sheet,
        index,
        value,
      );
      let _pipe = selector$1;
      return $pair.new$(
        _pipe,
        send_detect_rows_request(
          model,
          selector$1.file,
          selector$1.columns_type,
          sheet,
        ),
      );
    } else {
      let previous_type = (() => {
        let _pipe = selector.columns_type;
        let _pipe$1 = $list.find_map(
          _pipe,
          (s) => {
            let $ = s[0] === sheet;
            if ($) {
              let _pipe$1 = $dict.get(s[1], index);
              let _pipe$2 = $result.unwrap(
                _pipe$1,
                new $spreadsheet.UndefinedColumn(),
              );
              return new Ok(_pipe$2);
            } else {
              return new Error(new $spreadsheet.UndefinedColumn());
            }
          },
        );
        return $result.unwrap(_pipe$1, new $spreadsheet.UndefinedColumn());
      })();
      let _pipe = (() => {
        let $ = isEqual(previous_type, new $spreadsheet.QuestionColumn());
        if ($) {
          let _pipe = $spreadsheet_selector.reset_invalid_lines(selector, sheet);
          return $spreadsheet_selector.update_column_type(
            _pipe,
            sheet,
            index,
            value,
          );
        } else {
          return $spreadsheet_selector.update_column_type(
            selector,
            sheet,
            index,
            value,
          );
        }
      })();
      return $pair.new$(_pipe, $effect.none());
    }
  } else if (msg instanceof $msg.UserChangedInvalidLine) {
    let sheet = msg.sheet_name;
    let index = msg.index;
    let value = msg.validity;
    let _pipe = $spreadsheet_selector.update_invalid_line(
      selector,
      sheet,
      index,
      value,
    );
    return $pair.new$(_pipe, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedSelectedSheet) {
    let sheet = msg.sheet_name;
    let _pipe = $spreadsheet_selector.update_selected_sheet(selector, sheet);
    return $pair.new$(_pipe, $effect.none());
  } else if (msg instanceof $msg.UserSkippedSelectedSheet) {
    let current_sheet = msg.current_sheet_name;
    let next_sheet = msg.next_sheet_name;
    let _pipe = $spreadsheet_selector.skip_selected_sheet(
      selector,
      current_sheet,
      next_sheet,
    );
    return $pair.new$(_pipe, $effect.none());
  } else if (msg instanceof $msg.ApiFetchedXlsx && msg.decoded_sheet.isOk()) {
    let xlsx = msg.decoded_sheet[0];
    return [xlsx_fetched(selector, xlsx), $effect.none()];
  } else if (msg instanceof $msg.ApiFetchedXlsx && !msg.decoded_sheet.isOk()) {
    return [selector, $toast.error(error_msg)];
  } else if (msg instanceof $msg.UserSelectedXlsx) {
    let file = msg.file;
    let _pipe = send_excel_decoding_request(model, new $option.Some(file));
    return ((_capture) => {
      return $pair.new$(
        selector.withFields({ file: new $option.Some(file) }),
        _capture,
      );
    })(_pipe);
  } else if (msg instanceof $msg.ApiAutoDetectedXlsxRows) {
    let sheet = msg.sheet;
    let rows_type = msg.rows_type;
    return [xlsx_rows_auto_detected(selector, sheet, rows_type), $effect.none()];
  } else {
    throw makeError(
      "panic",
      "update/spreadsheet_selector",
      114,
      "update",
      "Should be handled outside of spreadsheet_selector.update",
      {}
    )
  }
}
