/// <reference types="./notion.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Token extends $CustomType {
  constructor(api_key, page_ids) {
    super();
    this.api_key = api_key;
    this.page_ids = page_ids;
  }
}

export function new$() {
  let api_key = "";
  let page_ids = toList([]);
  return new Token(api_key, page_ids);
}

export function encode(token) {
  return $json.object(
    toList([
      ["api_key", $json.string(token.api_key)],
      ["page_ids", $json.array(token.page_ids, $json.string)],
    ]),
  );
}

export function decode(dyn) {
  return $dynamic.decode2(
    (var0, var1) => { return new Token(var0, var1); },
    $dynamic.field("api_key", $dynamic.string),
    $dynamic.field("page_ids", $dynamic.list($dynamic.string)),
  )(dyn);
}

export function is_submit_disabled(token) {
  return (token.api_key === "") || $list.is_empty(token.page_ids);
}
