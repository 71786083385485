/// <reference types="./proposal.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $qualification_matrix from "../data/qualification_matrix.mjs";
import * as $question from "../data/question.mjs";
import * as $spreadsheet from "../data/spreadsheet.mjs";
import * as $status from "../data/status.mjs";
import * as $translate from "../data/translate.mjs";
import * as $decode_ from "../decode/extra.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType, isEqual } from "../gleam.mjs";
import * as $dynamic_ from "../gleam/dynamic/extra.mjs";

export class QuestionnaireResponse extends $CustomType {
  constructor(name, column, kind) {
    super();
    this.name = name;
    this.column = column;
    this.kind = kind;
  }
}

export class Questionnaire extends $CustomType {
  constructor(sheets, responses, qualification_matrix, worksheets, languages) {
    super();
    this.sheets = sheets;
    this.responses = responses;
    this.qualification_matrix = qualification_matrix;
    this.worksheets = worksheets;
    this.languages = languages;
  }
}

export class Display extends $CustomType {
  constructor(type_) {
    super();
    this.type_ = type_;
  }
}

export class Proposal extends $CustomType {
  constructor(id, display, org_id, name, deadline, owner, client, collaborators, progress, status, metadata, created_at) {
    super();
    this.id = id;
    this.display = display;
    this.org_id = org_id;
    this.name = name;
    this.deadline = deadline;
    this.owner = owner;
    this.client = client;
    this.collaborators = collaborators;
    this.progress = progress;
    this.status = status;
    this.metadata = metadata;
    this.created_at = created_at;
  }
}

export class Validated extends $CustomType {}

export class InReview extends $CustomType {}

export class AllStatus extends $CustomType {}

export class ProposalFilters extends $CustomType {
  constructor(status, owner) {
    super();
    this.status = status;
    this.owner = owner;
  }
}

export function is_ongoing(proposal) {
  return (isEqual(proposal.status, new $status.Pending())) || (isEqual(
    proposal.status,
    new $status.InProgress()
  ));
}

export function is_finished(proposal) {
  return !is_ongoing(proposal);
}

export function get_languages(p, q) {
  let $ = (() => {
    let _pipe = p.metadata.languages;
    return $list.length(_pipe);
  })() === 0;
  if ($) {
    return toList([]);
  } else {
    let $1 = q.metadata.language;
    if ($1 instanceof $option.None) {
      return p.metadata.languages;
    } else {
      let l = $1[0];
      let _pipe = p.metadata.languages;
      let _pipe$1 = $list.append(_pipe, toList([l]));
      return $list.unique(_pipe$1);
    }
  }
}

export function encode_metadata(metadata) {
  return $json.object(
    toList([
      ["type", $json.string("questionnaire")],
      [
        "languages",
        $json.array(
          metadata.languages,
          (l) => {
            let _pipe = $translate.decode_language_iso639_1(l);
            return $json.string(_pipe);
          },
        ),
      ],
      ["sheets", $json.array(metadata.sheets, $json.string)],
      [
        "responses",
        $json.array(
          metadata.responses,
          (q) => {
            return $json.object(
              toList([
                ["name", $json.string(q.name)],
                ["column", $json.int(q.column)],
                ["kind", $question.encode_question_type(q.kind)],
              ]),
            );
          },
        ),
      ],
      [
        "worksheets",
        $json.array(
          metadata.worksheets,
          (w) => {
            return $json.object(
              toList([
                ["name", $json.string(w.name)],
                ["start_index", $json.int(w.start_index)],
                [
                  "content",
                  $json.array(
                    w.content,
                    (r) => { return $json.array(r, $spreadsheet.encode_cell); },
                  ),
                ],
                [
                  "columns_type",
                  $spreadsheet.encode_columns_type(w.columns_type),
                ],
                ["rows_type", $spreadsheet.encode_rows_type(w.rows_type)],
              ]),
            );
          },
        ),
      ],
      [
        "qualification_matrix",
        $json.nullable(
          metadata.qualification_matrix,
          (q) => {
            return $json.array(
              q,
              (q) => {
                return $json.object(
                  toList([
                    ["item", $qualification_matrix.encode(q[0])],
                    ["state", $qualification_matrix.encode_answer(q[1])],
                  ]),
                );
              },
            );
          },
        ),
      ],
    ]),
  );
}

function decode_languages(dyn) {
  let _pipe = $dynamic.optional(
    $dynamic.list($translate.decode_language_iso639_1_dyn),
  )(dyn);
  return $result.map(
    _pipe,
    (_capture) => { return $option.unwrap(_capture, toList([])); },
  );
}

function decode_string_to_cell(dyn) {
  let _pipe = $dynamic.optional($dynamic.string)(dyn);
  return $result.map(
    _pipe,
    (s) => { return new $spreadsheet.Cell(s, toList([])); },
  );
}

export function encode_display(display) {
  return $json.object(toList([["type", $json.string(display.type_)]]));
}

export function encode(proposal) {
  return $json.object(
    toList([
      ["id", $json.string(proposal.id)],
      ["display", encode_display(proposal.display)],
      ["org_id", $json.string(proposal.org_id)],
      ["name", $json.string(proposal.name)],
      [
        "deadline",
        (() => {
          let _pipe = proposal.deadline;
          let _pipe$1 = $birl.to_iso8601(_pipe);
          return $json.string(_pipe$1);
        })(),
      ],
      ["owner", $json.string(proposal.owner)],
      ["client", $json.string(proposal.client)],
      ["collaborators", $json.array(proposal.collaborators, $json.string)],
      ["progress", $json.int(proposal.progress)],
      ["status", $status.encode(proposal.status)],
      ["metadata", encode_metadata(proposal.metadata)],
      [
        "created_at",
        (() => {
          let _pipe = proposal.created_at;
          let _pipe$1 = $birl.to_iso8601(_pipe);
          return $json.string(_pipe$1);
        })(),
      ],
    ]),
  );
}

export function is_collaborator(proposal, user_id) {
  let all_collaborators = listPrepend(proposal.owner, proposal.collaborators);
  return $list.contains(all_collaborators, user_id);
}

export function toggle_collaborator(proposal, user_id) {
  let collaborators = (() => {
    let $ = is_collaborator(proposal, user_id);
    if ($) {
      return $list.filter(
        proposal.collaborators,
        (u) => { return u !== user_id; },
      );
    } else {
      return listPrepend(user_id, proposal.collaborators);
    }
  })();
  return proposal.withFields({ collaborators: collaborators });
}

export function count_qualification_matrix_answers(qualification_matrix) {
  return $list.fold(
    qualification_matrix,
    [0.0, 0.0, 0.0],
    (_use0, val) => {
      let yes = _use0[0];
      let no = _use0[1];
      let total = _use0[2];
      let total$1 = total + 1.0;
      let $ = val[1];
      if ($ instanceof $qualification_matrix.Yes) {
        return [yes + 1.0, no, total$1];
      } else if ($ instanceof $qualification_matrix.No) {
        return [yes, no + 1.0, total$1];
      } else {
        return [yes, no, total$1];
      }
    },
  );
}

export function decode_metadata(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (_) => {
        return $decode.parameter(
          (sheets) => {
            return $decode.parameter(
              (responses) => {
                return $decode.parameter(
                  (worksheets) => {
                    return $decode.parameter(
                      (qualification_matrix) => {
                        return $decode.parameter(
                          (languages) => {
                            return new Questionnaire(
                              sheets,
                              responses,
                              qualification_matrix,
                              worksheets,
                              languages,
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "type",
    $decode.then$(
      $decode.string,
      (t) => {
        if (t === "questionnaire") {
          return $decode.into(t);
        } else {
          return $decode.fail("type");
        }
      },
    ),
  );
  let _pipe$2 = $decode.field(_pipe$1, "sheets", $decode.list($decode.string));
  let _pipe$3 = $decode.field(
    _pipe$2,
    "responses",
    $decode.list(decode_questionnaire_response()),
  );
  let _pipe$4 = $decode.field(
    _pipe$3,
    "worksheets",
    $decode.list(decode_worksheet()),
  );
  let _pipe$5 = $decode.field(
    _pipe$4,
    "qualification_matrix",
    $decode.optional($decode.list(decode_qualification_matrix())),
  );
  let _pipe$6 = $decode.field(
    _pipe$5,
    "languages",
    $decode_.dynamic(decode_languages),
  );
  return $decode.from(_pipe$6, dyn);
}

export function decode(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (id) => {
        return $decode.parameter(
          (display) => {
            return $decode.parameter(
              (org_id) => {
                return $decode.parameter(
                  (name) => {
                    return $decode.parameter(
                      (deadline) => {
                        return $decode.parameter(
                          (owner) => {
                            return $decode.parameter(
                              (client) => {
                                return $decode.parameter(
                                  (collaborators) => {
                                    return $decode.parameter(
                                      (progress) => {
                                        return $decode.parameter(
                                          (status) => {
                                            return $decode.parameter(
                                              (metadata) => {
                                                return $decode.parameter(
                                                  (created_at) => {
                                                    return new Proposal(
                                                      id,
                                                      display,
                                                      org_id,
                                                      name,
                                                      deadline,
                                                      owner,
                                                      client,
                                                      collaborators,
                                                      progress,
                                                      status,
                                                      metadata,
                                                      created_at,
                                                    );
                                                  },
                                                );
                                              },
                                            );
                                          },
                                        );
                                      },
                                    );
                                  },
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "id",
    (() => {
      let _pipe$1 = $decode_.dynamic($dynamic_.uuid);
      return $decode.map(_pipe$1, $string.lowercase);
    })(),
  );
  let _pipe$2 = $decode.field(_pipe$1, "display", $decode_.json(decode_display));
  let _pipe$3 = $decode.field(_pipe$2, "org_id", $decode.string);
  let _pipe$4 = $decode.field(_pipe$3, "name", $decode.string);
  let _pipe$5 = $decode.field(_pipe$4, "deadline", $decode_.time());
  let _pipe$6 = $decode.field(_pipe$5, "owner", $decode.string);
  let _pipe$7 = $decode.field(_pipe$6, "client", $decode.string);
  let _pipe$8 = $decode.field(
    _pipe$7,
    "collaborators",
    $decode.list($decode.string),
  );
  let _pipe$9 = $decode.field(_pipe$8, "progress", $decode.int);
  let _pipe$10 = $decode.field(
    _pipe$9,
    "status",
    $decode_.dynamic($status.decode),
  );
  let _pipe$11 = $decode.field(
    _pipe$10,
    "metadata",
    $decode_.json(decode_metadata),
  );
  let _pipe$12 = $decode.field(_pipe$11, "created_at", $decode_.time());
  return $decode.from(_pipe$12, dyn);
}

function decode_questionnaire_response() {
  let _pipe = $decode.into(
    $decode.parameter(
      (name) => {
        return $decode.parameter(
          (column) => {
            return $decode.parameter(
              (kind) => { return new QuestionnaireResponse(name, column, kind); },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "name", $decode.string);
  let _pipe$2 = $decode.field(_pipe$1, "column", $decode.int);
  return ((d) => {
    return $decode.one_of(
      toList([
        $decode.field(
          d,
          "kind",
          $decode_.dynamic($question.decode_question_type),
        ),
        $decode.map(d, (d) => { return d(new $question.Long()); }),
      ]),
    );
  })(_pipe$2);
}

function decode_qualification_matrix() {
  let _pipe = $decode.into(
    $decode.parameter(
      (item) => {
        return $decode.parameter((state) => { return [item, state]; });
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "item",
    $decode_.dynamic($qualification_matrix.decode),
  );
  return $decode.field(
    _pipe$1,
    "state",
    $decode_.dynamic($qualification_matrix.decode_answer),
  );
}

function decode_cell(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (value) => {
        return $decode.parameter(
          (dropdown) => { return new $spreadsheet.Cell(value, dropdown); },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "value", $decode.optional($decode.string));
  let _pipe$2 = $decode.field(_pipe$1, "dropdown", $decode.list($decode.string));
  return $decode.from(_pipe$2, dyn);
}

function spreadsheet_type_from_string(from_string) {
  let _pipe = from_string;
  let _pipe$1 = $spreadsheet.decode_type(_pipe);
  let _pipe$2 = $decode_.dynamic(_pipe$1);
  let _pipe$3 = $decode.optional(_pipe$2);
  return $decode.map(
    _pipe$3,
    (_capture) => { return $option.unwrap(_capture, $dict.new$()); },
  );
}

function decode_worksheet() {
  let _pipe = $decode.into(
    $decode.parameter(
      (name) => {
        return $decode.parameter(
          (start_index) => {
            return $decode.parameter(
              (content) => {
                return $decode.parameter(
                  (columns_type) => {
                    return $decode.parameter(
                      (rows_type) => {
                        return new $spreadsheet.Worksheet(
                          name,
                          start_index,
                          content,
                          columns_type,
                          rows_type,
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "name", $decode.string);
  let _pipe$2 = $decode.field(
    _pipe$1,
    "start_index",
    (() => {
      let _pipe$2 = $decode.optional($decode.int);
      return $decode.map(
        _pipe$2,
        (_capture) => { return $option.unwrap(_capture, 0); },
      );
    })(),
  );
  let _pipe$3 = $decode.field(
    _pipe$2,
    "content",
    $decode.list(
      $decode.list(
        $decode.one_of(
          toList([
            $decode_.json(decode_cell),
            $decode_.dynamic(decode_string_to_cell),
          ]),
        ),
      ),
    ),
  );
  let _pipe$4 = $decode.field(
    _pipe$3,
    "columns_type",
    (() => {
      let _pipe$4 = $spreadsheet.column_type_from_string;
      return spreadsheet_type_from_string(_pipe$4);
    })(),
  );
  return $decode.field(
    _pipe$4,
    "rows_type",
    (() => {
      let _pipe$5 = $spreadsheet.row_type_from_string;
      return spreadsheet_type_from_string(_pipe$5);
    })(),
  );
}

export function decode_display(dyn) {
  let _pipe = $decode.into(
    $decode.parameter((type_) => { return new Display(type_); }),
  );
  let _pipe$1 = $decode.field(_pipe, "type", $decode.string);
  return $decode.from(_pipe$1, dyn);
}
