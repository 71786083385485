/// <reference types="./error.d.mts" />
import * as $lustre from "../../lustre/lustre.mjs";
import * as $error from "../../sketch/sketch/error.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";

export class SketchError extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class LustreError extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}
