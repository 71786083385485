/// <reference types="./qualification_matrix.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../gleam.mjs";

export class QualificationMatrix extends $CustomType {
  constructor(id, title, content, qm_order, org_id) {
    super();
    this.id = id;
    this.title = title;
    this.content = content;
    this.qm_order = qm_order;
    this.org_id = org_id;
  }
}

export class NotSelected extends $CustomType {}

export class Yes extends $CustomType {}

export class No extends $CustomType {}

export function decode(dyn) {
  return $dynamic.decode5(
    (var0, var1, var2, var3, var4) => {
      return new QualificationMatrix(var0, var1, var2, var3, var4);
    },
    $dynamic.field("id", $dynamic.string),
    $dynamic.field("title", $dynamic.string),
    $dynamic.field("content", $dynamic.string),
    $dynamic.field("qm_order", $dynamic.int),
    $dynamic.field("org_id", $dynamic.string),
  )(dyn);
}

export function decode_answer(dyn) {
  let $ = $dynamic.string(dyn);
  if ($.isOk() && $[0] === "not-selected") {
    return new Ok(new NotSelected());
  } else if ($.isOk() && $[0] === "yes") {
    return new Ok(new Yes());
  } else if ($.isOk() && $[0] === "no") {
    return new Ok(new No());
  } else {
    return new Error(toList([]));
  }
}

export function encode(item) {
  return $json.object(
    toList([
      ["id", $json.string(item.id)],
      ["title", $json.string(item.title)],
      ["content", $json.string(item.content)],
      ["qm_order", $json.int(item.qm_order)],
      ["org_id", $json.string(item.org_id)],
    ]),
  );
}

export function encode_answer(answer) {
  if (answer instanceof NotSelected) {
    return $json.string("not-selected");
  } else if (answer instanceof Yes) {
    return $json.string("yes");
  } else {
    return $json.string("no");
  }
}
