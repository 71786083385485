/// <reference types="./new_proposal.d.mts" />
import * as $proposal from "../../common/data/proposal.mjs";
import * as $qualification_matrix from "../../common/data/qualification_matrix.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $function from "../../gleam_stdlib/gleam/function.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $level from "../../grille_pain/grille_pain/toast/level.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import * as $new_proposal from "../data/ui/new_proposal.mjs";
import { NewProposal } from "../data/ui/new_proposal.mjs";
import * as $effects from "../frontend/effects.mjs";
import * as $proposal_effects from "../frontend/effects/new_proposal.mjs";
import { makeError } from "../gleam.mjs";
import * as $spreadsheet_selector from "../update/spreadsheet_selector.mjs";

function update_new_proposal_field(model, msg) {
  let _pipe = model.new_proposal;
  return (() => {
    if (msg instanceof $msg.UserSubmittedDetails) {
      let $ = $model.is_qualification_matrix_hidden(model);
      if ($) {
        return (t) => {
          let _pipe$1 = t;
          let _pipe$2 = $new_proposal.next_step(_pipe$1);
          return $new_proposal.next_step(_pipe$2);
        };
      } else {
        return $new_proposal.next_step;
      }
    } else if (msg instanceof $msg.UserSkippedQualificationMatrix) {
      let $ = $model.is_qualification_matrix_hidden(model);
      if ($) {
        return (t) => {
          let _pipe$1 = t;
          let _pipe$2 = $new_proposal.next_step(_pipe$1);
          return $new_proposal.next_step(_pipe$2);
        };
      } else {
        return $new_proposal.next_step;
      }
    } else if (msg instanceof $msg.UserUpdatedName) {
      let name = msg.name;
      return (_capture) => { return $new_proposal.update_name(_capture, name); };
    } else if (msg instanceof $msg.UserUpdatedClient) {
      let client = msg.name;
      return (_capture) => {
        return $new_proposal.update_client(_capture, client);
      };
    } else if (msg instanceof $msg.UserUpdatedDate) {
      let date = msg.date;
      return (_capture) => {
        return $new_proposal.update_due_date(_capture, date);
      };
    } else if (msg instanceof $msg.UserAddedCollaborator) {
      let u = msg.user_id;
      return (_capture) => {
        return $new_proposal.add_collaborator(_capture, u);
      };
    } else if (msg instanceof $msg.UserRemovedCollaborator) {
      let u = msg.user_id;
      return (_capture) => {
        return $new_proposal.remove_collaborator(_capture, u);
      };
    } else if (msg instanceof $msg.UserSelectedQualificationMatrixState) {
      let id = msg.id;
      let qm = msg.answer;
      return (_capture) => {
        return $new_proposal.update_qualification_matrix_item(_capture, id, qm);
      };
    } else if (msg instanceof $msg.UserAddedLanguage) {
      let l = msg.language;
      return (_capture) => { return $new_proposal.add_language(_capture, l); };
    } else if (msg instanceof $msg.UserRemovedLanguage) {
      let l = msg.language;
      return (_capture) => { return $new_proposal.remove_language(_capture, l); };
    } else {
      throw makeError(
        "panic",
        "update/new_proposal",
        131,
        "update_new_proposal_field",
        "Should be handled in outer update",
        {}
      )
    }
  })()(_pipe);
}

export function update(model, msg) {
  if (msg instanceof $msg.OnNewProposalSkip) {
    return [model, $effect.none()];
  } else if (msg instanceof $msg.ApiReturnedSavedProposal && msg[0].isOk()) {
    let proposal = msg[0][0][0];
    let questions = msg[0][0][1];
    let questions$1 = $list.map(questions, (q) => { return [q.id, q]; });
    let _pipe = $route.replace(
      new $route.Proposals(new $route.Show(proposal.id, new $option.None(), 0)),
    );
    return $function.flip($pair.new$)(
      _pipe,
      (() => {
        let proposals = $list.key_set(model.proposals, proposal.id, proposal);
        let by_proposal = $dict.insert(
          model.questions.by_proposal,
          proposal.id,
          questions$1,
        );
        let questions$2 = model.questions.withFields({ by_proposal: by_proposal });
        return model.withFields({ proposals: proposals, questions: questions$2 });
      })(),
    );
  } else if (msg instanceof $msg.ApiReturnedSavedProposal && !msg[0].isOk()) {
    let _pipe = "Unable to save the proposal. Please, retry later";
    let _pipe$1 = $effects.toast(_pipe, new $level.Error());
    return ((_capture) => { return $pair.new$(model, _capture); })(_pipe$1);
  } else if (msg instanceof $msg.OnNewProposalSpreadsheetSelector &&
  msg[0] instanceof $msg.UserSubmittedXlsx) {
    let loading = new $route.Proposals(new $route.Add(true));
    let _pipe = $model.update_route(model, loading);
    return $pair.new$(_pipe, $proposal_effects.send_new_proposal(model));
  } else if (msg instanceof $msg.OnNewProposalSpreadsheetSelector &&
  msg[0] instanceof $msg.UserCancelledSpreadsheetSelection) {
    let _pipe = model.new_proposal;
    let _pipe$1 = $new_proposal.back_to_upload(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_new_proposal(model, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.OnNewProposalSpreadsheetSelector) {
    let msg$1 = msg[0];
    let $ = model.new_proposal;
    let proposal_spreadsheet = $.proposal_spreadsheet;
    let _pipe = $spreadsheet_selector.update(model, proposal_spreadsheet, msg$1);
    let _pipe$1 = $pair.map_second(
      _pipe,
      (_capture) => {
        return $effect.map(
          _capture,
          (var0) => { return new $msg.OnNewProposalSpreadsheetSelector(var0); },
        );
      },
    );
    let _pipe$2 = $pair.map_second(
      _pipe$1,
      (_capture) => {
        return $effect.map(
          _capture,
          (var0) => { return new $msg.OnNewProposal(var0); },
        );
      },
    );
    return $pair.map_first(
      _pipe$2,
      (proposal_spreadsheet) => {
        let _pipe$3 = model.new_proposal.withFields({
          proposal_spreadsheet: proposal_spreadsheet
        });
        let _pipe$4 = (() => {
          let $1 = $spreadsheet_selector.is_xlsx_fetched(msg$1);
          if ($1) {
            return $new_proposal.next_step;
          } else {
            return $function.identity;
          }
        })()(_pipe$3);
        return ((_capture) => {
          return $model.set_new_proposal(model, _capture);
        })(_pipe$4);
      },
    );
  } else if (msg instanceof $msg.UserUsedQualificationMatrix) {
    let _pipe = model.qualification_matrix;
    let _pipe$1 = $list.map(
      _pipe,
      (_capture) => {
        return $pair.new$(_capture, new $qualification_matrix.NotSelected());
      },
    );
    let _pipe$2 = new $option.Some(_pipe$1);
    let _pipe$3 = ((q) => {
      return model.new_proposal.withFields({ qualification_matrix: q });
    })(_pipe$2);
    let _pipe$4 = ((_capture) => {
      return $model.set_new_proposal(model, _capture);
    })(_pipe$3);
    return $pair.new$(_pipe$4, $effect.none());
  } else if (msg instanceof $msg.UserConfirmedQualificationMatrix) {
    return $new_proposal.require_qualification_matrix(
      model.new_proposal,
      (qm) => {
        let $ = $proposal.count_qualification_matrix_answers(qm);
        let success = $[0];
        let failure = $[1];
        let total = $[2];
        let is_incomplete = (success + failure) !== total;
        return $bool.lazy_guard(
          is_incomplete,
          () => {
            let _pipe = $model.set_modal(
              model,
              new $model.IncompleteQualificationMatrix(),
            );
            return $pair.new$(_pipe, $effect.none());
          },
          () => {
            let _pipe = (() => {
              let $1 = success > failure;
              if (!$1) {
                return $model.set_modal(
                  model,
                  new $model.DangerousQualificationMatrix(),
                );
              } else {
                let _pipe = model.new_proposal;
                let _pipe$1 = $new_proposal.next_step(_pipe);
                return ((_capture) => {
                  return $model.set_new_proposal(model, _capture);
                })(_pipe$1);
              }
            })();
            return $pair.new$(_pipe, $effect.none());
          },
        );
      },
    );
  } else if (msg instanceof $msg.UserConfirmedModalQualificationMatrix) {
    let _pipe = model.new_proposal;
    let _pipe$1 = $new_proposal.next_step(_pipe);
    let _pipe$2 = ((_capture) => {
      return $model.set_new_proposal(model, _capture);
    })(_pipe$1);
    let _pipe$3 = $model.set_modal(_pipe$2, new $model.NoModal());
    return $pair.new$(_pipe$3, $effect.none());
  } else {
    let new_proposal = update_new_proposal_field(model, msg);
    let _pipe = model;
    let _pipe$1 = $model.set_new_proposal(_pipe, new_proposal);
    return $pair.new$(_pipe$1, $effect.none());
  }
}
