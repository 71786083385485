/// <reference types="./schedule.d.mts" />
import * as $effect from "../../../../lustre/lustre/effect.mjs";
import * as $ffi from "../../../grille_pain/internals/ffi.mjs";

export function schedule(duration, msg) {
  return $effect.from(
    (dispatch) => {
      return $ffi.set_timeout(duration, () => { return dispatch(msg); });
    },
  );
}
