/// <reference types="./arrow_left_line.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function arrow_left_line() {
  let content = "<path d=\"M10.828 12.1147L15.778 17.0647L14.364 18.4787L8 12.1147L14.364 5.75073L15.778 7.16473L10.828 12.1147Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 24 24"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
