/// <reference types="./bar_chart.d.mts" />
import * as $attribute from "../../lustre/lustre/attribute.mjs";
import * as $element from "../../lustre/lustre/element.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Dataset extends $CustomType {
  constructor(colors, data, labels) {
    super();
    this.colors = colors;
    this.data = data;
    this.labels = labels;
  }
}

export function bar_chart(datasets, orientation) {
  let datasets$1 = $attribute.property("datasets", datasets);
  return $element.element(
    "bar-chart",
    toList([
      $attribute.style(toList([["display", "block"]])),
      datasets$1,
      $attribute.property("orientation", orientation),
    ]),
    toList([]),
  );
}
