/// <reference types="./checkbox.d.mts" />
import * as $s from "../../../../sketch/sketch.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { px } from "../../../../sketch/sketch/size.mjs";
import * as $h from "../../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $colors from "../../../design_system/colors.mjs";
import { toList } from "../../../gleam.mjs";

export function label(attrs, children) {
  return $h.label_(attrs, children);
}

export function input(attrs) {
  let _pipe = $s.class$(
    toList([
      $s.visibility("hidden"),
      $s.width(px(0)),
      $s.height(px(0)),
      $s.position("fixed"),
      $s.top(px(-100_000)),
    ]),
  );
  return $h.input(_pipe, attrs);
}

export function checkbox(checked, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.width(px(20)),
      $s.height(px(20)),
      $s.transition("all .3s"),
      $s.background(
        (() => {
          if (checked) {
            return $colors.light.onyx;
          } else {
            return $colors.light.white;
          }
        })(),
      ),
      $s.border(
        "1px solid " + (() => {
          if (checked) {
            return $colors.light.onyx;
          } else {
            return $colors.light.grey_400;
          }
        })(),
      ),
      $s.border_radius(px(6)),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.cursor("pointer"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon(child) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.width(px(14)),
      $s.height(px(14)),
      $s.color($colors.light.white),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}
