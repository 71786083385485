import * as gleam from './gleam.mjs'

// Stateful module for WebSocket handling. It should be considered as a simili
// effect manager, while waiting for Lustre to implement proper effect managers.
// It acts a singleton, managing state for the WebSocket connection to the
// backend, and making sure to not clutter main Lustre's application to
// guarantee a correct user experience.
//
// WebSocket connection uses a extensive retry strategy, to reconnect to the
// backend anytimes connection drop. This strategy should be used in conjuction
// with another user data fetchinhg strategy to restore the initial state,
// because intermediate updates could have been lost during the downtime.

/** `dispatch` allows to send a message to the running Lustre application.
 * `dispatch` should be initialized through the Lustre's side, by using
 * `websocket.setup`. That function calls `storeDispatch`, which will be in
 * charge of updating `dispatch`.
 * @type {((data: object) => null) | null} */
let dispatch = null

/** Handle the socket connection. Socket is a singleton instance, and is
 * kept in the websocket file. It's currently impossible to maintain two
 * different sockets openend. In such cases, it would be better to create a
 * proper effect manager.
 * @type {WebSocket | null} */
let socket = null

/** Keep in memory whether the DOM has an active inputtable element.
 * Because when an element is being edited and a message is dispatched to the
 * runtime, the Lustre's view will be updated. This triggers a repaint, and
 * the user will lose focus. To avoid such a bad experience, a boolean is used
 * to indicate whether messages should be sent straight away to Lustre or should
 * be postponed until after edit is done.
 * @type {boolean} */
let isInputFocused = false

/** Keep in memory messages that could not be sent, because user is editing
 * something in the running application. `messages` behaves as FIFO queue
 * (first in, first out).
 * @type {object[]} */
let messages = []

/** Connect the WebSocket to `to`. An access token is required for
 * authentication purposes. In order to connect to the backend, `websocket.setup`
 * should have been called on Lustre's side. Once the connection has been
 * established, an auto-reconnection strategy is used, where the socket
 * automatically tries to reconnect every 5 seconds.
 * @type {(to: string, access_token: string) => gleam.Result} */
export function connect(to, access_token) {
  try {
    if (!dispatch) return new gleam.Error('setup has not been called')
    if (socket) return new gleam.Error('connect has already been called')
    socket = new WebSocket(to)
    socket.addEventListener('open', onOpen(access_token))
    socket.addEventListener('message', onMessage)
    socket.addEventListener('close', onClose(to, access_token))
    return new gleam.Ok(socket)
  } catch (error) {
    socket = null
    console.error(error)
    scheduleConnection(to, access_token)
    return new gleam.Error()
  }
}

/** Idempotent function. Close the sockets, and restore the initial state, but
 * keep the setup state. `disconnect` can be used to disconnect and reconnect
 * the socket as will, by keeping the setup as-is for later reuse. */
export function disconnect() {
  socket?.close()
  socket = null
  messages = []
}

/** Used internally in `websocket.setup`. Part of the effect managing, in order
 * to send messages back to Lustre. Should be properly abstracted in an
 * effect manager later.
 * @type {(dispatch: ((data: object) => null)) => void} */
export function storeDispatch(dispatch_) {
  dispatch = dispatch_
}

/** Used internally in `websocket` module. */
export function readDispatch() {
  if (dispatch === null) return new gleam.Error()
  return new gleam.Ok(dispatch)
}

/** `flushMessages` is called when user ends inputting. After an input or a
 * `content-editable` HTML element has lost focus, `flushMessages` will
 * automatically be called to send all pending messages to Lustre. */
function flushMessages() {
  isInputFocused = false
  const messages_ = messages
  messages = []
  if (dispatch) messages_.forEach(dispatch)
}

/** Assume responsibility to send message back to Lustre, or to skip sending
 * message and store it in pending queue. */
function sendMessage(data) {
  if (isInputFocused) {
    messages.push(data)
  } else if (dispatch) {
    dispatch(data)
  }
}

/** On opening, authenticate the user right away.
 * @type {(access_token: string) => (event: Event) => void} */
function onOpen(access_token) {
  return function (_event) {
    console.log('WebSocket opened, authenticating…')
    socket.send(JSON.stringify({ type: 'authenticate', access_token }))
  }
}

/** On message, socket will send back message to Lustre. On a regular basis
 * (a bunch of seconds), backend will query the socket to see if it's still
 * alive, in order to correctly maintain a list of sockets with a ping message.
 * `onMessage` takes care of answering to those ping messages, and will only
 * transmit useful messages.
 * @type {(event: Event) => void} */
function onMessage(event) {
  try {
    const data = JSON.parse(event.data)
    if (data.type === 'ping') return socket.send(JSON.stringify({ type: 'pong' }))
    sendMessage(data)
    console.log('Message received', event)
  } catch (e) {
    console.error(e)
  }
}

/** Close the socket when the server asked closed connection, and resets the
 * initial state. Because a closing socket can be due to a network error,
 * `onClose` will trigger a reconnection. Pending messages are preserved, and
 * will automatically be flushed when input is done.
 * @type {(to: string, access_token: string) => (event: Event) => void} */
function onClose(to, access_token) {
  return function (_event) {
    console.log('Server closed socket.')
    socket.close()
    socket = null
    scheduleConnection(to, access_token)
  }
}

/** Tries reconnection of the WebSocket. Because `connect` takes care to check
 * if socket is already connected or not, `scheduleConnection` can be called
 * many times. In the worst case, all it does is taking place in the event loop,
 * but will never create bug or runtime errors.
 * @type {(to: string, access_token: string) => void} */
function scheduleConnection(to, access_token) {
  console.log('Unable to connect, will retry connection in 5 seconds.')
  setTimeout(() => connect(to, access_token), 5000)
}

// Last pieces of initialization. Because of the nature of JS, every required
// modules are ran once and only once. This allows to run arbitrarily code at
// launch, and keeping the guarantee that code will only be run once.
// However, running code does not mean the browser is ready to paint
// and properly handle events. As such, it's common to wait for DOM to end
// loading. Once it's done, two handlers are registered to keep track of the
// inputs on the app.
document.addEventListener('DOMContentLoaded', _event => {
  document.addEventListener('focusout', _event => flushMessages())
  document.addEventListener('focusin', event => {
    const { target } = event
    if (!target) return
    const isInput = ['INPUT', 'TEXTAREA'].includes(target.tagName)
    const isContentEditable = target.contentEditable === 'true'
    if (isInput || isContentEditable) isInputFocused = true
  })
})
