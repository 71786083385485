/// <reference types="./translate.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import { attribute } from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $svg from "../../../lustre/lustre/element/svg.mjs";
import { toList } from "../../gleam.mjs";

export function translate() {
  return $html.svg(
    toList([
      attribute("xmlns", "http://www.w3.org/2000/svg"),
      attribute("fill", "none"),
      attribute("viewBox", "0 0 24 25"),
    ]),
    toList([
      $svg.path(
        toList([
          attribute("fill", "currentColor"),
          attribute(
            "d",
            "M5 15.6323V17.6323C4.99984 18.1369 5.19041 18.6229 5.5335 18.9929C5.87659 19.3629 6.34684 19.5895 6.85 19.6273L7 19.6323H10V21.6323H7C5.93913 21.6323 4.92172 21.2109 4.17157 20.4608C3.42143 19.7106 3 18.6932 3 17.6323V15.6323H5ZM18 10.6323L22.4 21.6323H20.245L19.044 18.6323H14.954L13.755 21.6323H11.601L16 10.6323H18ZM17 13.5173L15.753 16.6323H18.245L17 13.5173ZM8 2.63232V4.63232H12V11.6323H8V14.6323H6V11.6323H2V4.63232H6V2.63232H8ZM17 3.63232C18.0609 3.63232 19.0783 4.05375 19.8284 4.8039C20.5786 5.55404 21 6.57146 21 7.63232V9.63232H19V7.63232C19 7.10189 18.7893 6.59318 18.4142 6.21811C18.0391 5.84304 17.5304 5.63232 17 5.63232H14V3.63232H17ZM6 6.63232H4V9.63232H6V6.63232ZM10 6.63232H8V9.63232H10V6.63232Z",
          ),
        ]),
      ),
    ]),
  );
}
