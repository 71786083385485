/// <reference types="./element.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $sketch from "../../../sketch/sketch.mjs";
import { prepend as listPrepend } from "../../gleam.mjs";
import * as $magic from "../../sketch/magic.mjs";

export function element(tag, class$, attributes, children) {
  let class_name = $magic.class_name(class$);
  return $el.element(
    tag,
    listPrepend($attribute.class$(class_name), attributes),
    children,
  );
}

export function element_(tag, attributes, children) {
  return $el.element(tag, attributes, children);
}

export function namespaced(tag, namespace, class$, attributes, children) {
  let class_name = $magic.class_name(class$);
  let attributes$1 = listPrepend($attribute.class$(class_name), attributes);
  return $el.namespaced(tag, namespace, attributes$1, children);
}

export function namespaced_(tag, namespace, attributes, children) {
  return $el.namespaced(tag, namespace, attributes, children);
}

export const keyed = $el.keyed;

export const fragment = $el.fragment;

export const none = $el.none;

export const text = $el.text;

export const map = $el.map;
