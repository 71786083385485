import * as analytics from '@vercel/analytics'
import * as speed from '@vercel/speed-insights'
import { BarChart } from './elements/bar-chart'
import { PieChart } from './elements/pie-chart'
// import { Tooltip } from './elements/tooltip'
import { main } from './frontend.gleam'

// Setup Vercel toolings.
if (import.meta.env.PROD) {
  analytics.inject({ mode: 'production' })
  speed.injectSpeedInsights()
}

// Register the custom elements upfront.
PieChart.register()
BarChart.register()

// Start the Lustre app.
document.addEventListener('DOMContentLoaded', main)
