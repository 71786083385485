/// <reference types="./spreadsheet_selector.d.mts" />
import * as $spreadsheet from "../../../common/data/spreadsheet.mjs";
import {
  AnswerColumn,
  MultipleChoiceColumn,
  QuestionColumn,
  QuestionRow,
  SectionRow,
  UndefinedColumn,
  UndefinedRow,
  YesNoColumn,
} from "../../../common/data/spreadsheet.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $sketch from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import * as $magic from "../../../sketch_magic/sketch/magic.mjs";
import * as $el from "../../../sketch_magic/sketch/magic/element.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $spreadsheet_selector from "../../data/ui/spreadsheet_selector.mjs";
import * as $colors from "../../design_system/colors.mjs";
import * as $button from "../../design_system/components/button.mjs";
import * as $dropdown from "../../design_system/components/dropdown.mjs";
import * as $layout from "../../design_system/components/layout.mjs";
import * as $icons from "../../design_system/icons.mjs";
import * as $outline from "../../design_system/outline.mjs";
import { fromCharcode as from_charcode } from "../../frontend.ffi.mjs";
import { Ok, Error, toList, prepend as listPrepend, isEqual } from "../../gleam.mjs";
import * as $dropdown_styles from "../../view/styles/dropdowns.mjs";
import * as $s from "../../view/styles/proposals.mjs";

function get_displayed_sheet(worksheets, selected_sheet) {
  let select = (_capture) => {
    return $result.map(_capture, (w) => { return w.content; });
  };
  return $result.try_recover(
    (() => {
      let _pipe = $list.find(
        worksheets,
        (w) => { return w.name === selected_sheet; },
      );
      return select(_pipe);
    })(),
    (_) => {
      let _pipe = $list.first(worksheets);
      return select(_pipe);
    },
  );
}

function invalid_sheet() {
  return $el.text("Invalid sheet");
}

function column_type_is_present(selected_sheet, columns_type, column_type) {
  let _pipe = $list.key_find(columns_type, selected_sheet);
  let _pipe$1 = $result.unwrap(_pipe, $dict.new$());
  let _pipe$2 = $dict.values(_pipe$1);
  return $list.contains(_pipe$2, column_type);
}

function is_sheet_valid(selected_sheet, columns_type) {
  return column_type_is_present(
    selected_sheet,
    columns_type,
    new QuestionColumn(),
  ) && ((column_type_is_present(
    selected_sheet,
    columns_type,
    new AnswerColumn(),
  ) || column_type_is_present(selected_sheet, columns_type, new YesNoColumn())) || column_type_is_present(
    selected_sheet,
    columns_type,
    new MultipleChoiceColumn(),
  ));
}

function is_first_sheet(worksheets, selected_sheet) {
  let _pipe = worksheets;
  let _pipe$1 = $list.first(_pipe);
  let _pipe$2 = $result.map(_pipe$1, (s) => { return s.name; });
  return ((s) => { return isEqual(s, new Ok(selected_sheet)); })(_pipe$2);
}

function is_last_sheet(worksheets, selected_sheet) {
  let _pipe = $list.reverse(worksheets);
  return is_first_sheet(_pipe, selected_sheet);
}

function maybe_button(displayed, button_type, attributes, content) {
  if (displayed) {
    return button_type(attributes, content);
  } else {
    return $el.none();
  }
}

function action_buttons(
  is_first_sheet,
  is_last_sheet,
  submit_enabled,
  next_enabled,
  skip_enabled,
  current_sheet,
  next_sheet,
  previous_sheet,
  cta_text
) {
  let text_class = $sketch.class$(
    toList([
      $sketch.hover(
        toList([
          (() => {
            let _pipe = $sketch.margin_right($size.px(0));
            return $sketch.important(_pipe);
          })(),
          $sketch.margin_left($size.px(-6)),
        ]),
      ),
    ]),
  );
  let update_selected_sheet = $e.on_click(
    new $msg.UserUpdatedSelectedSheet(next_sheet),
  );
  let skip_selected_sheet = $e.on_click(
    new $msg.UserSkippedSelectedSheet(current_sheet, next_sheet),
  );
  let single_page_sheet = is_first_sheet && is_last_sheet;
  let back_clicked = (() => {
    if (is_first_sheet) {
      return $e.on_click(new $msg.UserCancelledSpreadsheetSelection());
    } else {
      return $e.on_click(new $msg.UserUpdatedSelectedSheet(previous_sheet));
    }
  })();
  return $layout.row(
    toList([$layout.gap(16), $layout.justify("end")]),
    toList([]),
    toList([
      $button.text(
        toList([$a.class$($magic.class_name(text_class)), back_clicked]),
        toList([$button.icon($icons.left_arrow()), $h.text("Back")]),
      ),
      maybe_button(
        !single_page_sheet,
        $button.secondary,
        toList([$a.disabled(!skip_enabled), skip_selected_sheet]),
        toList([
          $h.text("Skip sheet"),
          (() => {
            if (!is_last_sheet) {
              return $button.icon($icons.right_arrow());
            } else {
              return $el.none();
            }
          })(),
        ]),
      ),
      maybe_button(
        !is_last_sheet,
        $button.primary,
        toList([$a.disabled(!next_enabled), update_selected_sheet]),
        toList([$h.text("Next sheet"), $button.icon($icons.right_arrow())]),
      ),
      maybe_button(
        is_last_sheet,
        $button.primary,
        toList([
          $a.disabled(!submit_enabled),
          $e.on_click(new $msg.UserSubmittedXlsx()),
        ]),
        toList([$h.text(cta_text)]),
      ),
    ]),
  );
}

function get_sheet_size(sheet) {
  return $list.fold(
    sheet,
    0,
    (size, item) => { return $int.max(size, $list.length(item)); },
  );
}

function select_border(border, content) {
  return $bool.lazy_guard(
    content !== 0,
    $a.none,
    () => {
      return $a.style(toList([[border, "1px solid " + $colors.light.grey_200]]));
    },
  );
}

function spreadsheet_headers(size, sheet, columns_type, display_only_qna) {
  return listPrepend(
    $h.div_(toList([]), toList([])),
    (() => {
      let _pipe = $list.range(0, size - 1);
      return $list.index_map(
        _pipe,
        (content, index) => {
          let style = select_border("border-left", content);
          let selected = $dict.get(columns_type, index);
          return $s.spreadsheet_header(
            toList([style]),
            toList([
              $h.div_(
                toList([]),
                toList([
                  $dropdown.dropdown(
                    toList([
                      $dropdown.show_arrow(),
                      $dropdown.panel_class($dropdown_styles.spacing()),
                      $dropdown.on_selected(
                        (_capture) => {
                          return new $msg.UserChangedColumnType(
                            sheet,
                            index,
                            _capture,
                          );
                        },
                      ),
                      $dropdown.placeholder("What is it?"),
                      (() => {
                        if (!selected.isOk()) {
                          return $dropdown.selected(new $option.None());
                        } else {
                          let type_ = selected[0];
                          return $dropdown.selected(new $option.Some(type_));
                        }
                      })(),
                    ]),
                    toList([
                      (() => {
                        let _pipe$1 = $dropdown.choice(
                          "Question",
                          new QuestionColumn(),
                        );
                        return $dropdown.with_class(
                          _pipe$1,
                          $dropdown_styles.current(),
                        );
                      })(),
                      (() => {
                        let _pipe$1 = $dropdown.choice(
                          "Answer",
                          new AnswerColumn(),
                        );
                        return $dropdown.with_class(
                          _pipe$1,
                          $dropdown_styles.success(),
                        );
                      })(),
                      (() => {
                        if (display_only_qna) {
                          return $dropdown.no_choice();
                        } else {
                          let _pipe$1 = $dropdown.choice(
                            "Yes / No answers",
                            new YesNoColumn(),
                          );
                          return $dropdown.with_class(
                            _pipe$1,
                            $dropdown_styles.success(),
                          );
                        }
                      })(),
                      (() => {
                        if (display_only_qna) {
                          return $dropdown.no_choice();
                        } else {
                          let _pipe$1 = $dropdown.choice(
                            "Multiple choice",
                            new MultipleChoiceColumn(),
                          );
                          return $dropdown.with_class(
                            _pipe$1,
                            $dropdown_styles.success(),
                          );
                        }
                      })(),
                      (() => {
                        let _pipe$1 = $dropdown.choice(
                          "Empty",
                          new UndefinedColumn(),
                        );
                        return $dropdown.with_class(
                          _pipe$1,
                          $dropdown_styles.neutral(),
                        );
                      })(),
                    ]),
                  ),
                ]),
              ),
              $h.div_(
                toList([]),
                toList([$h.text(from_charcode(65 + content))]),
              ),
            ]),
          );
        },
      );
    })(),
  );
}

function find_next_sheet(loop$worksheets, loop$selected_sheet) {
  while (true) {
    let worksheets = loop$worksheets;
    let selected_sheet = loop$selected_sheet;
    if (worksheets.atLeastLength(2) && (worksheets.head.name === selected_sheet)) {
      let sheet = worksheets.head;
      let next = worksheets.tail.head;
      return next.name;
    } else if (worksheets.atLeastLength(1)) {
      let sheets = worksheets.tail;
      loop$worksheets = sheets;
      loop$selected_sheet = selected_sheet;
    } else {
      return "";
    }
  }
}

function find_previous_sheet(worksheets, selected_sheet) {
  let _pipe = worksheets;
  let _pipe$1 = $list.reverse(_pipe);
  return find_next_sheet(_pipe$1, selected_sheet);
}

function spreadsheet_line_header(
  y,
  max,
  sheet,
  invalid_lines,
  rows_dropdowns_enabled
) {
  let style = select_border("border-top", y);
  let selected = (() => {
    let _pipe = $dict.get(invalid_lines, y);
    return $result.unwrap(_pipe, new UndefinedRow());
  })();
  return $s.spreadsheet_header_line(
    y,
    max,
    toList([style]),
    toList([
      $dropdown.dropdown(
        toList([
          $dropdown.show_arrow(),
          $dropdown.selected(new $option.Some(selected)),
          $dropdown.on_selected(
            (_capture) => {
              return new $msg.UserChangedInvalidLine(sheet, y, _capture);
            },
          ),
          $dropdown.panel_class($dropdown_styles.spacing()),
          $dropdown.input_class(
            $sketch.class$(
              toList([
                $sketch.compose(
                  (() => {
                    if (selected instanceof QuestionRow) {
                      return $dropdown_styles.bordered_success();
                    } else if (selected instanceof SectionRow) {
                      return $dropdown_styles.bordered_current();
                    } else {
                      return $dropdown_styles.bordered_neutral();
                    }
                  })(),
                ),
                $sketch.opacity(
                  (() => {
                    if (rows_dropdowns_enabled) {
                      return 1.0;
                    } else {
                      return 0.4;
                    }
                  })(),
                ),
              ]),
            ),
          ),
          $dropdown.disabled(!rows_dropdowns_enabled),
        ]),
        toList([
          (() => {
            let _pipe = $dropdown.choice("Question", new QuestionRow());
            return $dropdown.with_class(_pipe, $dropdown_styles.success());
          })(),
          (() => {
            let _pipe = $dropdown.choice("Section", new SectionRow());
            return $dropdown.with_class(_pipe, $dropdown_styles.current());
          })(),
          (() => {
            let _pipe = $dropdown.choice("", new UndefinedRow());
            let _pipe$1 = $dropdown.with_icon(_pipe, $outline.line());
            return $dropdown.with_class(_pipe$1, $dropdown_styles.neutral());
          })(),
        ]),
      ),
      $h.text($int.to_string(y)),
    ]),
  );
}

function view_spreadsheet(
  sheet,
  selected_sheet,
  spreadsheet_sel,
  worksheets,
  cta_text,
  display_only_qna
) {
  let columns_type = spreadsheet_sel.columns_type;
  let skip_last_sheet = spreadsheet_sel.skip_last_sheet;
  let size = get_sheet_size(sheet);
  let sheet_length = $list.length(sheet);
  let first_instruction = "Choose the column that contains the questions. If you don’t need to fill a particular sheet, you may skip it.";
  let second_instruction = "We just auto-detected questions and sections using our AI. Please confirm the rows and choose the columns where you want the answer.";
  let rows_dropdowns_enabled = column_type_is_present(
    selected_sheet,
    columns_type,
    new QuestionColumn(),
  );
  let next_enabled = is_sheet_valid(selected_sheet, columns_type);
  let is_last_sheet$1 = is_last_sheet(worksheets, selected_sheet);
  let current_instruction = (() => {
    if (!rows_dropdowns_enabled) {
      return first_instruction;
    } else {
      return second_instruction;
    }
  })();
  return $bool.lazy_guard(
    size === 0,
    $el.none,
    () => {
      return $el.fragment(
        toList([
          $s.explanatory_text(
            toList([]),
            toList([$el.text(current_instruction)]),
          ),
          $s.spreadsheet_wrapper(
            toList([]),
            toList([
              $s.spreadsheet(
                size + 1,
                toList([]),
                toList([
                  $el.fragment(
                    spreadsheet_headers(
                      size,
                      selected_sheet,
                      (() => {
                        let _pipe = columns_type;
                        let _pipe$1 = $list.key_find(_pipe, selected_sheet);
                        return $result.unwrap(_pipe$1, $dict.new$());
                      })(),
                      display_only_qna,
                    ),
                  ),
                  $el.fragment(
                    $list.flatten(
                      $list.index_map(
                        sheet,
                        (row, y) => {
                          let _pipe = spreadsheet_sel.invalid_lines;
                          let _pipe$1 = $list.key_find(_pipe, selected_sheet);
                          let _pipe$2 = $result.unwrap(_pipe$1, $dict.new$());
                          let _pipe$3 = ((_capture) => {
                            return spreadsheet_line_header(
                              y,
                              sheet_length,
                              selected_sheet,
                              _capture,
                              rows_dropdowns_enabled,
                            );
                          })(_pipe$2);
                          return $function.flip($list.prepend)(
                            _pipe$3,
                            $list.map(
                              row,
                              (col) => {
                                let $ = col.value;
                                if ($ instanceof $option.None) {
                                  return $s.empty_cell(toList([]));
                                } else {
                                  let content = $[0];
                                  return $s.filled_cell(
                                    toList([]),
                                    toList([$el.text(content)]),
                                  );
                                }
                              },
                            ),
                          );
                        },
                      ),
                    ),
                  ),
                ]),
              ),
              $s.spreadsheet_sheets(
                toList([]),
                $list.map(
                  worksheets,
                  (sheet) => {
                    let on_click = $e.on_click(
                      new $msg.UserUpdatedSelectedSheet(sheet.name),
                    );
                    let $ = selected_sheet === sheet.name;
                    if ($) {
                      return $s.active_spreadsheet_sheet(
                        toList([on_click]),
                        toList([$el.text(sheet.name)]),
                      );
                    } else {
                      return $s.spreadsheet_sheet(
                        toList([on_click]),
                        toList([$el.text(sheet.name)]),
                      );
                    }
                  },
                ),
              ),
            ]),
          ),
          action_buttons(
            is_first_sheet(worksheets, selected_sheet),
            is_last_sheet$1,
            $bool.guard(
              $list.is_empty(columns_type),
              false,
              () => {
                let number_of_valid_sheets = $list.length(
                  $list.filter_map(
                    columns_type,
                    (_use0) => {
                      let sheet$1 = _use0[0];
                      let $ = is_sheet_valid(sheet$1, columns_type);
                      if ($) {
                        return new Ok(undefined);
                      } else {
                        return new Error(undefined);
                      }
                    },
                  ),
                );
                return (number_of_valid_sheets > 0) && (skip_last_sheet || is_sheet_valid(
                  selected_sheet,
                  columns_type,
                ));
              },
            ),
            next_enabled,
            !(is_last_sheet$1 && skip_last_sheet) && !is_sheet_valid(
              selected_sheet,
              columns_type,
            ),
            selected_sheet,
            find_next_sheet(worksheets, selected_sheet),
            find_previous_sheet(worksheets, selected_sheet),
            cta_text,
          ),
        ]),
      );
    },
  );
}

export function select_columns(spreadsheet_sel, cta_text, display_only_qna) {
  let $ = spreadsheet_sel.xlsx;
  let $1 = spreadsheet_sel.selected_sheet;
  if ($ instanceof $option.None) {
    return $el.none();
  } else if ($1 instanceof $option.None) {
    return $el.none();
  } else {
    let spreadsheet = $[0];
    let selected_sheet = $1[0];
    let worksheets = spreadsheet.data;
    let sheet = get_displayed_sheet(worksheets, selected_sheet);
    let _pipe = sheet;
    let _pipe$1 = $result.replace_error(_pipe, invalid_sheet());
    let _pipe$2 = $result.map(
      _pipe$1,
      (_capture) => {
        return view_spreadsheet(
          _capture,
          selected_sheet,
          spreadsheet_sel,
          worksheets,
          cta_text,
          display_only_qna,
        );
      },
    );
    return $result.unwrap_both(_pipe$2);
  }
}
