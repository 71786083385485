/// <reference types="./spreadsheet_selector.d.mts" />
import * as $spreadsheet from "../../../common/data/spreadsheet.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $file from "../../../plinth/plinth/browser/file.mjs";
import { toList, CustomType as $CustomType, isEqual } from "../../gleam.mjs";

export class SpreadsheetSelector extends $CustomType {
  constructor(selected_sheet, columns_type, invalid_lines, xlsx, file, skip_last_sheet) {
    super();
    this.selected_sheet = selected_sheet;
    this.columns_type = columns_type;
    this.invalid_lines = invalid_lines;
    this.xlsx = xlsx;
    this.file = file;
    this.skip_last_sheet = skip_last_sheet;
  }
}

export function new$() {
  return new SpreadsheetSelector(
    new $option.None(),
    toList([]),
    toList([]),
    new $option.None(),
    new $option.None(),
    false,
  );
}

export function update_xlsx(new_proposal, xlsx) {
  return new_proposal.withFields({ xlsx: new $option.Some(xlsx) });
}

export function update_selected_sheet(new_proposal, name) {
  return new_proposal.withFields({ selected_sheet: new $option.Some(name) });
}

export function reset_columns_type(new_proposal, sheet) {
  let columns_type = (() => {
    let _pipe = new_proposal.columns_type;
    return $list.key_set(_pipe, sheet, $dict.new$());
  })();
  return new_proposal.withFields({ columns_type: columns_type });
}

export function reset_invalid_lines(new_proposal, sheet) {
  let invalid_lines = (() => {
    let _pipe = new_proposal.invalid_lines;
    return $list.key_set(_pipe, sheet, $dict.new$());
  })();
  return new_proposal.withFields({ invalid_lines: invalid_lines });
}

export function skip_last_sheet(new_proposal) {
  return new_proposal.withFields({ skip_last_sheet: true });
}

export function skip_selected_sheet(new_proposal, current_sheet, next_sheet) {
  let _pipe = new_proposal;
  let _pipe$1 = reset_columns_type(_pipe, current_sheet);
  let _pipe$2 = reset_invalid_lines(_pipe$1, current_sheet);
  return (() => {
    if (next_sheet === "") {
      return skip_last_sheet;
    } else {
      return (_capture) => {
        return update_selected_sheet(_capture, next_sheet);
      };
    }
  })()(_pipe$2);
}

export function update_column_type(new_proposal, sheet, index, value) {
  let columns_type = (() => {
    let _pipe = new_proposal.columns_type;
    let _pipe$1 = $list.key_find(_pipe, sheet);
    let _pipe$2 = $result.unwrap(_pipe$1, $dict.new$());
    let _pipe$3 = $dict.fold(
      _pipe$2,
      $dict.new$(),
      (d, k, v) => {
        return $bool.guard(
          isEqual(v, value),
          d,
          () => { return $dict.insert(d, k, v); },
        );
      },
    );
    let _pipe$4 = $dict.insert(_pipe$3, index, value);
    return ((_capture) => {
      return $list.key_set(new_proposal.columns_type, sheet, _capture);
    })(_pipe$4);
  })();
  return new_proposal.withFields({ columns_type: columns_type });
}

export function update_invalid_line(new_proposal, sheet, index, value) {
  let invalid_lines = (() => {
    let _pipe = new_proposal.invalid_lines;
    let _pipe$1 = $list.key_find(_pipe, sheet);
    let _pipe$2 = $result.unwrap(_pipe$1, $dict.new$());
    let _pipe$3 = $dict.insert(_pipe$2, index, value);
    return ((_capture) => {
      return $list.key_set(new_proposal.invalid_lines, sheet, _capture);
    })(_pipe$3);
  })();
  return new_proposal.withFields({ invalid_lines: invalid_lines });
}
