/// <reference types="./setup.d.mts" />
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $magic from "../../sketch_magic/sketch/magic.mjs";
import * as $dropdown from "../design_system/components/dropdown.mjs";
import * as $portal from "../design_system/components/portal.mjs";
import * as $tooltip from "../design_system/components/tooltip.mjs";
import { Ok } from "../gleam.mjs";

export function register() {
  let stylesheet = $magic.document();
  return $result.try$(
    $dropdown.register(stylesheet),
    (_) => {
      return $result.try$(
        $tooltip.register(stylesheet),
        (_) => {
          return $result.try$(
            $portal.register(),
            (_) => { return new Ok(undefined); },
          );
        },
      );
    },
  );
}
