/// <reference types="./arrow_right_line.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function arrow_right_line() {
  let content = "<path d=\"M13.172 12.1147L8.22198 7.16473L9.63598 5.75073L16 12.1147L9.63598 18.4787L8.22198 17.0647L13.172 12.1147Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 24 25"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
