export function newFormData() {
  return new FormData()
}

function clone(formData) {
  const f = new FormData()
  for (const [key, value] of formData.entries()) {
    f.append(key, value)
  }
  return f
}

export function append(formData, key, value) {
  const f = clone(formData)
  f.append(key, value)
  return f
}

export function set(formData, key, value) {
  const f = clone(formData)
  f.set(key, value)
  return f
}

export function appendBits(formData, key, value) {
  const f = clone(formData)
  f.append(key, new Blob([value.buffer]))
  return f
}

export function setBits(formData, key, value) {
  const f = clone(formData)
  f.set(key, new Blob([value.buffer]))
  return f
}

export function toFormData(json, blob) {
  const fd = new FormData()
  fd.append('content', json)
  fd.append('blob', new Blob([blob.buffer]))
  return fd
}
