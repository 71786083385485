/// <reference types="./question_bank.d.mts" />
import * as $birl from "../../../birl/birl.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $order from "../../../gleam_stdlib/gleam/order.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $magic from "../../../sketch_magic/sketch/magic.mjs";
import * as $content_library from "../../data/content_library.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $button from "../../design_system/components/button.mjs";
import * as $checkbox from "../../design_system/components/checkbox.mjs";
import * as $input from "../../design_system/components/input.mjs";
import * as $label from "../../design_system/components/label.mjs";
import * as $layout from "../../design_system/components/layout.mjs";
import * as $pp from "../../design_system/components/profile_picture.mjs";
import * as $skeleton from "../../design_system/components/skeleton.mjs";
import * as $table from "../../design_system/components/table.mjs";
import * as $icons from "../../design_system/icons.mjs";
import { toList, prepend as listPrepend, makeError, isEqual } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";
import * as $common from "../../view/common.mjs";
import * as $cl_common from "../../view/content_library/common.mjs";
import * as $s from "../../view/styles/content_library.mjs";

function state_card(color, count, status, active) {
  let content = (() => {
    if (status instanceof $data_source.Verified) {
      return "Verified";
    } else if (status instanceof $data_source.NotVerified) {
      return "Not verified";
    } else if (status instanceof $data_source.Pending) {
      return "Pending";
    } else if (status instanceof $data_source.Outdated) {
      return "Outdated";
    } else {
      return "??";
    }
  })();
  let handler = $e.on_click(
    new $msg.ContentLibrary(new $msg.UserSelectedFilter(status)),
  );
  return $s.filter_card(
    $cl_common.choose_settings(color, active),
    toList([handler]),
    toList([
      $s.filter_card_title(toList([]), toList([$h.text($int.to_string(count))])),
      $s.filter_card_subtitle(toList([]), toList([$h.text(content)])),
    ]),
  );
}

function content_library_filters(model) {
  return $layout.row(
    toList([$layout.gap(32), $layout.justify("space-between")]),
    toList([]),
    toList([
      $layout.row(
        toList([$layout.gap(16), $layout.align("center")]),
        toList([]),
        toList([
          $input.input(
            toList([
              $input.placeholder("Search"),
              $input.icon($icons.magnifying_glass()),
              $input.value(model.content_library.search),
              $input.on_input(
                (var0) => { return new $msg.UserUpdatedSearch(var0); },
              ),
            ]),
          ),
        ]),
      ),
      $layout.row(
        toList([$layout.gap(32)]),
        toList([]),
        toList([
          $h.label(
            toList([]),
            toList([
              $h.div(
                toList([
                  (() => {
                    let _pipe = $button.button_secondary();
                    let _pipe$1 = $magic.class_name(_pipe);
                    return $a.class$(_pipe$1);
                  })(),
                ]),
                toList([
                  $button.icon($icons.upload()),
                  $h.text("Upload from Excel"),
                ]),
              ),
              $h.input(
                toList([
                  $a.type_("file"),
                  $a.style(toList([["display", "none"]])),
                  $utils.on_files_change(
                    (files) => {
                      let $ = $list.first(files);
                      if (!$.isOk()) {
                        throw makeError(
                          "let_assert",
                          "view/content_library/question_bank",
                          122,
                          "",
                          "Pattern match failed, no pattern matched the value.",
                          { value: $ }
                        )
                      }
                      let file = $[0];
                      let _pipe = new $msg.UserSelectedXlsx(file);
                      return new $msg.ContentLibraryHandledSpreadsheetSelector(
                        _pipe,
                      );
                    },
                  ),
                  $utils.restrict_xlsx(),
                ]),
              ),
            ]),
          ),
          $button.primary(
            toList([$e.on_click(new $msg.UserCreatedQuestion())]),
            toList([$button.icon($icons.add()), $h.text("Add a new question")]),
          ),
        ]),
      ),
    ]),
  );
}

function view_loading_table() {
  let _pipe = $table.row(
    listPrepend(
      $table.cell($el.none()),
      (() => {
        let _pipe = $table.cell($skeleton.skeleton(100, 25));
        return $list.repeat(_pipe, 6);
      })(),
    ),
  );
  let _pipe$1 = $list.repeat(_pipe, 5);
  return $table.body(_pipe$1);
}

function content_library_table_headers() {
  return $table.headers(
    toList([
      (() => {
        let _pipe = $table.header("");
        return $table.fit(_pipe);
      })(),
      (() => {
        let _pipe = $table.header("Question");
        return $table.clamp(_pipe, "20%", 500);
      })(),
      (() => {
        let _pipe = $table.header("Answer");
        return $table.clamp(_pipe, "30%", 500);
      })(),
      (() => {
        let _pipe = $table.header("Status");
        return $table.fit(_pipe);
      })(),
      $table.header("Owner"),
      (() => {
        let _pipe = $table.header("Expiration Date");
        return $table.fit(_pipe);
      })(),
      $table.header("Tags"),
    ]),
  );
}

function view_table_body(model) {
  let sources = model.content_library.qna_sources;
  return $table.body(
    $list.map(
      (() => {
        let _pipe = (() => {
          let $ = model.content_library.filter_selected;
          if ($ instanceof $option.Some && $[0] instanceof $data_source.Verified) {
            return sources.verified;
          } else if ($ instanceof $option.Some &&
          $[0] instanceof $data_source.Pending) {
            return sources.pending;
          } else if ($ instanceof $option.Some &&
          $[0] instanceof $data_source.NotVerified) {
            return sources.not_verified;
          } else if ($ instanceof $option.Some &&
          $[0] instanceof $data_source.Outdated) {
            return sources.outdated;
          } else {
            return sources.all;
          }
        })();
        return (() => {
          let $ = model.content_library.search;
          if ($ === "") {
            return $function.identity;
          } else {
            return (_capture) => {
              return $list.filter(
                _capture,
                (s) => {
                  return $string.contains(
                    s.display.name,
                    model.content_library.search,
                  );
                },
              );
            };
          }
        })()(_pipe);
      })(),
      (source) => {
        let user = $list.find(
          model.users,
          (user) => { return isEqual(new $option.Some(user.id), source.owner); },
        );
        let _pipe = $table.row(
          toList([
            (() => {
              let _pipe = $table.cell(
                $checkbox.checkbox(
                  $checkbox.checked(
                    $content_library.is_selected(
                      model.content_library,
                      source.id,
                    ),
                  ),
                  (checked) => {
                    return new $msg.ContentLibrary(
                      new $msg.UserSelectedSource(source.id, checked),
                    );
                  },
                ),
              );
              return $table.stop_cell_propagation(_pipe);
            })(),
            $table.cell(
              $el.text(
                (() => {
                  let _pipe = source.display.qna;
                  let _pipe$1 = $option.map(
                    _pipe,
                    (q) => { return q.question; },
                  );
                  return $option.unwrap(_pipe$1, "");
                })(),
              ),
            ),
            $table.cell(
              $el.text(
                (() => {
                  let _pipe = source.display.qna;
                  let _pipe$1 = $option.map(_pipe, (q) => { return q.answer; });
                  return $option.unwrap(_pipe$1, "");
                })(),
              ),
            ),
            $table.cell(
              (() => {
                let $ = (() => {
                  let $1 = source.status;
                  if ($1 instanceof $data_source.Pending) {
                    return [new $label.Current(), "Pending"];
                  } else if ($1 instanceof $data_source.NotVerified) {
                    return [new $label.Neutral(), "Not verified"];
                  } else if ($1 instanceof $data_source.Verified) {
                    return [new $label.Success(), "Verified"];
                  } else if ($1 instanceof $data_source.Outdated) {
                    return [new $label.Error(), "Outdated"];
                  } else {
                    return [new $label.Error(), "Archived"];
                  }
                })();
                let status = $[0];
                let content = $[1];
                return $label.label(status, content);
              })(),
            ),
            $table.cell(
              $common.name_small(
                toList([]),
                (() => {
                  if (!user.isOk()) {
                    return toList([$el.text("")]);
                  } else {
                    let name = user[0].name;
                    let picture = user[0].picture;
                    let initials = user[0].initials;
                    return toList([
                      $pp.from_user(picture, initials, new $pp.Medium()),
                      $el.text(name),
                    ]);
                  }
                })(),
              ),
            ),
            $table.cell(
              (() => {
                let $ = (() => {
                  let $1 = source.expiration_date;
                  if ($1 instanceof $option.None) {
                    return [new $label.Neutral(), "None"];
                  } else {
                    let expiration_date = $1[0];
                    let _pipe = (() => {
                      let $2 = $birl.compare(expiration_date, $birl.utc_now());
                      if ($2 instanceof $order.Gt) {
                        return new $label.Success();
                      } else if ($2 instanceof $order.Eq) {
                        return new $label.Success();
                      } else {
                        return new $label.Error();
                      }
                    })();
                    return $pair.new$(
                      _pipe,
                      $utils.time_to_qualified_day(expiration_date, "/"),
                    );
                  }
                })();
                let status = $[0];
                let content = $[1];
                return $label.label(status, content);
              })(),
            ),
            $table.cell(
              $layout.row(
                toList([$layout.gap(8)]),
                toList([]),
                $list.filter_map(
                  source.tags,
                  (tag) => {
                    let tag$1 = $list.find(
                      model.tags,
                      (t) => { return t.id === tag; },
                    );
                    return $result.map(
                      tag$1,
                      (tag) => {
                        return $label.label(new $label.Neutral(), tag.name);
                      },
                    );
                  },
                ),
              ),
            ),
          ]),
        );
        return $table.on_click(
          _pipe,
          new $msg.ContentLibrary(new $msg.UserEdittedQuestion(source.id)),
        );
      },
    ),
  );
}

const empty_text = "Connect your Drive, or upload directly files, directly with the button above.";

function empty_cell() {
  return $s.empty_cell($s.empty_text_content($h.text(empty_text)));
}

function view_empty_table() {
  return $table.body(
    toList([
      (() => {
        let _pipe = $table.row(
          toList([
            (() => {
              let _pipe = $table.cell(empty_cell());
              return $table.span(_pipe, 7);
            })(),
          ]),
        );
        return $table.hover(_pipe, false);
      })(),
    ]),
  );
}

function content_library_table(model) {
  return $table.table(
    toList([
      content_library_table_headers(),
      (() => {
        let $ = model.loading.content_library;
        let $1 = $list.is_empty(model.content_library.qna_sources.all);
        if ($) {
          return view_loading_table();
        } else if ($1) {
          return view_empty_table();
        } else {
          return view_table_body(model);
        }
      })(),
    ]),
  );
}

export function view(model) {
  return $el.fragment(
    toList([
      $layout.row(
        toList([$layout.gap(32)]),
        toList([]),
        toList([
          state_card(
            "green",
            model.content_library.qna_status.verified,
            new $data_source.Verified(),
            isEqual(
              new $option.Some(new $data_source.Verified()),
              model.content_library.filter_selected
            ),
          ),
          state_card(
            "blue",
            model.content_library.qna_status.pending,
            new $data_source.Pending(),
            isEqual(
              new $option.Some(new $data_source.Pending()),
              model.content_library.filter_selected
            ),
          ),
          state_card(
            "grey",
            model.content_library.qna_status.not_verified,
            new $data_source.NotVerified(),
            isEqual(
              new $option.Some(new $data_source.NotVerified()),
              model.content_library.filter_selected
            ),
          ),
          state_card(
            "red",
            model.content_library.qna_status.outdated,
            new $data_source.Outdated(),
            isEqual(
              new $option.Some(new $data_source.Outdated()),
              model.content_library.filter_selected
            ),
          ),
        ]),
      ),
      (() => {
        let _pipe = content_library_filters(model);
        return $el.map(
          _pipe,
          (var0) => { return new $msg.ContentLibrary(var0); },
        );
      })(),
      content_library_table(model),
    ]),
  );
}
