/// <reference types="./spreadsheet.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $mime_types from "../../mime_types/mime_types.mjs";
import { Ok, toList, CustomType as $CustomType } from "../gleam.mjs";

export class QuestionColumn extends $CustomType {}

export class AnswerColumn extends $CustomType {}

export class YesNoColumn extends $CustomType {}

export class MultipleChoiceColumn extends $CustomType {}

export class UndefinedColumn extends $CustomType {}

export class SectionRow extends $CustomType {}

export class QuestionRow extends $CustomType {}

export class UndefinedRow extends $CustomType {}

export class Worksheet extends $CustomType {
  constructor(name, start_index, content, columns_type, rows_type) {
    super();
    this.name = name;
    this.start_index = start_index;
    this.content = content;
    this.columns_type = columns_type;
    this.rows_type = rows_type;
  }
}

export class Cell extends $CustomType {
  constructor(value, dropdown) {
    super();
    this.value = value;
    this.dropdown = dropdown;
  }
}

export class Spreadsheet extends $CustomType {
  constructor(id, name, type_, data, blob) {
    super();
    this.id = id;
    this.name = name;
    this.type_ = type_;
    this.data = data;
    this.blob = blob;
  }
}

export function encode_cell(cell) {
  return $json.object(
    toList([
      ["value", $json.nullable(cell.value, $json.string)],
      ["dropdown", $json.array(cell.dropdown, $json.string)],
    ]),
  );
}

function encode_column_type(column_type) {
  if (column_type instanceof QuestionColumn) {
    return $json.string("question");
  } else if (column_type instanceof AnswerColumn) {
    return $json.string("answer");
  } else if (column_type instanceof YesNoColumn) {
    return $json.string("yes_no");
  } else if (column_type instanceof MultipleChoiceColumn) {
    return $json.string("multiple_choice");
  } else {
    return $json.string("other");
  }
}

export function encode_columns_type(columns) {
  let _pipe = columns;
  let _pipe$1 = $dict.to_list(_pipe);
  let _pipe$2 = $list.map(
    _pipe$1,
    (s) => { return [$int.to_string(s[0]), encode_column_type(s[1])]; },
  );
  return $json.object(_pipe$2);
}

function encode_row_type(row_type) {
  if (row_type instanceof SectionRow) {
    return $json.string("section");
  } else if (row_type instanceof QuestionRow) {
    return $json.string("question");
  } else {
    return $json.string("other");
  }
}

export function encode_rows_type(rows) {
  let _pipe = rows;
  let _pipe$1 = $dict.to_list(_pipe);
  let _pipe$2 = $list.map(
    _pipe$1,
    (s) => { return [$int.to_string(s[0]), encode_row_type(s[1])]; },
  );
  return $json.object(_pipe$2);
}

export function encode(spreadsheet) {
  return $json.object(
    toList([
      ["id", $json.string(spreadsheet.id)],
      ["name", $json.string(spreadsheet.name)],
      ["mime_type", $json.string($mime_types.to_string(spreadsheet.type_))],
      [
        "data",
        $json.array(
          spreadsheet.data,
          (data) => {
            return $json.object(
              toList([
                ["name", $json.string(data.name)],
                ["start_index", $json.int(data.start_index)],
                [
                  "worksheet",
                  (() => {
                    let _pipe = data.content;
                    let _pipe$1 = $list.map(
                      _pipe,
                      (c) => { return $json.array(c, encode_cell); },
                    );
                    return $json.preprocessed_array(_pipe$1);
                  })(),
                ],
                ["columns_type", encode_columns_type(data.columns_type)],
                ["rows_type", encode_rows_type(data.rows_type)],
              ]),
            );
          },
        ),
      ],
    ]),
  );
}

export function decode_cell(dyn) {
  return $dynamic.decode2(
    (a, b) => { return new Cell(a, b); },
    $dynamic.field("value", $dynamic.optional($dynamic.string)),
    $dynamic.field("dropdown", $dynamic.list($dynamic.string)),
  )(dyn);
}

export function decode_type(decode_fn) {
  return $dynamic.dict(
    (dyn) => {
      return $result.then$(
        $dynamic.string(dyn),
        (string_value) => {
          return $result.replace_error(
            $int.parse(string_value),
            toList([
              new $dynamic.DecodeError("[0-9]+", string_value, toList([])),
            ]),
          );
        },
      );
    },
    (dyn) => {
      let _pipe = $dynamic.string(dyn);
      return $result.then$(_pipe, decode_fn);
    },
  );
}

export function column_type_from_string(s) {
  if (s === "question") {
    return new Ok(new QuestionColumn());
  } else if (s === "answer") {
    return new Ok(new AnswerColumn());
  } else if (s === "yes_no") {
    return new Ok(new YesNoColumn());
  } else if (s === "multiple_choice") {
    return new Ok(new MultipleChoiceColumn());
  } else {
    return new Ok(new UndefinedColumn());
  }
}

export function row_type_from_string(s) {
  if (s === "question") {
    return new Ok(new QuestionRow());
  } else if (s === "section") {
    return new Ok(new SectionRow());
  } else {
    return new Ok(new UndefinedRow());
  }
}

function decode_data(dyn) {
  return $dynamic.list(
    $dynamic.decode5(
      (a, b, c, d, e) => { return new Worksheet(a, b, c, d, e); },
      $dynamic.field("name", $dynamic.string),
      (dyn) => {
        let _pipe = $dynamic.optional_field(
          "start_index",
          (dyn) => {
            let _pipe = $dynamic.optional($dynamic.int)(dyn);
            return $result.map(
              _pipe,
              (_capture) => { return $option.unwrap(_capture, 0); },
            );
          },
        )(dyn);
        return $result.map(
          _pipe,
          (_capture) => { return $option.unwrap(_capture, 0); },
        );
      },
      $dynamic.field("worksheet", $dynamic.list($dynamic.list(decode_cell))),
      $dynamic.field("columns_type", decode_type(column_type_from_string)),
      $dynamic.field("rows_type", decode_type(row_type_from_string)),
    ),
  )(dyn);
}

export function decode(blob) {
  return (dyn) => {
    return $dynamic.decode4(
      (a, b, c, d) => { return new Spreadsheet(a, b, c, d, blob); },
      $dynamic.field("id", $dynamic.string),
      $dynamic.field("name", $dynamic.string),
      $dynamic.field("mime_type", $mime_types.decode),
      $dynamic.field("data", decode_data),
    )(dyn);
  };
}
