/// <reference types="./dropdowns.d.mts" />
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px, rem } from "../../../sketch/sketch/size.mjs";
import { toList } from "../../gleam.mjs";

function label() {
  return $s.class$(
    toList([
      $s.padding_("4px 12px"),
      $s.border_radius(px(4)),
      $s.text_align("center"),
      $s.white_space("nowrap"),
      $s.flex("1"),
      $s.cursor("pointer"),
      $s.border("1px solid transparent"),
      $s.color("var(--text-color)"),
      (() => {
        let _pipe = $s.background("var(--background-color)");
        return $s.important(_pipe);
      })(),
      $s.transition("border_color 0.3s"),
      $s.font_weight("500"),
      $s.font_size(rem(0.875)),
      $s.line_height("1.25rem"),
      $s.min_width(px(120)),
      $s.hover(
        toList([
          (() => {
            let _pipe = $s.border_color("var(--text-color)");
            return $s.important(_pipe);
          })(),
          $s.background("var(--background-color)"),
        ]),
      ),
    ]),
  );
}

export function success() {
  return $s.class$(
    toList([
      $s.compose(label()),
      $s.property("--background-color", "#f6ffdf"),
      $s.property("--text-color", "#b1db4b"),
    ]),
  );
}

export function bordered_success() {
  return $s.class$(
    toList([
      $s.compose(success()),
      (() => {
        let _pipe = $s.border_color("var(--text-color)");
        return $s.important(_pipe);
      })(),
    ]),
  );
}

export function error() {
  return $s.class$(
    toList([
      $s.property("--background-color", "#ffeeee"),
      $s.property("--text-color", "#f35555"),
      $s.compose(label()),
    ]),
  );
}

export function bordered_error() {
  return $s.class$(
    toList([
      $s.compose(error()),
      (() => {
        let _pipe = $s.border_color("var(--text-color)");
        return $s.important(_pipe);
      })(),
    ]),
  );
}

export function neutral() {
  return $s.class$(
    toList([
      $s.compose(label()),
      $s.property("--background-color", "#eaebec"),
      $s.property("--text-color", "#7e7f88"),
    ]),
  );
}

export function bordered_neutral() {
  return $s.class$(
    toList([
      $s.compose(neutral()),
      (() => {
        let _pipe = $s.border_color("var(--text-color)");
        return $s.important(_pipe);
      })(),
    ]),
  );
}

export function current() {
  return $s.class$(
    toList([
      $s.compose(label()),
      $s.property("--background-color", "#eef4fe"),
      $s.property("--text-color", "#3b75ca"),
    ]),
  );
}

export function bordered_current() {
  return $s.class$(
    toList([
      $s.compose(current()),
      (() => {
        let _pipe = $s.border_color("var(--text-color)");
        return $s.important(_pipe);
      })(),
    ]),
  );
}

export function spacing() {
  return $s.class$(toList([$s.gap(px(10)), $s.padding(px(10))]));
}
