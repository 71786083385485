/// <reference types="./display.d.mts" />
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { rem } from "../../../sketch/sketch/size.mjs";
import { toList } from "../../gleam.mjs";

export function xxl() {
  return $s.class$(
    toList([
      $s.font_weight("500"),
      $s.font_size(rem(4.5)),
      $s.line_height("5.625rem"),
      $s.letter_spacing("-0.045rem"),
    ]),
  );
}

export function xl() {
  return $s.class$(
    toList([
      $s.font_weight("500"),
      $s.font_size(rem(3.75)),
      $s.line_height("4.5rem"),
      $s.letter_spacing("-0.0375rem"),
    ]),
  );
}

export function lg() {
  return $s.class$(
    toList([
      $s.font_weight("500"),
      $s.font_size(rem(3.0)),
      $s.line_height("3.75rem"),
      $s.letter_spacing("-0.03rem"),
    ]),
  );
}

export function md() {
  return $s.class$(
    toList([
      $s.font_weight("500"),
      $s.font_size(rem(2.25)),
      $s.line_height("2.75rem"),
      $s.letter_spacing("-0.0225rem"),
    ]),
  );
}

export function sm() {
  return $s.class$(
    toList([
      $s.font_weight("500"),
      $s.font_size(rem(1.875)),
      $s.line_height("2.375rem"),
      $s.letter_spacing("-0.01875rem"),
    ]),
  );
}

export function xs() {
  return $s.class$(
    toList([
      $s.font_weight("500"),
      $s.font_size(rem(1.5)),
      $s.line_height("2rem"),
      $s.letter_spacing("-0.015rem"),
    ]),
  );
}
