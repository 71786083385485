/// <reference types="./utils.d.mts" />
import * as $birl from "../birl/birl.mjs";
import * as $translate from "../common/data/translate.mjs";
import * as $int from "../gleam_stdlib/gleam/int.mjs";
import * as $list from "../gleam_stdlib/gleam/list.mjs";
import * as $pair from "../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../gleam_stdlib/gleam/result.mjs";
import * as $string from "../gleam_stdlib/gleam/string.mjs";
import * as $uri from "../gleam_stdlib/gleam/uri.mjs";
import * as $a from "../lustre/lustre/attribute.mjs";
import * as $event from "../lustre/lustre/event.mjs";
import * as $mime_types from "../mime_types/mime_types.mjs";
import * as $file from "../plinth/plinth/browser/file.mjs";
import * as $global from "../plinth/plinth/javascript/global.mjs";
import { getNavigatorLanguage as get_navigator_language_str } from "./frontend.ffi.mjs";
import * as $ffi from "./frontend/ffi.mjs";
import { Ok, Error, toList, isEqual } from "./gleam.mjs";

export { get_navigator_language_str };

export function to_path(segments) {
  let _pipe = segments;
  let _pipe$1 = $list.map(
    _pipe,
    (_capture) => { return $string.replace(_capture, "/", ""); },
  );
  let _pipe$2 = $list.map(_pipe$1, $uri.percent_encode);
  let _pipe$3 = $string.join(_pipe$2, "/");
  return ((_capture) => { return $string.append("/", _capture); })(_pipe$3);
}

export function stop_propagation() {
  return $event.on(
    "click",
    (event) => {
      $event.stop_propagation(event);
      return new Error(toList([]));
    },
  );
}

export function time_to_qualified_day(time, separator) {
  let day = $birl.get_day(time);
  let month = $string.pad_left($int.to_string(day.month), 2, "0");
  let date = $string.pad_left($int.to_string(day.date), 2, "0");
  let year = $string.pad_left($int.to_string(day.year), 4, "0");
  return $string.join(toList([year, month, date]), separator);
}

export function parse_input_time(value) {
  let $ = $string.split(value, "-");
  if ($.hasLength(3)) {
    let year = $.head;
    let month = $.tail.head;
    let date = $.tail.tail.head;
    return $result.try$(
      $int.parse(year),
      (year) => {
        return $result.try$(
          $int.parse(month),
          (month) => {
            return $result.map(
              $int.parse(date),
              (date) => {
                let day = new $birl.Day(year, month, date);
                let time_of_day = new $birl.TimeOfDay(0, 0, 0, 0);
                let _pipe = $birl.utc_now();
                let _pipe$1 = $birl.set_day(_pipe, day);
                let _pipe$2 = $birl.set_time_of_day(_pipe$1, time_of_day);
                let _pipe$3 = $birl.to_unix(_pipe$2);
                return $birl.from_unix(_pipe$3);
              },
            );
          },
        );
      },
    );
  } else {
    return new Error(undefined);
  }
}

export function restrict_xlsx() {
  let _pipe = toList([
    ".xls",
    ".xlsx",
    $mime_types.to_string(new $mime_types.Xlsx()),
  ]);
  let _pipe$1 = $string.join(_pipe, ",");
  return ((_capture) => { return $a.attribute("accept", _capture); })(_pipe$1);
}

export function on_files_change(msg) {
  return $event.on(
    "change",
    (event) => {
      let _pipe = $ffi.read_file_event(event);
      let _pipe$1 = $result.replace_error(_pipe, toList([]));
      return $result.map(_pipe$1, msg);
    },
  );
}

export function find_column_index(types, value) {
  let _pipe = types;
  let _pipe$1 = $list.find(_pipe, (v) => { return isEqual(v[1], value); });
  return $result.map(_pipe$1, $pair.first);
}

export function find_nth(loop$l, loop$index, loop$start) {
  while (true) {
    let l = loop$l;
    let index = loop$index;
    let start = loop$start;
    if (l.atLeastLength(1) && (index === start)) {
      let first = l.head;
      return new Ok(first);
    } else if (l.atLeastLength(1)) {
      let rest = l.tail;
      loop$l = rest;
      loop$index = index;
      loop$start = start + 1;
    } else {
      return new Error(undefined);
    }
  }
}

export function set_nil_timeout(timeout, fun) {
  $global.set_timeout(timeout, fun);
  return undefined;
}

export function slice(l, start, end) {
  let _pipe = $list.drop(l, start);
  return $list.take(_pipe, end - start);
}

export function get_navigator_language() {
  let _pipe = get_navigator_language_str();
  return $translate.map_string_to_iso639(_pipe);
}
