/// <reference types="./user.d.mts" />
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import { Ok, CustomType as $CustomType } from "../../gleam.mjs";

export class User extends $CustomType {
  constructor(name, given_name, family_name, middle_name, nickname, preferred_username, profile, picture, website, email, email_verified, gender, birthdate, zoneinfo, locale, phone_number, phone_number_verified, address, updated_at, sub, org_id) {
    super();
    this.name = name;
    this.given_name = given_name;
    this.family_name = family_name;
    this.middle_name = middle_name;
    this.nickname = nickname;
    this.preferred_username = preferred_username;
    this.profile = profile;
    this.picture = picture;
    this.website = website;
    this.email = email;
    this.email_verified = email_verified;
    this.gender = gender;
    this.birthdate = birthdate;
    this.zoneinfo = zoneinfo;
    this.locale = locale;
    this.phone_number = phone_number;
    this.phone_number_verified = phone_number_verified;
    this.address = address;
    this.updated_at = updated_at;
    this.sub = sub;
    this.org_id = org_id;
  }
}

export function decode(dyn) {
  let opt = (f, d, c) => {
    return $result.try$($dynamic.optional_field(f, d)(dyn), c);
  };
  return opt(
    "name",
    $dynamic.string,
    (name) => {
      return opt(
        "given_name",
        $dynamic.string,
        (given_name) => {
          return opt(
            "family_name",
            $dynamic.string,
            (family_name) => {
              return opt(
                "middle_name",
                $dynamic.string,
                (middle_name) => {
                  return opt(
                    "nickname",
                    $dynamic.string,
                    (nickname) => {
                      return opt(
                        "preferred_username",
                        $dynamic.string,
                        (preferred_username) => {
                          return opt(
                            "profile",
                            $dynamic.string,
                            (profile) => {
                              return opt(
                                "picture",
                                $dynamic.string,
                                (picture) => {
                                  return opt(
                                    "website",
                                    $dynamic.string,
                                    (website) => {
                                      return opt(
                                        "email",
                                        $dynamic.string,
                                        (email) => {
                                          return opt(
                                            "email_verified",
                                            $dynamic.bool,
                                            (email_verified) => {
                                              return opt(
                                                "gender",
                                                $dynamic.string,
                                                (gender) => {
                                                  return opt(
                                                    "birthdate",
                                                    $dynamic.string,
                                                    (birthdate) => {
                                                      return opt(
                                                        "zoneinfo",
                                                        $dynamic.string,
                                                        (zoneinfo) => {
                                                          return opt(
                                                            "locale",
                                                            $dynamic.string,
                                                            (locale) => {
                                                              return opt(
                                                                "phone_number",
                                                                $dynamic.string,
                                                                (phone_number) => {
                                                                  return opt(
                                                                    "phone_number_verified",
                                                                    $dynamic.bool,
                                                                    (
                                                                        phone_number_verified
                                                                      ) => {
                                                                      return opt(
                                                                        "address",
                                                                        $dynamic.string,
                                                                        (
                                                                            address
                                                                          ) => {
                                                                          return opt(
                                                                            "updated_at",
                                                                            $dynamic.string,
                                                                            (
                                                                                updated_at
                                                                              ) => {
                                                                              return $result.try$(
                                                                                $dynamic.field(
                                                                                  "sub",
                                                                                  $dynamic.string,
                                                                                )(
                                                                                  dyn,
                                                                                ),
                                                                                (
                                                                                    sub
                                                                                  ) => {
                                                                                  return opt(
                                                                                    "org_id",
                                                                                    $dynamic.string,
                                                                                    (
                                                                                        org_id
                                                                                      ) => {
                                                                                      return new Ok(
                                                                                        new User(
                                                                                          name,
                                                                                          given_name,
                                                                                          family_name,
                                                                                          middle_name,
                                                                                          nickname,
                                                                                          preferred_username,
                                                                                          profile,
                                                                                          picture,
                                                                                          website,
                                                                                          email,
                                                                                          email_verified,
                                                                                          gender,
                                                                                          birthdate,
                                                                                          zoneinfo,
                                                                                          locale,
                                                                                          phone_number,
                                                                                          phone_number_verified,
                                                                                          address,
                                                                                          updated_at,
                                                                                          sub,
                                                                                          org_id,
                                                                                        ),
                                                                                      );
                                                                                    },
                                                                                  );
                                                                                },
                                                                              );
                                                                            },
                                                                          );
                                                                        },
                                                                      );
                                                                    },
                                                                  );
                                                                },
                                                              );
                                                            },
                                                          );
                                                        },
                                                      );
                                                    },
                                                  );
                                                },
                                              );
                                            },
                                          );
                                        },
                                      );
                                    },
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function id(user) {
  return user.sub;
}

export function org_id(user) {
  return user.org_id;
}
