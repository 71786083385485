/// <reference types="./barchart.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function barchart() {
  let content = "<path d=\"M3 3.0271H21C21.2652 3.0271 21.5196 3.13246 21.7071 3.31999C21.8946 3.50753 22 3.76188 22 4.0271V20.0271C22 20.2923 21.8946 20.5467 21.7071 20.7342C21.5196 20.9217 21.2652 21.0271 21 21.0271H3C2.73478 21.0271 2.48043 20.9217 2.29289 20.7342C2.10536 20.5467 2 20.2923 2 20.0271V4.0271C2 3.76188 2.10536 3.50753 2.29289 3.31999C2.48043 3.13246 2.73478 3.0271 3 3.0271ZM7 13.0271V17.0271H9V13.0271H7ZM11 7.0271V17.0271H13V7.0271H11ZM15 10.0271V17.0271H17V10.0271H15Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 24 25"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
