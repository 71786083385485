/// <reference types="./account.d.mts" />
import * as $user from "../../../common/data/user.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $model from "../../data/model.mjs";
import * as $button from "../../design_system/components/button.mjs";
import * as $input from "../../design_system/components/input.mjs";
import * as $layout from "../../design_system/components/layout.mjs";
import * as $profile_picture from "../../design_system/components/profile_picture.mjs";
import * as $skeleton from "../../design_system/components/skeleton.mjs";
import { toList } from "../../gleam.mjs";
import * as $common from "../../view/common.mjs";

function view_profile_picture(user) {
  return $skeleton.hide_loading(
    user,
    128,
    128,
    (user) => {
      let size = new $profile_picture.Humongous();
      return $profile_picture.from_user(user.picture, user.initials, size);
    },
  );
}

function view_name_input(user) {
  if (!user.isOk()) {
    return $layout.column(
      toList([$layout.gap(16), $layout.align("start")]),
      toList([]),
      toList([
        $skeleton.skeleton(80, 20),
        $skeleton.skeleton(200, 45),
        $skeleton.skeleton(150, 45),
      ]),
    );
  } else {
    let user$1 = user[0];
    return $layout.column(
      toList([$layout.gap(16), $layout.align("start")]),
      toList([]),
      toList([
        $input.input(
          toList([
            $input.label("Name"),
            $input.placeholder("John Doe"),
            $input.value(user$1.name),
            $input.disabled(true),
          ]),
        ),
        $button.secondary(
          toList([$a.disabled(true)]),
          toList([$el.text("Change name")]),
        ),
      ]),
    );
  }
}

function view_email_input(user) {
  if (!user.isOk()) {
    return $layout.column(
      toList([$layout.gap(16), $layout.align("start")]),
      toList([]),
      toList([$skeleton.skeleton(80, 20), $skeleton.full_width(45)]),
    );
  } else {
    let user$1 = user[0];
    return $layout.column(
      toList([$layout.gap(8), $layout.align("start")]),
      toList([]),
      toList([
        $input.input(
          toList([
            $input.value(user$1.email),
            $input.disabled(true),
            $input.label("Email"),
          ]),
        ),
      ]),
    );
  }
}

export function view(model) {
  let user = $model.find_current_user(model);
  return $el.fragment(
    toList([
      $common.underlined_title(
        toList([]),
        toList([$el.text("My profile"), $common.separator()]),
      ),
      $layout.row(
        toList([$layout.gap(30), $layout.align("center")]),
        toList([]),
        toList([view_profile_picture(user), view_name_input(user)]),
      ),
      $common.underlined_title(
        toList([]),
        toList([$el.text("Email"), $common.separator()]),
      ),
      view_email_input(user),
    ]),
  );
}
