/// <reference types="./icons.d.mts" />
import * as $s from "../../sketch/sketch.mjs";
import * as $size from "../../sketch/sketch/size.mjs";
import { px } from "../../sketch/sketch/size.mjs";
import * as $h from "../../sketch_magic/sketch/magic/element/html.mjs";
import * as $add from "../design_system/icons/add.mjs";
import * as $add_user from "../design_system/icons/add_user.mjs";
import * as $arrow_dropdown from "../design_system/icons/arrow_dropdown.mjs";
import * as $arrow_left_line from "../design_system/icons/arrow_left_line.mjs";
import * as $arrow_right_line from "../design_system/icons/arrow_right_line.mjs";
import * as $barchart from "../design_system/icons/barchart.mjs";
import * as $bell from "../design_system/icons/bell.mjs";
import * as $book from "../design_system/icons/book.mjs";
import * as $building from "../design_system/icons/building.mjs";
import * as $calendar from "../design_system/icons/calendar.mjs";
import * as $checkmark from "../design_system/icons/checkmark.mjs";
import * as $cross from "../design_system/icons/cross.mjs";
import * as $double_fill from "../design_system/icons/double_fill.mjs";
import * as $export from "../design_system/icons/export.mjs";
import * as $file_edit from "../design_system/icons/file_edit.mjs";
import * as $filters from "../design_system/icons/filters.mjs";
import * as $form from "../design_system/icons/form.mjs";
import * as $info from "../design_system/icons/info.mjs";
import * as $left_arrow from "../design_system/icons/left_arrow.mjs";
import * as $lightbulb_flash from "../design_system/icons/lightbulb_flash.mjs";
import * as $longer from "../design_system/icons/longer.mjs";
import * as $magnifying_glass from "../design_system/icons/magnifying_glass.mjs";
import * as $map from "../design_system/icons/map.mjs";
import * as $menu from "../design_system/icons/menu.mjs";
import * as $minus from "../design_system/icons/minus.mjs";
import * as $more from "../design_system/icons/more.mjs";
import * as $paper_plane from "../design_system/icons/paper_plane.mjs";
import * as $price_tag from "../design_system/icons/price_tag.mjs";
import * as $question_mark from "../design_system/icons/question_mark.mjs";
import * as $right_arrow from "../design_system/icons/right_arrow.mjs";
import * as $shorter from "../design_system/icons/shorter.mjs";
import * as $table_line from "../design_system/icons/table_line.mjs";
import * as $translate from "../design_system/icons/translate.mjs";
import * as $trash from "../design_system/icons/trash.mjs";
import * as $upload from "../design_system/icons/upload.mjs";
import * as $user from "../design_system/icons/user.mjs";
import * as $wrench from "../design_system/icons/wrench.mjs";
import { toList } from "../gleam.mjs";

export function small(icon) {
  let _pipe = $s.class$(toList([$s.width(px(24)), $s.height(px(24))]));
  return $h.div(_pipe, toList([]), toList([icon]));
}

export const add = $add.add;

export const add_user = $add_user.add_user;

export const arrow_dropdown = $arrow_dropdown.arrow_dropdown;

export const arrow_left_line = $arrow_left_line.arrow_left_line;

export const arrow_right_line = $arrow_right_line.arrow_right_line;

export const bell = $bell.bell;

export const book = $book.book;

export const building = $building.building;

export const calendar = $calendar.calendar;

export const checkmark = $checkmark.checkmark;

export const cross = $cross.cross;

export const double_fill = $double_fill.double_fill;

export const export$ = $export.export$;

export const file_edit = $file_edit.file_edit;

export const filters = $filters.filters;

export const form = $form.form;

export const left_arrow = $left_arrow.left_arrow;

export const lightbulb_flash = $lightbulb_flash.lightbulb_flash;

export const longer = $longer.longer;

export const magnifying_glass = $magnifying_glass.magnifying_glass;

export const map = $map.map;

export const menu = $menu.menu;

export const minus = $minus.minus;

export const more = $more.more;

export const paper_plane = $paper_plane.paper_plane;

export const price_tag = $price_tag.price_tag;

export const question_mark = $question_mark.question_mark;

export const right_arrow = $right_arrow.right_arrow;

export const shorter = $shorter.shorter;

export const table_line = $table_line.table_line;

export const trash = $trash.trash;

export const upload = $upload.upload;

export const user = $user.user;

export const translate = $translate.translate;

export const wrench = $wrench.wrench;

export const barchart = $barchart.barchart;

export const info = $info.info;
